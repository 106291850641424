import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  ADMIN_KEY,
  URL as CLIENT_URL,
  AFFILIATE_APP_URL,
} from "../../config/config";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  Collapse,
  Box,
  Snackbar,
  Link,
  Tooltip,
  TextField,
  Divider,
  Drawer,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackOutlined from "@material-ui/icons/ArrowBackOutlined";
import { formatCurrency } from "../../helpers/currency";
import { createRandomString } from "../../helpers/hashing";
import { Sync } from "@material-ui/icons";
import { DateRangePicker } from "react-dates";
import moment from "moment-timezone";
import ReactJson from "react-json-view";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { th } from "date-fns/locale";

const Tier_Limit = [
  { name: "Tier 0 : Regular (20%)", value: "tier0" },
  { name: "Tier 1 : Growth (25%)", value: "tier1" },
  { name: "Tier 2 : Premium (30%)", value: "tier2" },
  { name: "Tier 3 : Elite (35%)", value: "tier3" },
  { name: "Tier 4 : Platinum (40%)", value: "tier4" },
];

class AffiliateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.partner,
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      showCode: false,
      isDrawerOpen: false,
      logs: [],
      currencyFilter: "INR",
      showDialog: false,
      commissionAmount: 0,
      balanceOnHold: 0,
      totalCommisionPaid: 0,
      newPassword: "",
      loading: false,
      remarks: "",
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
      },
      affiliateNameDialog: false,
      changeAffiliateName: "",
      tierLimit: "",
    };
  }

  onClose = this.props.onClose;

  componentDidMount() {
    axios
      .post(`${CLIENT_URL}/superadmin/affiliate-super-admin-logs`, {
        affiliateId: this.props.partner._id,
      })
      .then((response) => {
        const newLogs = response.data
          .map((log) => {
            const newLog = { ...log };
            delete newLog.body.affiliateId;
            return Object.keys(newLog.body).length > 0 ? newLog : null;
          })
          .filter((log) => log !== null);
        this.setState({
          logs: newLogs,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setAffiliateInDB = async (fields) => {
    try {
      const { partner } = this.props;
      const { _id } = partner;
      const queryObj = {
        affiliateId: _id,
        fields,
      };
      const response = await axios.post(
        CLIENT_URL + "/superadmin/set-affiliate-field",
        {
          ...queryObj,
        }
      );
      this.showSuccessMessage("Updated Field in DB");
      return response;
    } catch (error) {
      console.log(error);
      this.showErrorMessage("Unable to update field in DB");
      throw error;
    }
  };

  updateAffiliateName = async () => {
    const affiliate = this.props.partner;
    await this.setAffiliateInDB({
      name: this.state.changeAffiliateName,
    })
      .then((response) => {
        affiliate.name = this.state.changeAffiliateName;
        // this.props.setPartner(affiliate);
        this.closeConfirmNameDialog();
        this.setState({
          changeAffiliateName: "",
        });
      })
      .catch((error) => {
        this.closeConfirmNameDialog();
        this.setState({
          changeAffiliateName: "",
        });
      });
  };

  getTierLimit = () => {
    const result = Tier_Limit.filter((e) => e.name === this.state.tierLimit);
    return result[0].value;
  };

  updateAffiliateTierLimit = async () => {
    const limit = this.getTierLimit();
    const affiliate = this.props.partner;
    try {
      await this.setAffiliateInDB({
        affiliatePlan: limit,
      });
      affiliate.affiliatePlan = limit;
    } catch (error) {
      this.showErrorMessage("Unable to update Tier Limit");
    }
  };

  handleDrawerOpen = async () => {
    this.setState({ isDrawerOpen: true });
  };
  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };

  showSuccessMessage = (message) =>
    this.setState({
      alert: true,
      alertMsg: message,
      alertSeverity: "success",
    });

  showErrorMessage = (message) =>
    this.setState({
      alert: true,
      alertMsg: message,
      alertSeverity: "error",
    });

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  getFormattedDate = (date) => {
    if (date) {
      const formattedDate = moment
        .tz(new Date(date), "Asia/Calcutta")
        .endOf("day")
        .format("DD MMMM YYYY");
      return formattedDate;
    }
  };

  clearDues = async () => {
    try {
      this.setState({ clearingDues: true });
      const {
        currencyFilter,
        remarks,
        applied,
        totalCommisionPaid,
        balanceOnHold,
      } = this.state;
      const startDate = applied["createdAt"].startDate;
      const endDate = applied["createdAt"].endDate;

      if (!startDate && !endDate) {
        this.showErrorMessage("Please select start date and end date");
        this.setState({ loading: false });
        return;
      }

      const { partner } = this.props;
      const { _id } = partner;

      const response = await axios.post(
        `${CLIENT_URL}/superadmin/clear-affiliate-dues`,
        {
          affiliateId: _id,
          currency: currencyFilter,
          startDate: startDate,
          endDate: endDate,
          remarks: remarks,
          amountPaid: totalCommisionPaid * Math.pow(10, 5),
          balanceOnHold,
        }
      );

      this.showSuccessMessage("Dues Cleared Successfully");
      this.setState({ clearingDues: false, showDialog: false, remarks: "" });
    } catch (error) {
      this.setState({ clearingDues: false, showDialog: false, remarks: "" });
      this.showErrorMessage("Unable to clear dues");
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fetchAggregatedBilling = async () => {
    try {
      this.setState({ loading: true });
      const { currencyFilter } = this.state;
      const startDate = this.state.applied["createdAt"].startDate;
      const endDate = this.state.applied["createdAt"].endDate;

      if (!startDate && !endDate) {
        this.showErrorMessage("Please select start date and end date");
        this.setState({ loading: false });
        return;
      }

      const { partner } = this.props;
      const { _id } = partner;
      const response = await axios.post(
        `${CLIENT_URL}/superadmin/aggregate-affiliate-billings`,
        {
          affiliateId: _id,
          currency: currencyFilter,
          startDate: startDate,
          endDate: endDate,
        }
      );
      const { affiliateBillings, balanceOnHold } = response?.data || {};

      const totalCommision = affiliateBillings?.[0]?.totalCommision || 0;
      const balanceOnHoldValue = balanceOnHold?.[0]?.balanceOnHold || 0;

      const formattedCommision = (totalCommision / Math.pow(10, 5)).toFixed(2);
      const formattedHoldBalance = (
        balanceOnHoldValue / Math.pow(10, 5)
      ).toFixed(2);

      const totalCommisionPaid = formattedCommision - formattedHoldBalance;

      this.setState({
        commissionAmount: formattedCommision,
        balanceOnHold: formattedHoldBalance,
        totalCommisionPaid: totalCommisionPaid,
        showDialog: true,
      });
      this.setState({ loading: false });
      this.showSuccessMessage("Amount Aggregated Successfully");
    } catch (error) {
      this.setState({ loading: false });
      this.showErrorMessage("Unable to aggregate amount");
    }
  };

  setCurrencyFilter = (event) => {
    this.setState({ currencyFilter: event.target.value });
  };

  clearDate = () => {
    const filterKey = "createdAt";
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };

  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };

  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  loginAsOwner = async () => {
    const { partner } = this.props;
    const { _id } = partner;

    try {
      const {
        data: { token },
      } = await axios.post(
        `${CLIENT_URL}/superadmin/generate-any-token`,
        {
          _id,
          type: "affiliate",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );
      window.open(`${AFFILIATE_APP_URL}/login?token=${token}`, "_blank");
    } catch (error) {}
  };

  resetPassword = async () => {
    try {
      const loginPassword = createRandomString();
      await axios.patch(`${CLIENT_URL}/superadmin/reset-affiliate-password`, {
        affiliateId: this.props.partner._id,
        loginPassword,
      });

      this.showSuccessMessage("Password Reset Successful!");
      return loginPassword;
    } catch (error) {
      this.showErrorMessage("Unable to reset password");
    }
  };

  handleResetPassword = async () => {
    const newPass = await this.resetPassword();
    newPass && this.setState({ newPassword: newPass });
  };

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  openaffiliateNameDialog = () => {
    this.setState({ affiliateNameDialog: true });
  };

  closeaffiliateNameDialog = () => {
    this.setState({ affiliateNameDialog: false });
  };

  openConfirmNameDialog = () => {
    this.setState({ nameConfirmationDialog: true });
  };

  closeConfirmNameDialog = () => {
    this.setState({ nameConfirmationDialog: false });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { classes, partner, agent } = this.props;
    const t = this.state;

    return (
      <>
        <DialogTitle className={classes.title}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                size="small"
                aria-label="close"
                onClick={this.onClose}
              >
                <ArrowBackOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item style={{ flexGrow: 1, display: "flex" }}>
                  <Typography variant="h3" component="p">
                    {partner.name || partner.brandName}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      this.openaffiliateNameDialog();
                    }}
                    disabled={this.props.agent.power <= 10}
                    style={{ paddingTop: "3px" }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <div>
              <Dialog
                open={this.state.affiliateNameDialog}
                onClose={this.closeaffiliateNameDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: { height: "20%", width: "35%" },
                }}
              >
                <DialogContent>
                  <DialogContentText>
                    Enter new affiliate name:
                  </DialogContentText>
                  <TextField
                    name="changeAffiliateName"
                    value={this.state.changeAffiliateName}
                    onChange={this.handleChange}
                    disabled={this.props.agent.power <= 10}
                    style={{
                      width: "80%",
                      border: "1px solid ",
                      background: "white",
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closeaffiliateNameDialog}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      this.closeaffiliateNameDialog();
                      this.openConfirmNameDialog();
                    }}
                    color="primary"
                    disabled={!this.state.changeAffiliateName}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Dialog
                open={this.state.nameConfirmationDialog}
                onClose={this.closeConfirmNameDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: { height: "18%", width: "35%" },
                }}
              >
                <DialogContent>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "16px",
                    }}
                  >
                    Are you sure you want to change affiliate name from <br />
                    <b>{partner.name}</b> to{" "}
                    <b>{this.state.changeAffiliateName}</b>?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeConfirmNameDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      this.updateAffiliateName();
                      this.closeConfirmNameDialog();
                    }}
                    color="primary"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Grid item xs />
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.loginAsOwner}
              >
                Login
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDrawerOpen}
              >
                View Logs
              </Button>
            </Grid>
            <div>
              <Drawer
                anchor="right"
                open={this.state.isDrawerOpen}
                onClose={this.handleDrawerClose}
                className={classes.drawerWidth}
              >
                <Box style={{ padding: "16px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "50px",
                      alignItems: "center",
                      marginBottom: "20px",
                      background: "#EBF5F3",
                    }}
                  >
                    Partner Admin Logs
                  </Typography>
                  {this.state.logs.length === 0
                    ? "No Logs found"
                    : this.state.logs.map((log, index) => {
                        const createdAtDate = new Date(log.createdAt);
                        const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                        const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "10px",
                              marginBottom: "15px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                <ReactJson
                                  src={log.body}
                                  displayDataTypes={false}
                                  displayObjectSize={false}
                                  enableClipboard={false}
                                  name="Changes"
                                  shouldCollapse={(field) => {
                                    if (
                                      field?.name?.includes("marketing") ||
                                      field?.name?.includes("utility") ||
                                      field?.name?.includes("authentication") ||
                                      field?.name?.includes("service")
                                    ) {
                                      return true;
                                    }
                                    return false;
                                  }}
                                />
                              </div>
                              <Typography variant="body1">
                                Name: {log.displayName}
                              </Typography>
                              <Typography variant="body1">
                                Date: {formattedDate}
                              </Typography>
                              <Typography variant="body1">
                                Time: {formattedTime}
                              </Typography>
                            </div>
                            {log?.body?.amount && (
                              <Typography
                                variant="body1"
                                style={{
                                  width: "43%",
                                  color: "#0a474c",
                                  fontWeight: "bold",
                                }}
                              >
                                Template Credit: {`${partner.currency} `}
                                {log?.body?.action === "ADD" ? "(+)" : "(-)"}
                                {log?.body?.amount / Math.pow(10, 5)}
                              </Typography>
                            )}
                          </div>
                        );
                      })}
                </Box>
              </Drawer>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box
            px={{
              xs: 2,
              md: 30,
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box py={2} px={2} my={2} className={classes.container}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Commision Payout Details
              </Typography>
              <Box my={2}>
                <Grid
                  container
                  xs={12}
                  style={{
                    display: window.innerWidth <= 850 ? "inline-block" : "flex",
                    alignItems: "center",
                  }}
                >
                  <DateFilter
                    filterKey="createdAt"
                    filterName="Created At"
                    classes={classes}
                    applied={this.state.applied}
                    setDate={this.setDate}
                    setFocus={this.setFocus}
                    checkDateInput={this.checkDateInput}
                    clearDate={this.clearDate}
                  />
                  <Grid item xs={6} style={{ marginLeft: "70px" }}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={this.state.currencyFilter}
                        onChange={this.setCurrencyFilter}
                      >
                        <FormControlLabel
                          value="INR"
                          control={<Radio color="primary" />}
                          label="INR"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="USD"
                          control={<Radio color="primary" />}
                          label="USD"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                    {this.props.agent.power <= 10 ? (
                      <></>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "70px" }}
                        onClick={() => {
                          this.fetchAggregatedBilling();
                        }}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Show Details"
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box py={2} px={2} my={1} className={classes.container}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Reset Password
              </Typography>
              <Typography variant="body2" gutterBottom>
                User Name: {partner.userName}
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    className={classes.textFieldDisabled}
                    disabled={true}
                    type="text"
                    placeholder="Click Reset to get a new password"
                    fullWidth
                    value={this.state.newPassword}
                    InputProps={{
                      endAdornment: (
                        <span>
                          <Tooltip title="Click to copy Password">
                            <IconButton
                              size="small"
                              disabled={!this.state.newPassword}
                              onClick={() =>
                                this.copyToClipboard(this.state.newPassword)
                              }
                            >
                              <FileCopyIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </span>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  {agent.power <= 10 ? (
                    <></>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleResetPassword}
                    >
                      Reset
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box py={2} px={2} my={2} className={classes.container}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Update Affiliate Tier Level
              </Typography>
              <Typography variant="body1" gutterBottom>
                Current Tier -{" "}
                {Tier_Limit.find(
                  (limit) => limit.value === partner.affiliatePlan
                )?.name || null}
              </Typography>
              <Grid container>
                <Grid item xs={5} alignItems="center">
                  <Autocomplete
                    disabled={this.props.agent.power <= 10}
                    value={this.state.tierLimit}
                    options={Tier_Limit.map((option) => option.name)}
                    onChange={(e, value, reason) =>
                      this.setState({
                        tierLimit: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          border: "1px solid ",
                          background: "white",
                          marginRight: 10,
                        }}
                        disabled={this.props.agent.power <= 10}
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select Tier"
                        name="Affiliate Tier Limit"
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    {this.props.agent.power <= 10 ? (
                      <></>
                    ) : (
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        variant="contained"
                        onClick={this.updateAffiliateTierLimit}
                      >
                        Update Tier Level
                      </Button>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* <Box mb={4} className={classes.container}>
              <Grid
                container
                spacing={8}
                style={{
                  padding: "20px 30px 25px 30px",
                }}
              >
                <Grid xs={12}>
                  <Grid
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="h4" style={{ marginBottom: "11px" }}>
                      {"Download Report"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    style={{ marginBottom: "15px" }}
                  >
                    {"Download Affiliate Report"}
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  style={{
                    display: window.innerWidth <= 850 ? "inline-block" : "flex",
                    alignItems: "center",
                  }}
                >
                  <DateFilter
                    filterKey="createdAt"
                    filterName="Created At"
                    classes={classes}
                    applied={this.state.applied}
                    setDate={this.setDate}
                    setFocus={this.setFocus}
                    checkDateInput={this.checkDateInput}
                    clearDate={this.clearDate}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 8,
                      height: 30,
                    }}
                    onClick={() => {
                      this.getReport();
                    }}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
            <Grid container justifyContent="center">
              <Grid item>
                {agent.power <= 10 ? (
                  <></>
                ) : (
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        showCode: !t.showCode,
                      })
                    }
                  >
                    {t.showCode ? "Hide" : "Show"} Code
                  </Button>
                )}
              </Grid>
            </Grid>
            <Collapse in={t.showCode}>
              <pre contentEditable={true} className={classes.codeBox}>
                {JSON.stringify(this.props.partner, null, 2)}
              </pre>
            </Collapse>
          </Box>
        </DialogContent>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          maxWidth="md"
          PaperProps={{
            style: {
              maxHeight: "60%",
              width: "40%",
            },
          }}
        >
          <DialogTitle>Commission Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Time Period:{" "}
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", fontSize: "1.2rem" }}
              >
                {" "}
                {this.getFormattedDate(
                  this.state.applied["createdAt"].startDate
                )}{" "}
                {"(12:00:00 AM) "}-{" "}
                {this.getFormattedDate(this.state.applied["createdAt"].endDate)}{" "}
                {"(11:59:59 PM)"}
              </Typography>
              <br />
              Commission:
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "20px",
                }}
              >
                {this.state.currencyFilter} {this.state.commissionAmount}
              </Typography>
              Balance On Hold:
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "20px",
                }}
              >
                {this.state.currencyFilter} {this.state.balanceOnHold}
              </Typography>
              <b> Total Commision to be paid:</b>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "20px",
                }}
              >
                {this.state.currencyFilter} {this.state.totalCommisionPaid}
              </Typography>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                Enter Remarks: {"  "}
                <TextField
                  style={{
                    width: "60%",
                    border: "1px solid ",
                    background: "white",
                    marginRight: 10,
                  }}
                  disabled={this.props.agent.power <= 10}
                  name="remarks"
                  onChange={this.handleInput}
                  value={this.state.remarks}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "5px", marginRight: "5px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.setState({ showDialog: false })}
            >
              Close
            </Button>
            <Button
              onClick={() => this.clearDues()}
              variant="contained"
              color="primary"
              disabled={
                this.state.clearingDues || this.state.commissionAmount < 1
              }
            >
              Clear Dues
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <div className={classes.datePickerPosition}>
      <Grid item xs style={{ display: "flex", justifyContent: "flex-start" }}>
        <DateRangePicker
          startDatePlaceholderText="From"
          endDatePlaceholderText="To"
          onClose={() => checkDateInput(filterKey)}
          startDateId="startDate"
          endDateId="endDate"
          startDate={A.startDate}
          endDate={A.endDate}
          onDatesChange={({ startDate, endDate }) =>
            setDate(filterKey, startDate, endDate)
          }
          onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
          navPosition="navPositionTop"
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          customArrowIcon={true}
          screenReaderInputMessage={" "}
          small
          readOnly
          maxDate={moment(new Date())}
          minimumNights={0}
          isOutsideRange={(day) =>
            day.isAfter(moment()) || moment().subtract(1, "days").isBefore(day)
          }
          focusedInput={A.focusedInput}
        />
      </Grid>
    </div>
  );
}

const styles = (theme) =>
  createStyles({
    root: {},
    logo: {
      height: theme.spacing(4),
    },
    drawerWidth: {
      "& .MuiDrawer-paper": {
        width: "32%",
      },
    },
    codeBox: {
      backgroundColor: "#eee",
      padding: "1rem",
      borderRadius: "12px",
      overflow: "auto",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    container: {
      background: "#ebf5f3",
      padding: "30px",
      borderRadius: "8px",
    },
    content: {
      backgroundColor: "#FDFFFC",
    },
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
    iconButton: {
      cursor: "pointer",
    },
    textField: {
      width: 200,
    },
    textFieldDisabled: {
      backgroundColor: "white",
    },
  });

const connectedPartnerDetails = connect((state) => ({
  agent: state.login.user,
}))(AffiliateDetails);
export default withStyles(styles)(connectedPartnerDetails);
