import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  createStyles,
  withStyles,
  Switch,
} from "@material-ui/core";
import { TENANT_ID } from "../../config/config";

function PartnerToggles({
  classes,
  partner,
  updateAttribute,
  agent,
  skipBalanceCheck,
}) {
  const handleToggle = async (key, value) => {
    await updateAttribute(key, !value);
  };

  return (
    <Box className={classes.root}>
      <Box py={2} px={2} my={2} className={classes.container}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
          Skip campaign balance check
        </Typography>
        <Box my={2} style={{ display: "flex", alignItems: "center" }}>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Switch
              name="skipBalanceCheck"
              checked={partner?.skipBalanceCheck || false}
              onChange={(e) => skipBalanceCheck(e)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </Box>
      </Box>

      <Box py={2} px={2} my={2} className={classes.container}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Bypass OTP restriction at IP level
        </Typography>
        <Box my={2} style={{ display: "flex", alignItems: "center" }}>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Switch
              name="skipIPCheck"
              checked={partner?.skipIPCheck || false}
              onClick={() =>
                handleToggle("skipIPCheck", partner?.skipIPCheck || false)
              }
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </Box>
      </Box>

      {/* {!TENANT_ID && (
        <Box py={2} px={2} my={2} className={classes.container}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            WCC Payment Automation for Whitelabel
          </Typography>
          <Box my={2} style={{ display: "flex", alignItems: "center" }}>
            {agent.power <= 10 ? (
              <></>
            ) : (
              <Switch
                name="isRazorpayEnabled"
                checked={partner?.isRazorpayEnabled || false}
                onClick={() =>
                  handleToggle(
                    "isRazorpayEnabled",
                    partner?.isRazorpayEnabled || false
                  )
                }
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            )}
          </Box>
        </Box>
      )} */}
    </Box>
  );
}

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: 20,
      overflowX: "hidden",
    },
    container: {
      background: "#EBF5F3",
      borderRadius: "15px",
    },
  });
const connectedPartnerToggles = connect((state) => ({
  agent: state.login.user,
}))(PartnerToggles);
export default withStyles(styles)(connectedPartnerToggles);
