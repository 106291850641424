import { AISENSY_PARTNER_ID, TENANT_ID } from "../config/config";

/**
 *  Get info about the host of the application
 *  @return {{isTenant: boolean; isPartner: boolean; isAiSensy: boolean}}
 */
export const getHostInfo = (partnerId) => {
  // Check if origin is from the host i.e not PARTNER!
  const isTenant =
    TENANT_ID && TENANT_ID !== AISENSY_PARTNER_ID && TENANT_ID !== partnerId;
  const isAiSensy =
    !isTenant && (!partnerId || partnerId === AISENSY_PARTNER_ID);
  const isPartner = !isTenant && !isAiSensy;

  return { isTenant, isPartner, isAiSensy };
};
