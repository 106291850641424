// const SocketURL =
//   process.env.NODE_ENV === "production"
//     ? "https://socket-server-prod.herokuapp.com/agents"
//     : "http://localhost:3200/agents";

// const URL =
//   process.env.NODE_ENV === "production"
//     ? "https://restapi-prod.herokuapp.com"
//     : "http://localhost:3400";

// const URL = "http://localhost:5001/client/t1";

export const ADMIN_KEY =
  process.env.REACT_APP_ADMIN_KEY || "fc9f1f97a5cabc66424e01a7";

export const APP_URL = process.env.REACT_APP_URL || "http://localhost:3000";
export const PARTNER_APP_URL =
  process.env.REACT_APP_PARTNER_APP_URL || "http://localhost:3000";

export const AFFILIATE_APP_URL =
  process.env.REACT_APP_AFFILIATE_APP_URL || "http://localhost:3000";

const SocketURL =
  process.env.REACT_APP_SOCKET_URL || "https://backend.aisensy.org/agents";
// const URL =
//   process.env.NODE_ENV === "production"
//     ? "https://backend.aisensy.com/client/t1"
//     : "http://localhost:5001/client/t1";
const URL =
  process.env.REACT_APP_WHATSAPP_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/client/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1"
    : process.env.REACT_APP_MODE === "prestaging"
    ? "https://backend.aisensy.org/client/t1"
    : "http://localhost:5001/client/t1");
// const SocketURL = "https://socketserver-staging.herokuapp.com/agents";
// const URL = "https://restapi-staging.herokuapp.com";

const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "882865407668-dl8a107bcb65kl8ttqe8e6j2usu3cm40.apps.googleusercontent.com";

const partnerURL =
  process.env.REACT_APP_PARTNER_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/partner/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/partner/t1"
    : process.env.REACT_APP_MODE === "prestaging"
    ? "https://backend.aisensy.org/partner/t1"
    : "http://localhost:5099/partner/t1");

const CHARGEBEE_REDIRECT_URL =
  process.env.REACT_APP_CHARGEBEE_REDIRECT_URL ||
  (process.env.REACT_APP_MODE === "staging"
    ? "https://aisensy-test.chargebee.com/d/customers"
    : "https://aisensy.chargebee.com/d/customers");

const CORE_API_URL =
  process.env.REACT_APP_CORE_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://apis.aisensy.com/core-services"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://apis.aisensy.com/stg/core-services"
    : "https://apis.aisensy.org/core-services");

const INTEGRATION_SERVER_URL =
  process.env.REACT_APP_INTEGRATIONS_SERVER_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/apps"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/apps"
    : process.env.REACT_APP_MODE === "prestaging"
    ? "https://backend.aisensy.org/apps"
    : "http://localhost:5006/apps");

const CAMPAIGN_URL =
  process.env.REACT_APP_CAMPAIGN_SERVER_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/campaign/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/campaign/t1"
    : process.env.REACT_APP_MODE === "prestaging"
    ? "https://backend.aisensy.org/campaign/t1"
    : "http://localhost:5002/campaign/t1");

const AISENSY_PARTNER_ID =
  process.env.REACT_APP_AISENSY_PARTNER_ID || "627ea4876340e14cf7081778";

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const APP_ID = process.env.REACT_APP_APP_ID || "799369954601524";

export {
  SocketURL,
  URL,
  GOOGLE_CLIENT_ID,
  partnerURL,
  CORE_API_URL,
  INTEGRATION_SERVER_URL,
  CAMPAIGN_URL,
  CHARGEBEE_REDIRECT_URL,
  AISENSY_PARTNER_ID,
  TENANT_ID,
  APP_ID,
};
