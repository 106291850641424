export const CBP = {
  AF: {
    countryName: "Afghanistan",
    dialCode: "+93",
    isoCode: "AF",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  AL: {
    countryName: "Albania",
    dialCode: "+355",
    isoCode: "AL",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  DZ: {
    countryName: "Algeria",
    dialCode: "+213",
    isoCode: "DZ",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  AO: {
    countryName: "Angola",
    dialCode: "+244",
    isoCode: "AO",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  AR: {
    countryName: "Argentina",
    dialCode: "+54",
    isoCode: "AR",
    authentication: {
      price: {
        INR: 315900,
        USD: 4390,
      },
      metaPrice: {
        INR: 268880,
        USD: 3671,
      },
    },
    marketing: {
      price: {
        INR: 532200,
        USD: 7390,
      },
      metaPrice: {
        INR: 452930,
        USD: 6180,
      },
    },
    utility: {
      price: {
        INR: 292787,
        USD: 4063,
      },
      metaPrice: {
        INR: 249180,
        USD: 3401,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 430000,
        USD: 6540,
      },
      metaPrice: {
        INR: 385500,
        USD: 5260,
      },
    },
    userInitiated: {
      price: {
        INR: 265000,
        USD: 3890,
      },
      metaPrice: {
        INR: 231300,
        USD: 3160,
      },
    },
  },
  AM: {
    countryName: "Armenia",
    dialCode: "+374",
    isoCode: "AM",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  AU: {
    countryName: "Australia",
    dialCode: "+61",
    isoCode: "AU",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  AT: {
    countryName: "Austria",
    dialCode: "+43",
    isoCode: "AT",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  AZ: {
    countryName: "Azerbaijan",
    dialCode: "+994",
    isoCode: "AZ",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  BH: {
    countryName: "Bahrain",
    dialCode: "+973",
    isoCode: "BH",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  BD: {
    countryName: "Bangladesh",
    dialCode: "+880",
    isoCode: "BD",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  BY: {
    countryName: "Belarus",
    dialCode: "+375",
    isoCode: "BY",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  BE: {
    countryName: "Belgium",
    dialCode: "+32",
    isoCode: "BE",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  BJ: {
    countryName: "Benin",
    dialCode: "+229",
    isoCode: "BJ",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  BO: {
    countryName: "Bolivia",
    dialCode: "+591",
    isoCode: "BO",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  BW: {
    countryName: "Botswana",
    dialCode: "+267",
    isoCode: "BW",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  BR: {
    countryName: "Brazil",
    dialCode: "+55",
    isoCode: "BR",
    authentication: {
      price: {
        INR: 271300,
        USD: 3770,
      },
      metaPrice: {
        INR: 230870,
        USD: 3150,
      },
    },
    marketing: {
      price: {
        INR: 538200,
        USD: 7480,
      },
      metaPrice: {
        INR: 458080,
        USD: 6250,
      },
    },
    utility: {
      price: {
        INR: 68891,
        USD: 957,
      },
      metaPrice: {
        INR: 58631,
        USD: 800,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 409000,
        USD: 6240,
      },
      metaPrice: {
        INR: 366460,
        USD: 5000,
      },
    },
    userInitiated: {
      price: {
        INR: 252000,
        USD: 3710,
      },
      metaPrice: {
        INR: 219870,
        USD: 3000,
      },
    },
  },
  BG: {
    countryName: "Bulgaria",
    dialCode: "+359",
    isoCode: "BG",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  BF: {
    countryName: "Burkina Faso",
    dialCode: "+226",
    isoCode: "BF",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  BI: {
    countryName: "Burundi",
    dialCode: "+257",
    isoCode: "BI",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  KH: {
    countryName: "Cambodia",
    dialCode: "+855",
    isoCode: "KH",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  CA: {
    countryName: "Canada",
    dialCode: "+1",
    isoCode: "CA",
    authentication: {
      price: {
        INR: 116200,
        USD: 1610,
      },
      metaPrice: {
        INR: 98890,
        USD: 1350,
      },
    },
    marketing: {
      price: {
        INR: 215200,
        USD: 2990,
      },
      metaPrice: {
        INR: 183130,
        USD: 2500,
      },
    },
    utility: {
      price: {
        INR: 34428,
        USD: 479,
      },
      metaPrice: {
        INR: 29300,
        USD: 400,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 107680,
        USD: 1470,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 64610,
        USD: 880,
      },
    },
  },
  CM: {
    countryName: "Cameroon",
    dialCode: "+237",
    isoCode: "CM",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  TD: {
    countryName: "Chad",
    dialCode: "+235",
    isoCode: "TD",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  CL: {
    countryName: "Chile",
    dialCode: "+56",
    isoCode: "CL",
    authentication: {
      price: {
        INR: 454000,
        USD: 6310,
      },
      metaPrice: {
        INR: 386420,
        USD: 5270,
      },
    },
    marketing: {
      price: {
        INR: 765300,
        USD: 10630,
      },
      metaPrice: {
        INR: 651350,
        USD: 8890,
      },
    },
    utility: {
      price: {
        INR: 172185,
        USD: 2392,
      },
      metaPrice: {
        INR: 146540,
        USD: 2000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 612000,
        USD: 9220,
      },
      metaPrice: {
        INR: 554640,
        USD: 7570,
      },
    },
    userInitiated: {
      price: {
        INR: 373000,
        USD: 5490,
      },
      metaPrice: {
        INR: 332790,
        USD: 4540,
      },
    },
  },
  CN: {
    countryName: "China",
    dialCode: "+86",
    isoCode: "CN",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  CO: {
    countryName: "Colombia",
    dialCode: "+57",
    isoCode: "CO",
    authentication: {
      price: {
        INR: 65900,
        USD: 920,
      },
      metaPrice: {
        INR: 56070,
        USD: 770,
      },
    },
    marketing: {
      price: {
        INR: 107600,
        USD: 1500,
      },
      metaPrice: {
        INR: 91610,
        USD: 1250,
      },
    },
    utility: {
      price: {
        INR: 1728,
        USD: 24,
      },
      metaPrice: {
        INR: 1470,
        USD: 20,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 95000,
        USD: 1610,
      },
      metaPrice: {
        INR: 73290,
        USD: 1000,
      },
    },
    userInitiated: {
      price: {
        INR: 63000,
        USD: 930,
      },
      metaPrice: {
        INR: 43970,
        USD: 600,
      },
    },
  },
  CG: {
    countryName: "Republic of the Congo (Brazzaville)",
    dialCode: "+242",
    isoCode: "CG",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 396000,
        USD: 6050,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 130000,
        USD: 1910,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  CR: {
    countryName: "Costa Rica",
    dialCode: "+506",
    isoCode: "CR",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  HR: {
    countryName: "Croatia",
    dialCode: "+385",
    isoCode: "HR",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  CZ: {
    countryName: "Czech Republic",
    dialCode: "+420",
    isoCode: "CZ",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  DK: {
    countryName: "Denmark",
    dialCode: "+45",
    isoCode: "DK",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  VE: {
    countryName: "Venezuela",
    dialCode: "+58",
    isoCode: "VE",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  EC: {
    countryName: "Ecuador",
    dialCode: "+593",
    isoCode: "EC",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  EG: {
    countryName: "Egypt",
    dialCode: "+20",
    isoCode: "EG",
    authentication: {
      price: {
        INR: 532500,
        USD: 7400,
      },
      metaPrice: {
        INR: 453210,
        USD: 6180,
      },
    },
    marketing: {
      price: {
        INR: 924100,
        USD: 12840,
      },
      metaPrice: {
        INR: 786510,
        USD: 10730,
      },
    },
    utility: {
      price: {
        INR: 44791,
        USD: 622,
      },
      metaPrice: {
        INR: 38120,
        USD: 520,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 861000,
        USD: 12880,
      },
      metaPrice: {
        INR: 786510,
        USD: 10730,
      },
    },
    userInitiated: {
      price: {
        INR: 523000,
        USD: 7690,
      },
      metaPrice: {
        INR: 471910,
        USD: 6440,
      },
    },
  },
  SV: {
    countryName: "El Salvador",
    dialCode: "+503",
    isoCode: "SV",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  ER: {
    countryName: "Eritrea",
    dialCode: "+291",
    isoCode: "ER",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  ET: {
    countryName: "Ethiopia",
    dialCode: "+251",
    isoCode: "ET",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  FI: {
    countryName: "Finland",
    dialCode: "+358",
    isoCode: "FI",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  FR: {
    countryName: "France",
    dialCode: "+33",
    isoCode: "FR",
    authentication: {
      price: {
        INR: 595400,
        USD: 8270,
      },
      metaPrice: {
        INR: 506740,
        USD: 6910,
      },
    },
    marketing: {
      price: {
        INR: 1233600,
        USD: 17130,
      },
      metaPrice: {
        INR: 1049840,
        USD: 14320,
      },
    },
    utility: {
      price: {
        INR: 258430,
        USD: 3588,
      },
      metaPrice: {
        INR: 219940,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1143000,
        USD: 17040,
      },
      metaPrice: {
        INR: 1049840,
        USD: 14320,
      },
    },
    userInitiated: {
      price: {
        INR: 692000,
        USD: 10180,
      },
      metaPrice: {
        INR: 629900,
        USD: 8590,
      },
    },
  },
  GA: {
    countryName: "Gabon",
    dialCode: "+241",
    isoCode: "GA",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  GM: {
    countryName: "Gambia",
    dialCode: "+220",
    isoCode: "GM",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  GE: {
    countryName: "Georgia",
    dialCode: "+995",
    isoCode: "GE",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  DE: {
    countryName: "Germany",
    dialCode: "+49",
    isoCode: "DE",
    authentication: {
      price: {
        INR: 661600,
        USD: 9190,
      },
      metaPrice: {
        INR: 563080,
        USD: 7680,
      },
    },
    marketing: {
      price: {
        INR: 1175900,
        USD: 16330,
      },
      metaPrice: {
        INR: 1000731,
        USD: 13651,
      },
    },
    utility: {
      price: {
        INR: 473784,
        USD: 6584,
      },
      metaPrice: {
        INR: 403220,
        USD: 5500,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1091000,
        USD: 16260,
      },
      metaPrice: {
        INR: 1000730,
        USD: 13650,
      },
    },
    userInitiated: {
      price: {
        INR: 661000,
        USD: 9720,
      },
      metaPrice: {
        INR: 600440,
        USD: 8190,
      },
    },
  },
  GH: {
    countryName: "Ghana",
    dialCode: "+233",
    isoCode: "GH",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  GR: {
    countryName: "Greece",
    dialCode: "+30",
    isoCode: "GR",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  GT: {
    countryName: "Guatemala",
    dialCode: "+502",
    isoCode: "GT",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  GW: {
    countryName: "Guinea-Bissau",
    dialCode: "+245",
    isoCode: "GW",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  HT: {
    countryName: "Haiti",
    dialCode: "+509",
    isoCode: "HT",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  HN: {
    countryName: "Honduras",
    dialCode: "+504",
    isoCode: "HN",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  HK: {
    countryName: "Hong Kong",
    dialCode: "+852",
    isoCode: "HK",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  HU: {
    countryName: "Hungary",
    dialCode: "+36",
    isoCode: "HU",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  IN: {
    countryName: "India",
    dialCode: "+91",
    isoCode: "IN",
    authentication: {
      price: {
        INR: 12500,
        USD: 168,
      },
      metaPrice: {
        INR: 11500,
        USD: 140,
      },
    },
    marketing: {
      price: {
        INR: 88000,
        USD: 1279,
      },
      metaPrice: {
        INR: 78460,
        USD: 1070,
      },
    },
    utility: {
      price: {
        INR: 12500,
        USD: 168,
      },
      metaPrice: {
        INR: 11500,
        USD: 140,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 55000,
        USD: 800,
      },
      metaPrice: {
        INR: 48430,
        USD: 660,
      },
    },
    userInitiated: {
      price: {
        INR: 35000,
        USD: 510,
      },
      metaPrice: {
        INR: 29060,
        USD: 400,
      },
    },
  },
  ID: {
    countryName: "Indonesia",
    dialCode: "+62",
    isoCode: "ID",
    authentication: {
      price: {
        INR: 258254,
        USD: 3585,
      },
      metaPrice: {
        INR: 219791,
        USD: 3000,
      },
    },
    marketing: {
      price: {
        INR: 353800,
        USD: 4910,
      },
      metaPrice: {
        INR: 301110,
        USD: 4110,
      },
    },
    utility: {
      price: {
        INR: 172173,
        USD: 2390,
      },
      metaPrice: {
        INR: 146530,
        USD: 2000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 265000,
        USD: 4110,
      },
      metaPrice: {
        INR: 231510,
        USD: 3160,
      },
    },
    userInitiated: {
      price: {
        INR: 165000,
        USD: 2430,
      },
      metaPrice: {
        INR: 138900,
        USD: 1900,
      },
    },
  },
  IQ: {
    countryName: "Iraq",
    dialCode: "+964",
    isoCode: "IQ",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  IE: {
    countryName: "Ireland",
    dialCode: "+353",
    isoCode: "IE",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  IL: {
    countryName: "Israel",
    dialCode: "+972",
    isoCode: "IL",
    authentication: {
      price: {
        INR: 145300,
        USD: 2020,
      },
      metaPrice: {
        INR: 123680,
        USD: 1690,
      },
    },
    marketing: {
      price: {
        INR: 304000,
        USD: 4220,
      },
      metaPrice: {
        INR: 258710,
        USD: 3530,
      },
    },
    utility: {
      price: {
        INR: 45649,
        USD: 634,
      },
      metaPrice: {
        INR: 38850,
        USD: 530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 252000,
        USD: 3930,
      },
      metaPrice: {
        INR: 219870,
        USD: 3000,
      },
    },
    userInitiated: {
      price: {
        INR: 157000,
        USD: 2320,
      },
      metaPrice: {
        INR: 131920,
        USD: 1800,
      },
    },
  },
  IT: {
    countryName: "Italy",
    dialCode: "+39",
    isoCode: "IT",
    authentication: {
      price: {
        INR: 325300,
        USD: 4520,
      },
      metaPrice: {
        INR: 276870,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 594700,
        USD: 8260,
      },
      metaPrice: {
        INR: 506140,
        USD: 6910,
      },
    },
    utility: {
      price: {
        INR: 258195,
        USD: 3585,
      },
      metaPrice: {
        INR: 219740,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 522000,
        USD: 7900,
      },
      metaPrice: {
        INR: 470980,
        USD: 6430,
      },
    },
    userInitiated: {
      price: {
        INR: 320000,
        USD: 4700,
      },
      metaPrice: {
        INR: 282590,
        USD: 3860,
      },
    },
  },
  JP: {
    countryName: "Japan",
    dialCode: "+81",
    isoCode: "JP",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  JO: {
    countryName: "Jordan",
    dialCode: "+962",
    isoCode: "JO",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  KE: {
    countryName: "Kenya",
    dialCode: "+254",
    isoCode: "KE",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  KW: {
    countryName: "Kuwait",
    dialCode: "+965",
    isoCode: "KW",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  LA: {
    countryName: "Laos",
    dialCode: "+856",
    isoCode: "LA",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  LV: {
    countryName: "Latvia",
    dialCode: "+371",
    isoCode: "LV",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  LB: {
    countryName: "Lebanon",
    dialCode: "+961",
    isoCode: "LB",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  LS: {
    countryName: "Lesotho",
    dialCode: "+266",
    isoCode: "LS",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  LR: {
    countryName: "Liberia",
    dialCode: "+231",
    isoCode: "LR",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  LY: {
    countryName: "Libya",
    dialCode: "+218",
    isoCode: "LY",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  LT: {
    countryName: "Lithuania",
    dialCode: "+370",
    isoCode: "LT",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  MG: {
    countryName: "Madagascar",
    dialCode: "+261",
    isoCode: "MG",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MW: {
    countryName: "Malawi",
    dialCode: "+265",
    isoCode: "MW",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MY: {
    countryName: "Malaysia",
    dialCode: "+60",
    isoCode: "MY",
    authentication: {
      price: {
        INR: 155000,
        USD: 2150,
      },
      metaPrice: {
        INR: 131940,
        USD: 1800,
      },
    },
    marketing: {
      price: {
        INR: 740700,
        USD: 10290,
      },
      metaPrice: {
        INR: 630370,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 120579,
        USD: 1673,
      },
      metaPrice: {
        INR: 102620,
        USD: 1400,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 592000,
        USD: 8930,
      },
      metaPrice: {
        INR: 536550,
        USD: 7320,
      },
    },
    userInitiated: {
      price: {
        INR: 189000,
        USD: 2780,
      },
      metaPrice: {
        INR: 160960,
        USD: 2200,
      },
    },
  },
  ML: {
    countryName: "Mali",
    dialCode: "+223",
    isoCode: "ML",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MR: {
    countryName: "Mauritania",
    dialCode: "+222",
    isoCode: "MR",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MX: {
    countryName: "Mexico",
    dialCode: "+52",
    isoCode: "MX",
    authentication: {
      price: {
        INR: 206100,
        USD: 2860,
      },
      metaPrice: {
        INR: 175370,
        USD: 2390,
      },
    },
    marketing: {
      price: {
        INR: 375300,
        USD: 5210,
      },
      metaPrice: {
        INR: 319380,
        USD: 4360,
      },
    },
    utility: {
      price: {
        INR: 86069,
        USD: 1195,
      },
      metaPrice: {
        INR: 73250,
        USD: 1000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 291000,
        USD: 4490,
      },
      metaPrice: {
        INR: 255650,
        USD: 3490,
      },
    },
    userInitiated: {
      price: {
        INR: 98000,
        USD: 1450,
      },
      metaPrice: {
        INR: 76690,
        USD: 1050,
      },
    },
  },
  MD: {
    countryName: "Moldova",
    dialCode: "+373",
    isoCode: "MD",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  MN: {
    countryName: "Mongolia",
    dialCode: "+976",
    isoCode: "MN",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  MA: {
    countryName: "Morocco",
    dialCode: "+212",
    isoCode: "MA",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MZ: {
    countryName: "Mozambique",
    dialCode: "+258",
    isoCode: "MZ",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  NA: {
    countryName: "Namibia",
    dialCode: "+264",
    isoCode: "NA",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  NP: {
    countryName: "Nepal",
    dialCode: "+977",
    isoCode: "NP",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  NL: {
    countryName: "Netherlands",
    dialCode: "+31",
    isoCode: "NL",
    authentication: {
      price: {
        INR: 620200,
        USD: 8610,
      },
      metaPrice: {
        INR: 527840,
        USD: 7200,
      },
    },
    marketing: {
      price: {
        INR: 1375700,
        USD: 19110,
      },
      metaPrice: {
        INR: 1170780,
        USD: 15970,
      },
    },
    utility: {
      price: {
        INR: 430708,
        USD: 5980,
      },
      metaPrice: {
        INR: 366560,
        USD: 5000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1185000,
        USD: 17650,
      },
      metaPrice: {
        INR: 1088670,
        USD: 14850,
      },
    },
    userInitiated: {
      price: {
        INR: 717000,
        USD: 10550,
      },
      metaPrice: {
        INR: 653200,
        USD: 8910,
      },
    },
  },
  NZ: {
    countryName: "New Zealand",
    dialCode: "+64",
    isoCode: "NZ",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  NI: {
    countryName: "Nicaragua",
    dialCode: "+505",
    isoCode: "NI",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  NE: {
    countryName: "Niger",
    dialCode: "+227",
    isoCode: "NE",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  NG: {
    countryName: "Nigeria",
    dialCode: "+234",
    isoCode: "NG",
    authentication: {
      price: {
        INR: 247500,
        USD: 3440,
      },
      metaPrice: {
        INR: 210600,
        USD: 2870,
      },
    },
    marketing: {
      price: {
        INR: 444700,
        USD: 6180,
      },
      metaPrice: {
        INR: 378510,
        USD: 5160,
      },
    },
    utility: {
      price: {
        INR: 57752,
        USD: 802,
      },
      metaPrice: {
        INR: 49150,
        USD: 670,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 422000,
        USD: 6430,
      },
      metaPrice: {
        INR: 378510,
        USD: 5160,
      },
    },
    userInitiated: {
      price: {
        INR: 260000,
        USD: 3820,
      },
      metaPrice: {
        INR: 227100,
        USD: 3100,
      },
    },
  },
  NO: {
    countryName: "Norway",
    dialCode: "+47",
    isoCode: "NO",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  OM: {
    countryName: "Oman",
    dialCode: "+968",
    isoCode: "OM",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  PK: {
    countryName: "Pakistan",
    dialCode: "+92",
    isoCode: "PK",
    authentication: {
      price: {
        INR: 196200,
        USD: 2720,
      },
      metaPrice: {
        INR: 166960,
        USD: 2280,
      },
    },
    marketing: {
      price: {
        INR: 407500,
        USD: 5660,
      },
      metaPrice: {
        INR: 346830,
        USD: 4730,
      },
    },
    utility: {
      price: {
        INR: 46530,
        USD: 646,
      },
      metaPrice: {
        INR: 39600,
        USD: 540,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 388000,
        USD: 5930,
      },
      metaPrice: {
        INR: 346830,
        USD: 4730,
      },
    },
    userInitiated: {
      price: {
        INR: 128000,
        USD: 1880,
      },
      metaPrice: {
        INR: 104050,
        USD: 1420,
      },
    },
  },
  PA: {
    countryName: "Panama",
    dialCode: "+507",
    isoCode: "PA",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  PG: {
    countryName: "Papua New Guinea",
    dialCode: "+675",
    isoCode: "PG",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  PY: {
    countryName: "Paraguay",
    dialCode: "+595",
    isoCode: "PY",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  PE: {
    countryName: "Peru",
    dialCode: "+51",
    isoCode: "PE",
    authentication: {
      price: {
        INR: 324600,
        USD: 4510,
      },
      metaPrice: {
        INR: 276260,
        USD: 3770,
      },
    },
    marketing: {
      price: {
        INR: 605500,
        USD: 8410,
      },
      metaPrice: {
        INR: 515360,
        USD: 7030,
      },
    },
    utility: {
      price: {
        INR: 172279,
        USD: 2392,
      },
      metaPrice: {
        INR: 146620,
        USD: 2000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 487000,
        USD: 7380,
      },
      metaPrice: {
        INR: 438390,
        USD: 5980,
      },
    },
    userInitiated: {
      price: {
        INR: 157000,
        USD: 2300,
      },
      metaPrice: {
        INR: 131520,
        USD: 1790,
      },
    },
  },
  PH: {
    countryName: "Philippines",
    dialCode: "+63",
    isoCode: "PH",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  PL: {
    countryName: "Poland",
    dialCode: "+48",
    isoCode: "PL",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  PT: {
    countryName: "Portugal",
    dialCode: "+351",
    isoCode: "PT",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  PR: {
    countryName: "Puerto Rico",
    dialCode: "+1939",
    isoCode: "PR",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  QA: {
    countryName: "Qatar",
    dialCode: "+974",
    isoCode: "QA",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  RO: {
    countryName: "Romania",
    dialCode: "+40",
    isoCode: "RO",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  RU: {
    countryName: "Russia",
    dialCode: "+7",
    isoCode: "RU",
    authentication: {
      price: {
        INR: 369600,
        USD: 5130,
      },
      metaPrice: {
        INR: 314570,
        USD: 4290,
      },
    },
    marketing: {
      price: {
        INR: 690500,
        USD: 9590,
      },
      metaPrice: {
        INR: 587670,
        USD: 8020,
      },
    },
    utility: {
      price: {
        INR: 344405,
        USD: 4780,
      },
      metaPrice: {
        INR: 293110,
        USD: 4000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 538000,
        USD: 8130,
      },
      metaPrice: {
        INR: 485820,
        USD: 6630,
      },
    },
    userInitiated: {
      price: {
        INR: 329000,
        USD: 4840,
      },
      metaPrice: {
        INR: 291490,
        USD: 3980,
      },
    },
  },
  RW: {
    countryName: "Rwanda",
    dialCode: "+250",
    isoCode: "RW",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  SA: {
    countryName: "Saudi Arabia",
    dialCode: "+966",
    isoCode: "SA",
    authentication: {
      price: {
        INR: 194800,
        USD: 2710,
      },
      metaPrice: {
        INR: 165800,
        USD: 2260,
      },
    },
    marketing: {
      price: {
        INR: 391181,
        USD: 5437,
      },
      metaPrice: {
        INR: 332920,
        USD: 4550,
      },
    },
    utility: {
      price: {
        INR: 98935,
        USD: 1375,
      },
      metaPrice: {
        INR: 84200,
        USD: 1150,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 272000,
        USD: 4220,
      },
      metaPrice: {
        INR: 237950,
        USD: 3250,
      },
    },
    userInitiated: {
      price: {
        INR: 169000,
        USD: 2490,
      },
      metaPrice: {
        INR: 142770,
        USD: 1950,
      },
    },
  },
  SN: {
    countryName: "Senegal",
    dialCode: "+221",
    isoCode: "SN",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  RS: {
    countryName: "Serbia",
    dialCode: "+381",
    isoCode: "RS",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  SL: {
    countryName: "Sierra Leone",
    dialCode: "+232",
    isoCode: "SL",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  SG: {
    countryName: "Singapore",
    dialCode: "+65",
    isoCode: "SG",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  SK: {
    countryName: "Slovakia",
    dialCode: "+421",
    isoCode: "SK",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  SI: {
    countryName: "Slovenia",
    dialCode: "+386",
    isoCode: "SI",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  SO: {
    countryName: "Somalia",
    dialCode: "+252",
    isoCode: "SO",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  ZA: {
    countryName: "South Africa",
    dialCode: "+27",
    isoCode: "ZA",
    authentication: {
      price: {
        INR: 155300,
        USD: 2160,
      },
      metaPrice: {
        INR: 132130,
        USD: 1800,
      },
    },
    marketing: {
      price: {
        INR: 326900,
        USD: 4540,
      },
      metaPrice: {
        INR: 278210,
        USD: 3791,
      },
    },
    utility: {
      price: {
        INR: 65554,
        USD: 911,
      },
      metaPrice: {
        INR: 55790,
        USD: 760,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 236000,
        USD: 3690,
      },
      metaPrice: {
        INR: 205540,
        USD: 2800,
      },
    },
    userInitiated: {
      price: {
        INR: 148000,
        USD: 2180,
      },
      metaPrice: {
        INR: 123320,
        USD: 1680,
      },
    },
  },
  SS: {
    countryName: "South Sudan",
    dialCode: "+211",
    isoCode: "SS",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  ES: {
    countryName: "Spain",
    dialCode: "+34",
    isoCode: "ES",
    authentication: {
      price: {
        INR: 294300,
        USD: 4090,
      },
      metaPrice: {
        INR: 250490,
        USD: 3420,
      },
    },
    marketing: {
      price: {
        INR: 529300,
        USD: 7350,
      },
      metaPrice: {
        INR: 450441,
        USD: 6150,
      },
    },
    utility: {
      price: {
        INR: 172114,
        USD: 2390,
      },
      metaPrice: {
        INR: 146480,
        USD: 2000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 500000,
        USD: 7570,
      },
      metaPrice: {
        INR: 450440,
        USD: 6150,
      },
    },
    userInitiated: {
      price: {
        INR: 306000,
        USD: 4500,
      },
      metaPrice: {
        INR: 270260,
        USD: 3690,
      },
    },
  },
  LK: {
    countryName: "Sri Lanka",
    dialCode: "+94",
    isoCode: "LK",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  SD: {
    countryName: "Sudan",
    dialCode: "+249",
    isoCode: "SD",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  SE: {
    countryName: "Sweden",
    dialCode: "+46",
    isoCode: "SE",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  CH: {
    countryName: "Switzerland",
    dialCode: "+41",
    isoCode: "CH",
    authentication: {
      price: {
        INR: 325400,
        USD: 4520,
      },
      metaPrice: {
        INR: 276970,
        USD: 3780,
      },
    },
    marketing: {
      price: {
        INR: 509700,
        USD: 7080,
      },
      metaPrice: {
        INR: 433780,
        USD: 5920,
      },
    },
    utility: {
      price: {
        INR: 258289,
        USD: 3585,
      },
      metaPrice: {
        INR: 219820,
        USD: 3000,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 1058000,
        USD: 15790,
      },
      metaPrice: {
        INR: 970140,
        USD: 13240,
      },
    },
    userInitiated: {
      price: {
        INR: 328000,
        USD: 4830,
      },
      metaPrice: {
        INR: 291040,
        USD: 3970,
      },
    },
  },
  TW: {
    countryName: "Taiwan",
    dialCode: "+886",
    isoCode: "TW",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  TJ: {
    countryName: "Tajikistan",
    dialCode: "+992",
    isoCode: "TJ",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  TH: {
    countryName: "Thailand",
    dialCode: "+66",
    isoCode: "TH",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  TG: {
    countryName: "Togo",
    dialCode: "+228",
    isoCode: "TG",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  TN: {
    countryName: "Tunisia",
    dialCode: "+216",
    isoCode: "TN",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  TR: {
    countryName: "Turkey",
    dialCode: "+90",
    isoCode: "TR",
    authentication: {
      price: {
        INR: 71700,
        USD: 1000,
      },
      metaPrice: {
        INR: 61010,
        USD: 830,
      },
    },
    marketing: {
      price: {
        INR: 93900,
        USD: 1300,
      },
      metaPrice: {
        INR: 79890,
        USD: 1090,
      },
    },
    utility: {
      price: {
        INR: 45637,
        USD: 634,
      },
      metaPrice: {
        INR: 38840,
        USD: 530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 95000,
        USD: 1610,
      },
      metaPrice: {
        INR: 73290,
        USD: 1000,
      },
    },
    userInitiated: {
      price: {
        INR: 39000,
        USD: 580,
      },
      metaPrice: {
        INR: 21990,
        USD: 300,
      },
    },
  },
  TM: {
    countryName: "Turkmenistan",
    dialCode: "+993",
    isoCode: "TM",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  UG: {
    countryName: "Uganda",
    dialCode: "+256",
    isoCode: "UG",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  UA: {
    countryName: "Ukraine",
    dialCode: "+380",
    isoCode: "UA",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  AE: {
    countryName: "United Arab Emirates",
    dialCode: "+971",
    isoCode: "AE",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130330,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 330927,
        USD: 4589,
      },
      metaPrice: {
        INR: 281640,
        USD: 3840,
      },
    },
    utility: {
      price: {
        INR: 135231,
        USD: 1877,
      },
      metaPrice: {
        INR: 115090,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 265000,
        USD: 4110,
      },
      metaPrice: {
        INR: 231650,
        USD: 3160,
      },
    },
    userInitiated: {
      price: {
        INR: 165000,
        USD: 2430,
      },
      metaPrice: {
        INR: 138990,
        USD: 1900,
      },
    },
  },
  GB: {
    countryName: "United Kingdom",
    dialCode: "+44",
    isoCode: "GB",
    authentication: {
      price: {
        INR: 308400,
        USD: 4280,
      },
      metaPrice: {
        INR: 262490,
        USD: 3580,
      },
    },
    marketing: {
      price: {
        INR: 455277,
        USD: 6322,
      },
      metaPrice: {
        INR: 387470,
        USD: 5290,
      },
    },
    utility: {
      price: {
        INR: 189434,
        USD: 2629,
      },
      metaPrice: {
        INR: 161220,
        USD: 2200,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 525000,
        USD: 7950,
      },
      metaPrice: {
        INR: 474130,
        USD: 6470,
      },
    },
    userInitiated: {
      price: {
        INR: 321000,
        USD: 4730,
      },
      metaPrice: {
        INR: 284480,
        USD: 3880,
      },
    },
  },
  US: {
    countryName: "United States",
    dialCode: "+1",
    isoCode: "US",
    authentication: {
      price: {
        INR: 116200,
        USD: 1610,
      },
      metaPrice: {
        INR: 98890,
        USD: 1350,
      },
    },
    marketing: {
      price: {
        INR: 215200,
        USD: 2990,
      },
      metaPrice: {
        INR: 183130,
        USD: 2500,
      },
    },
    utility: {
      price: {
        INR: 34428,
        USD: 479,
      },
      metaPrice: {
        INR: 29300,
        USD: 400,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 107680,
        USD: 1470,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 64610,
        USD: 880,
      },
    },
  },
  UY: {
    countryName: "Uruguay",
    dialCode: "+598",
    isoCode: "UY",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  UZ: {
    countryName: "Uzbekistan",
    dialCode: "+998",
    isoCode: "UZ",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  VN: {
    countryName: "Vietnam",
    dialCode: "+84",
    isoCode: "VN",
    authentication: {
      price: {
        INR: 365700,
        USD: 5080,
      },
      metaPrice: {
        INR: 311240,
        USD: 4250,
      },
    },
    marketing: {
      price: {
        INR: 630600,
        USD: 8760,
      },
      metaPrice: {
        INR: 536640,
        USD: 7320,
      },
    },
    utility: {
      price: {
        INR: 135243,
        USD: 1880,
      },
      metaPrice: {
        INR: 115100,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 602000,
        USD: 9080,
      },
      metaPrice: {
        INR: 546170,
        USD: 7450,
      },
    },
    userInitiated: {
      price: {
        INR: 192000,
        USD: 2830,
      },
      metaPrice: {
        INR: 163850,
        USD: 2240,
      },
    },
  },
  YE: {
    countryName: "Yemen",
    dialCode: "+967",
    isoCode: "YE",
    authentication: {
      price: {
        INR: 153100,
        USD: 2130,
      },
      metaPrice: {
        INR: 130300,
        USD: 1780,
      },
    },
    marketing: {
      price: {
        INR: 293700,
        USD: 4080,
      },
      metaPrice: {
        INR: 249930,
        USD: 3410,
      },
    },
    utility: {
      price: {
        INR: 135208,
        USD: 1877,
      },
      metaPrice: {
        INR: 115070,
        USD: 1570,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 588000,
        USD: 8870,
      },
      metaPrice: {
        INR: 532840,
        USD: 7270,
      },
    },
    userInitiated: {
      price: {
        INR: 187000,
        USD: 2760,
      },
      metaPrice: {
        INR: 159850,
        USD: 2180,
      },
    },
  },
  ZM: {
    countryName: "Zambia",
    dialCode: "+260",
    isoCode: "ZM",
    authentication: {
      price: {
        INR: 124101,
        USD: 1720,
      },
      metaPrice: {
        INR: 105580,
        USD: 1440,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 52547,
        USD: 729,
      },
      metaPrice: {
        INR: 44720,
        USD: 610,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  TZ: {
    countryName: "Tanzania",
    dialCode: "+1",
    isoCode: "TZ",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  SZ: {
    countryName: "Swaziland",
    dialCode: "+1",
    isoCode: "SZ",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  MK: {
    countryName: "North Macedonia",
    dialCode: "+1",
    isoCode: "MK",
    authentication: {
      price: {
        INR: 479500,
        USD: 6661,
      },
      metaPrice: {
        INR: 408090,
        USD: 5570,
      },
    },
    marketing: {
      price: {
        INR: 740200,
        USD: 10280,
      },
      metaPrice: {
        INR: 629970,
        USD: 8600,
      },
    },
    utility: {
      price: {
        INR: 303832,
        USD: 4219,
      },
      metaPrice: {
        INR: 258580,
        USD: 3530,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 673000,
        USD: 10120,
      },
      metaPrice: {
        INR: 611660,
        USD: 8350,
      },
    },
    userInitiated: {
      price: {
        INR: 213000,
        USD: 3130,
      },
      metaPrice: {
        INR: 183500,
        USD: 2500,
      },
    },
  },
  DO: {
    countryName: "Dominican Republic",
    dialCode: "+1",
    isoCode: "DO",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  JM: {
    countryName: "Jamaica",
    dialCode: "+1",
    isoCode: "JM",
    authentication: {
      price: {
        INR: 382800,
        USD: 5320,
      },
      metaPrice: {
        INR: 325810,
        USD: 4450,
      },
    },
    marketing: {
      price: {
        INR: 636900,
        USD: 8850,
      },
      metaPrice: {
        INR: 542070,
        USD: 7400,
      },
    },
    utility: {
      price: {
        INR: 97267,
        USD: 1351,
      },
      metaPrice: {
        INR: 82780,
        USD: 1130,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 572000,
        USD: 8630,
      },
      metaPrice: {
        INR: 517160,
        USD: 7060,
      },
    },
    userInitiated: {
      price: {
        INR: 349000,
        USD: 5130,
      },
      metaPrice: {
        INR: 310300,
        USD: 4230,
      },
    },
  },
  CI: {
    countryName: "Ivory Coast",
    dialCode: "+384",
    isoCode: "CI",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 193800,
        USD: 2690,
      },
      metaPrice: {
        INR: 164970,
        USD: 2250,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 967000,
        USD: 14440,
      },
      metaPrice: {
        INR: 885720,
        USD: 12080,
      },
    },
    userInitiated: {
      price: {
        INR: 302000,
        USD: 4440,
      },
      metaPrice: {
        INR: 265720,
        USD: 3630,
      },
    },
  },
  OTHER: {
    countryName: "All other countries",
    dialCode: "+",
    isoCode: "OTHER",
    authentication: {
      price: {
        INR: 261901,
        USD: 3640,
      },
      metaPrice: {
        INR: 222920,
        USD: 3040,
      },
    },
    marketing: {
      price: {
        INR: 519900,
        USD: 7220,
      },
      metaPrice: {
        INR: 442480,
        USD: 6040,
      },
    },
    utility: {
      price: {
        INR: 66282,
        USD: 921,
      },
      metaPrice: {
        INR: 56411,
        USD: 770,
      },
    },
    service: {
      price: {
        INR: 0,
        USD: 0,
      },
      metaPrice: {
        INR: 0,
        USD: 0,
      },
    },
    businessInitiated: {
      price: {
        INR: 396000,
        USD: 6050,
      },
      metaPrice: {
        INR: 353840,
        USD: 4830,
      },
    },
    userInitiated: {
      price: {
        INR: 130000,
        USD: 1910,
      },
      metaPrice: {
        INR: 106150,
        USD: 1450,
      },
    },
  },
};
