import { Box, Chip, Tooltip, Typography } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import React from "react";

function getOptionLabel(data, selected, classes, isFromNewAdsManager = false) {
  const { tag, pageId, tasks, accountId, pageName, accountName, freezed } =
    data || {};

  const label = accountId
    ? `${accountName} - ${accountId.split("_")[1]}`
    : pageId
    ? `${pageName} - ${pageId}`
    : "NA";

  const chipColors = {
    Assigned: "#28c152",
    Limited: "#efa95e",
    Personal: "#8859e7",
  };

  const lbl = `${pageId ? "Page" : "Ad Account"}`;

  const tagDescription = {
    Limited: `${lbl} is not assigned`,
    Personal: `Cannnot Access Personal ${lbl}'s`,
  };

  return (
    <Tooltip
      placement="right"
      title={
        <Box p={1.5}>
          <Typography variant="body2" gutterBottom>
            <b>{`${pageId ? "Page : " : "Account : "}`}</b>
            {label}
          </Typography>
          {freezed && (
            <Typography variant="body2" gutterBottom>
              <b>{`Freezed : `}</b>
              {`Other Agents will only be able to access this ${lbl}`}
            </Typography>
          )}
          {/* <Typography variant="body2" gutterBottom>
            <b>{`Permissions : `}</b>
            {JSON.stringify(tasks)
              .replace(/["\[\]]/g, "")
              .replace(/,/g, ", ")}
          </Typography> */}
          {tag === "Personal" && isFromNewAdsManager
            ? ""
            : tag !== "Assigned" && (
                <Typography variant="body2" gutterBottom>
                  <b>{`Reason : `}</b>
                  {tagDescription[tag] || ""}
                </Typography>
              )}
        </Box>
      }
      arrow
      classes={{ arrow: classes.arrow }}
    >
      <Box width="100%" style={{ display: "flex" }}>
        <Box width={freezed ? "95%" : "100%"}>
          <Typography
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {label}
          </Typography>
        </Box>
        {freezed && (
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Lock fontSize="small" color="primary" />
          </Box>
        )}
        {/* <Box width="10%" style={{ display: "flex", justifyContent: "end" }}>
          <Chip
            style={{
              borderRadius: "6px",
              color: chipColors[tag] ? chipColors[tag] : "",
              borderColor: chipColors[tag] ? chipColors[tag] : "",
              fontWeight: 600,
              fontSize: "0.8em",
              alignItems: "center",
            }}
            label={tag[0]}
            size="small"
            variant="outlined"
          ></Chip>
        </Box> */}
      </Box>
    </Tooltip>
  );
}

export { getOptionLabel };
