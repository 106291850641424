const AISENSY_WCC_PLAN = {
  business_initiated: {
    AF: {
      INR: 602000,
      USD: 9080,
    },
    AL: {
      INR: 673000,
      USD: 10120,
    },
    DZ: {
      INR: 967000,
      USD: 14440,
    },
    AO: {
      INR: 967000,
      USD: 14440,
    },
    AR: {
      INR: 430000,
      USD: 6540,
    },
    AM: {
      INR: 673000,
      USD: 10120,
    },
    AU: {
      INR: 602000,
      USD: 9080,
    },
    AT: {
      INR: 1058000,
      USD: 15790,
    },
    AZ: {
      INR: 673000,
      USD: 10120,
    },
    BH: {
      INR: 588000,
      USD: 8870,
    },
    BD: {
      INR: 602000,
      USD: 9080,
    },
    BY: {
      INR: 673000,
      USD: 10120,
    },
    BE: {
      INR: 1058000,
      USD: 15790,
    },
    BJ: {
      INR: 967000,
      USD: 14440,
    },
    BO: {
      INR: 572000,
      USD: 8630,
    },
    BW: {
      INR: 967000,
      USD: 14440,
    },
    BR: {
      INR: 409000,
      USD: 6240,
    },
    BG: {
      INR: 673000,
      USD: 10120,
    },
    BF: {
      INR: 967000,
      USD: 14440,
    },
    BI: {
      INR: 967000,
      USD: 14440,
    },
    KH: {
      INR: 602000,
      USD: 9080,
    },
    CA: {
      INR: 572000,
      USD: 8630,
    },
    CM: {
      INR: 967000,
      USD: 14440,
    },
    TD: {
      INR: 967000,
      USD: 14440,
    },
    CL: {
      INR: 612000,
      USD: 9220,
    },
    CN: {
      INR: 602000,
      USD: 9080,
    },
    CO: {
      INR: 95000,
      USD: 1610,
    },
    CG: {
      INR: 396000,
      USD: 6050,
    },
    CR: {
      INR: 572000,
      USD: 8630,
    },
    HR: {
      INR: 673000,
      USD: 10120,
    },
    CZ: {
      INR: 673000,
      USD: 10120,
    },
    DK: {
      INR: 1058000,
      USD: 15790,
    },
    VE: {
      INR: 572000,
      USD: 8630,
    },
    EC: {
      INR: 572000,
      USD: 8630,
    },
    EG: {
      INR: 861000,
      USD: 12880,
    },
    SV: {
      INR: 572000,
      USD: 8630,
    },
    ER: {
      INR: 967000,
      USD: 14440,
    },
    ET: {
      INR: 967000,
      USD: 14440,
    },
    FI: {
      INR: 1058000,
      USD: 15790,
    },
    FR: {
      INR: 1143000,
      USD: 17040,
    },
    GA: {
      INR: 967000,
      USD: 14440,
    },
    GM: {
      INR: 967000,
      USD: 14440,
    },
    GE: {
      INR: 673000,
      USD: 10120,
    },
    DE: {
      INR: 1091000,
      USD: 16260,
    },
    GH: {
      INR: 967000,
      USD: 14440,
    },
    GR: {
      INR: 673000,
      USD: 10120,
    },
    GT: {
      INR: 572000,
      USD: 8630,
    },
    GW: {
      INR: 967000,
      USD: 14440,
    },
    HT: {
      INR: 572000,
      USD: 8630,
    },
    HN: {
      INR: 572000,
      USD: 8630,
    },
    HK: {
      INR: 602000,
      USD: 9080,
    },
    HU: {
      INR: 673000,
      USD: 10120,
    },
    IN: {
      INR: 55000,
      USD: 800,
    },
    ID: {
      INR: 265000,
      USD: 4110,
    },
    IQ: {
      INR: 588000,
      USD: 8870,
    },
    IE: {
      INR: 1058000,
      USD: 15790,
    },
    IL: {
      INR: 252000,
      USD: 3930,
    },
    IT: {
      INR: 522000,
      USD: 7900,
    },
    JP: {
      INR: 602000,
      USD: 9080,
    },
    JO: {
      INR: 588000,
      USD: 8870,
    },
    KE: {
      INR: 967000,
      USD: 14440,
    },
    KW: {
      INR: 588000,
      USD: 8870,
    },
    LA: {
      INR: 602000,
      USD: 9080,
    },
    LV: {
      INR: 673000,
      USD: 10120,
    },
    LB: {
      INR: 588000,
      USD: 8870,
    },
    LS: {
      INR: 967000,
      USD: 14440,
    },
    LR: {
      INR: 967000,
      USD: 14440,
    },
    LY: {
      INR: 967000,
      USD: 14440,
    },
    LT: {
      INR: 673000,
      USD: 10120,
    },
    MG: {
      INR: 967000,
      USD: 14440,
    },
    MW: {
      INR: 967000,
      USD: 14440,
    },
    MY: {
      INR: 592000,
      USD: 8930,
    },
    ML: {
      INR: 967000,
      USD: 14440,
    },
    MR: {
      INR: 967000,
      USD: 14440,
    },
    MX: {
      INR: 291000,
      USD: 4490,
    },
    MD: {
      INR: 673000,
      USD: 10120,
    },
    MN: {
      INR: 602000,
      USD: 9080,
    },
    MA: {
      INR: 967000,
      USD: 14440,
    },
    MZ: {
      INR: 967000,
      USD: 14440,
    },
    NA: {
      INR: 967000,
      USD: 14440,
    },
    NP: {
      INR: 602000,
      USD: 9080,
    },
    NL: {
      INR: 1185000,
      USD: 17650,
    },
    NZ: {
      INR: 602000,
      USD: 9080,
    },
    NI: {
      INR: 572000,
      USD: 8630,
    },
    NE: {
      INR: 967000,
      USD: 14440,
    },
    NG: {
      INR: 422000,
      USD: 6430,
    },
    NO: {
      INR: 1058000,
      USD: 15790,
    },
    OM: {
      INR: 588000,
      USD: 8870,
    },
    PK: {
      INR: 388000,
      USD: 5930,
    },
    PA: {
      INR: 572000,
      USD: 8630,
    },
    PG: {
      INR: 602000,
      USD: 9080,
    },
    PY: {
      INR: 572000,
      USD: 8630,
    },
    PE: {
      INR: 487000,
      USD: 7380,
    },
    PH: {
      INR: 602000,
      USD: 9080,
    },
    PL: {
      INR: 673000,
      USD: 10120,
    },
    PT: {
      INR: 1058000,
      USD: 15790,
    },
    PR: {
      INR: 572000,
      USD: 8630,
    },
    QA: {
      INR: 588000,
      USD: 8870,
    },
    RO: {
      INR: 673000,
      USD: 10120,
    },
    RU: {
      INR: 538000,
      USD: 8130,
    },
    RW: {
      INR: 967000,
      USD: 14440,
    },
    SA: {
      INR: 272000,
      USD: 4220,
    },
    SN: {
      INR: 967000,
      USD: 14440,
    },
    RS: {
      INR: 673000,
      USD: 10120,
    },
    SL: {
      INR: 967000,
      USD: 14440,
    },
    SG: {
      INR: 602000,
      USD: 9080,
    },
    SK: {
      INR: 673000,
      USD: 10120,
    },
    SI: {
      INR: 673000,
      USD: 10120,
    },
    SO: {
      INR: 967000,
      USD: 14440,
    },
    ZA: {
      INR: 236000,
      USD: 3690,
    },
    SS: {
      INR: 967000,
      USD: 14440,
    },
    ES: {
      INR: 500000,
      USD: 7570,
    },
    LK: {
      INR: 602000,
      USD: 9080,
    },
    SD: {
      INR: 967000,
      USD: 14440,
    },
    SE: {
      INR: 1058000,
      USD: 15790,
    },
    CH: {
      INR: 1058000,
      USD: 15790,
    },
    TW: {
      INR: 602000,
      USD: 9080,
    },
    TJ: {
      INR: 602000,
      USD: 9080,
    },
    TH: {
      INR: 602000,
      USD: 9080,
    },
    TG: {
      INR: 967000,
      USD: 14440,
    },
    TN: {
      INR: 967000,
      USD: 14440,
    },
    TR: {
      INR: 95000,
      USD: 1610,
    },
    TM: {
      INR: 602000,
      USD: 9080,
    },
    UG: {
      INR: 967000,
      USD: 14440,
    },
    UA: {
      INR: 673000,
      USD: 10120,
    },
    AE: {
      INR: 265000,
      USD: 4110,
    },
    GB: {
      INR: 525000,
      USD: 7950,
    },
    US: {
      INR: 572000,
      USD: 8630,
    },
    UY: {
      INR: 572000,
      USD: 8630,
    },
    UZ: {
      INR: 602000,
      USD: 9080,
    },
    VN: {
      INR: 602000,
      USD: 9080,
    },
    YE: {
      INR: 588000,
      USD: 8870,
    },
    ZM: {
      INR: 967000,
      USD: 14440,
    },
    TZ: {
      INR: 967000,
      USD: 14440,
    },
    SZ: {
      INR: 967000,
      USD: 14440,
    },
    MK: {
      INR: 673000,
      USD: 10120,
    },
    DO: {
      INR: 572000,
      USD: 8630,
    },
    JM: {
      INR: 572000,
      USD: 8630,
    },
    CI: {
      INR: 967000,
      USD: 14440,
    },
    OTHER: {
      INR: 396000,
      USD: 6050,
    },
  },
  user_initiated: {
    AF: {
      INR: 192000,
      USD: 2830,
    },
    AL: {
      INR: 213000,
      USD: 3130,
    },
    DZ: {
      INR: 302000,
      USD: 4440,
    },
    AO: {
      INR: 302000,
      USD: 4440,
    },
    AR: {
      INR: 265000,
      USD: 3890,
    },
    AM: {
      INR: 213000,
      USD: 3130,
    },
    AU: {
      INR: 192000,
      USD: 2830,
    },
    AT: {
      INR: 328000,
      USD: 4830,
    },
    AZ: {
      INR: 213000,
      USD: 3130,
    },
    BH: {
      INR: 187000,
      USD: 2760,
    },
    BD: {
      INR: 192000,
      USD: 2830,
    },
    BY: {
      INR: 213000,
      USD: 3130,
    },
    BE: {
      INR: 328000,
      USD: 4830,
    },
    BJ: {
      INR: 302000,
      USD: 4440,
    },
    BO: {
      INR: 349000,
      USD: 5130,
    },
    BW: {
      INR: 302000,
      USD: 4440,
    },
    BR: {
      INR: 252000,
      USD: 3710,
    },
    BG: {
      INR: 213000,
      USD: 3130,
    },
    BF: {
      INR: 302000,
      USD: 4440,
    },
    BI: {
      INR: 302000,
      USD: 4440,
    },
    KH: {
      INR: 192000,
      USD: 2830,
    },
    CA: {
      INR: 349000,
      USD: 5130,
    },
    CM: {
      INR: 302000,
      USD: 4440,
    },
    TD: {
      INR: 302000,
      USD: 4440,
    },
    CL: {
      INR: 373000,
      USD: 5490,
    },
    CN: {
      INR: 192000,
      USD: 2830,
    },
    CO: {
      INR: 63000,
      USD: 930,
    },
    CG: {
      INR: 130000,
      USD: 1910,
    },
    CR: {
      INR: 349000,
      USD: 5130,
    },
    HR: {
      INR: 213000,
      USD: 3130,
    },
    CZ: {
      INR: 213000,
      USD: 3130,
    },
    DK: {
      INR: 328000,
      USD: 4830,
    },
    VE: {
      INR: 349000,
      USD: 5130,
    },
    EC: {
      INR: 349000,
      USD: 5130,
    },
    EG: {
      INR: 523000,
      USD: 7690,
    },
    SV: {
      INR: 349000,
      USD: 5130,
    },
    ER: {
      INR: 302000,
      USD: 4440,
    },
    ET: {
      INR: 302000,
      USD: 4440,
    },
    FI: {
      INR: 328000,
      USD: 4830,
    },
    FR: {
      INR: 692000,
      USD: 10180,
    },
    GA: {
      INR: 302000,
      USD: 4440,
    },
    GM: {
      INR: 302000,
      USD: 4440,
    },
    GE: {
      INR: 213000,
      USD: 3130,
    },
    DE: {
      INR: 661000,
      USD: 9720,
    },
    GH: {
      INR: 302000,
      USD: 4440,
    },
    GR: {
      INR: 213000,
      USD: 3130,
    },
    GT: {
      INR: 349000,
      USD: 5130,
    },
    GW: {
      INR: 302000,
      USD: 4440,
    },
    HT: {
      INR: 349000,
      USD: 5130,
    },
    HN: {
      INR: 349000,
      USD: 5130,
    },
    HK: {
      INR: 192000,
      USD: 2830,
    },
    HU: {
      INR: 213000,
      USD: 3130,
    },
    IN: {
      INR: 35000,
      USD: 510,
    },
    ID: {
      INR: 165000,
      USD: 2430,
    },
    IQ: {
      INR: 187000,
      USD: 2760,
    },
    IE: {
      INR: 328000,
      USD: 4830,
    },
    IL: {
      INR: 157000,
      USD: 2320,
    },
    IT: {
      INR: 320000,
      USD: 4700,
    },
    JP: {
      INR: 192000,
      USD: 2830,
    },
    JO: {
      INR: 187000,
      USD: 2760,
    },
    KE: {
      INR: 302000,
      USD: 4440,
    },
    KW: {
      INR: 187000,
      USD: 2760,
    },
    LA: {
      INR: 192000,
      USD: 2830,
    },
    LV: {
      INR: 213000,
      USD: 3130,
    },
    LB: {
      INR: 187000,
      USD: 2760,
    },
    LS: {
      INR: 302000,
      USD: 4440,
    },
    LR: {
      INR: 302000,
      USD: 4440,
    },
    LY: {
      INR: 302000,
      USD: 4440,
    },
    LT: {
      INR: 213000,
      USD: 3130,
    },
    MG: {
      INR: 302000,
      USD: 4440,
    },
    MW: {
      INR: 302000,
      USD: 4440,
    },
    MY: {
      INR: 189000,
      USD: 2780,
    },
    ML: {
      INR: 302000,
      USD: 4440,
    },
    MR: {
      INR: 302000,
      USD: 4440,
    },
    MX: {
      INR: 98000,
      USD: 1450,
    },
    MD: {
      INR: 213000,
      USD: 3130,
    },
    MN: {
      INR: 192000,
      USD: 2830,
    },
    MA: {
      INR: 302000,
      USD: 4440,
    },
    MZ: {
      INR: 302000,
      USD: 4440,
    },
    NA: {
      INR: 302000,
      USD: 4440,
    },
    NP: {
      INR: 192000,
      USD: 2830,
    },
    NL: {
      INR: 717000,
      USD: 10550,
    },
    NZ: {
      INR: 192000,
      USD: 2830,
    },
    NI: {
      INR: 349000,
      USD: 5130,
    },
    NE: {
      INR: 302000,
      USD: 4440,
    },
    NG: {
      INR: 260000,
      USD: 3820,
    },
    NO: {
      INR: 328000,
      USD: 4830,
    },
    OM: {
      INR: 187000,
      USD: 2760,
    },
    PK: {
      INR: 128000,
      USD: 1880,
    },
    PA: {
      INR: 349000,
      USD: 5130,
    },
    PG: {
      INR: 192000,
      USD: 2830,
    },
    PY: {
      INR: 349000,
      USD: 5130,
    },
    PE: {
      INR: 157000,
      USD: 2300,
    },
    PH: {
      INR: 192000,
      USD: 2830,
    },
    PL: {
      INR: 213000,
      USD: 3130,
    },
    PT: {
      INR: 328000,
      USD: 4830,
    },
    PR: {
      INR: 349000,
      USD: 5130,
    },
    QA: {
      INR: 187000,
      USD: 2760,
    },
    RO: {
      INR: 213000,
      USD: 3130,
    },
    RU: {
      INR: 329000,
      USD: 4840,
    },
    RW: {
      INR: 302000,
      USD: 4440,
    },
    SA: {
      INR: 169000,
      USD: 2490,
    },
    SN: {
      INR: 302000,
      USD: 4440,
    },
    RS: {
      INR: 213000,
      USD: 3130,
    },
    SL: {
      INR: 302000,
      USD: 4440,
    },
    SG: {
      INR: 192000,
      USD: 2830,
    },
    SK: {
      INR: 213000,
      USD: 3130,
    },
    SI: {
      INR: 213000,
      USD: 3130,
    },
    SO: {
      INR: 302000,
      USD: 4440,
    },
    ZA: {
      INR: 148000,
      USD: 2180,
    },
    SS: {
      INR: 302000,
      USD: 4440,
    },
    ES: {
      INR: 306000,
      USD: 4500,
    },
    LK: {
      INR: 192000,
      USD: 2830,
    },
    SD: {
      INR: 302000,
      USD: 4440,
    },
    SE: {
      INR: 328000,
      USD: 4830,
    },
    CH: {
      INR: 328000,
      USD: 4830,
    },
    TW: {
      INR: 192000,
      USD: 2830,
    },
    TJ: {
      INR: 192000,
      USD: 2830,
    },
    TH: {
      INR: 192000,
      USD: 2830,
    },
    TG: {
      INR: 302000,
      USD: 4440,
    },
    TN: {
      INR: 302000,
      USD: 4440,
    },
    TR: {
      INR: 39000,
      USD: 580,
    },
    TM: {
      INR: 192000,
      USD: 2830,
    },
    UG: {
      INR: 302000,
      USD: 4440,
    },
    UA: {
      INR: 213000,
      USD: 3130,
    },
    AE: {
      INR: 165000,
      USD: 2430,
    },
    GB: {
      INR: 321000,
      USD: 4730,
    },
    US: {
      INR: 349000,
      USD: 5130,
    },
    UY: {
      INR: 349000,
      USD: 5130,
    },
    UZ: {
      INR: 192000,
      USD: 2830,
    },
    VN: {
      INR: 192000,
      USD: 2830,
    },
    YE: {
      INR: 187000,
      USD: 2760,
    },
    ZM: {
      INR: 302000,
      USD: 4440,
    },
    TZ: {
      INR: 302000,
      USD: 4440,
    },
    SZ: {
      INR: 302000,
      USD: 4440,
    },
    MK: {
      INR: 213000,
      USD: 3130,
    },
    DO: {
      INR: 349000,
      USD: 5130,
    },
    JM: {
      INR: 349000,
      USD: 5130,
    },
    CI: {
      INR: 302000,
      USD: 4440,
    },
    OTHER: {
      INR: 130000,
      USD: 1910,
    },
  },
  service: {
    AF: {
      INR: 0,
      USD: 0,
    },
    AL: {
      INR: 0,
      USD: 0,
    },
    DZ: {
      INR: 0,
      USD: 0,
    },
    AO: {
      INR: 0,
      USD: 0,
    },
    AR: {
      INR: 0,
      USD: 0,
    },
    AM: {
      INR: 0,
      USD: 0,
    },
    AU: {
      INR: 0,
      USD: 0,
    },
    AT: {
      INR: 0,
      USD: 0,
    },
    AZ: {
      INR: 0,
      USD: 0,
    },
    BH: {
      INR: 0,
      USD: 0,
    },
    BD: {
      INR: 0,
      USD: 0,
    },
    BY: {
      INR: 0,
      USD: 0,
    },
    BE: {
      INR: 0,
      USD: 0,
    },
    BJ: {
      INR: 0,
      USD: 0,
    },
    BO: {
      INR: 0,
      USD: 0,
    },
    BW: {
      INR: 0,
      USD: 0,
    },
    BR: {
      INR: 0,
      USD: 0,
    },
    BG: {
      INR: 0,
      USD: 0,
    },
    BF: {
      INR: 0,
      USD: 0,
    },
    BI: {
      INR: 0,
      USD: 0,
    },
    KH: {
      INR: 0,
      USD: 0,
    },
    CA: {
      INR: 0,
      USD: 0,
    },
    CM: {
      INR: 0,
      USD: 0,
    },
    TD: {
      INR: 0,
      USD: 0,
    },
    CL: {
      INR: 0,
      USD: 0,
    },
    CN: {
      INR: 0,
      USD: 0,
    },
    CO: {
      INR: 0,
      USD: 0,
    },
    CG: {
      INR: 0,
      USD: 0,
    },
    CR: {
      INR: 0,
      USD: 0,
    },
    HR: {
      INR: 0,
      USD: 0,
    },
    CZ: {
      INR: 0,
      USD: 0,
    },
    DK: {
      INR: 0,
      USD: 0,
    },
    VE: {
      INR: 0,
      USD: 0,
    },
    EC: {
      INR: 0,
      USD: 0,
    },
    EG: {
      INR: 0,
      USD: 0,
    },
    SV: {
      INR: 0,
      USD: 0,
    },
    ER: {
      INR: 0,
      USD: 0,
    },
    ET: {
      INR: 0,
      USD: 0,
    },
    FI: {
      INR: 0,
      USD: 0,
    },
    FR: {
      INR: 0,
      USD: 0,
    },
    GA: {
      INR: 0,
      USD: 0,
    },
    GM: {
      INR: 0,
      USD: 0,
    },
    GE: {
      INR: 0,
      USD: 0,
    },
    DE: {
      INR: 0,
      USD: 0,
    },
    GH: {
      INR: 0,
      USD: 0,
    },
    GR: {
      INR: 0,
      USD: 0,
    },
    GT: {
      INR: 0,
      USD: 0,
    },
    GW: {
      INR: 0,
      USD: 0,
    },
    HT: {
      INR: 0,
      USD: 0,
    },
    HN: {
      INR: 0,
      USD: 0,
    },
    HK: {
      INR: 0,
      USD: 0,
    },
    HU: {
      INR: 0,
      USD: 0,
    },
    IN: {
      INR: 0,
      USD: 0,
    },
    ID: {
      INR: 0,
      USD: 0,
    },
    IQ: {
      INR: 0,
      USD: 0,
    },
    IE: {
      INR: 0,
      USD: 0,
    },
    IL: {
      INR: 0,
      USD: 0,
    },
    IT: {
      INR: 0,
      USD: 0,
    },
    JP: {
      INR: 0,
      USD: 0,
    },
    JO: {
      INR: 0,
      USD: 0,
    },
    KE: {
      INR: 0,
      USD: 0,
    },
    KW: {
      INR: 0,
      USD: 0,
    },
    LA: {
      INR: 0,
      USD: 0,
    },
    LV: {
      INR: 0,
      USD: 0,
    },
    LB: {
      INR: 0,
      USD: 0,
    },
    LS: {
      INR: 0,
      USD: 0,
    },
    LR: {
      INR: 0,
      USD: 0,
    },
    LY: {
      INR: 0,
      USD: 0,
    },
    LT: {
      INR: 0,
      USD: 0,
    },
    MG: {
      INR: 0,
      USD: 0,
    },
    MW: {
      INR: 0,
      USD: 0,
    },
    MY: {
      INR: 0,
      USD: 0,
    },
    ML: {
      INR: 0,
      USD: 0,
    },
    MR: {
      INR: 0,
      USD: 0,
    },
    MX: {
      INR: 0,
      USD: 0,
    },
    MD: {
      INR: 0,
      USD: 0,
    },
    MN: {
      INR: 0,
      USD: 0,
    },
    MA: {
      INR: 0,
      USD: 0,
    },
    MZ: {
      INR: 0,
      USD: 0,
    },
    NA: {
      INR: 0,
      USD: 0,
    },
    NP: {
      INR: 0,
      USD: 0,
    },
    NL: {
      INR: 0,
      USD: 0,
    },
    NZ: {
      INR: 0,
      USD: 0,
    },
    NI: {
      INR: 0,
      USD: 0,
    },
    NE: {
      INR: 0,
      USD: 0,
    },
    NG: {
      INR: 0,
      USD: 0,
    },
    NO: {
      INR: 0,
      USD: 0,
    },
    OM: {
      INR: 0,
      USD: 0,
    },
    PK: {
      INR: 0,
      USD: 0,
    },
    PA: {
      INR: 0,
      USD: 0,
    },
    PG: {
      INR: 0,
      USD: 0,
    },
    PY: {
      INR: 0,
      USD: 0,
    },
    PE: {
      INR: 0,
      USD: 0,
    },
    PH: {
      INR: 0,
      USD: 0,
    },
    PL: {
      INR: 0,
      USD: 0,
    },
    PT: {
      INR: 0,
      USD: 0,
    },
    PR: {
      INR: 0,
      USD: 0,
    },
    QA: {
      INR: 0,
      USD: 0,
    },
    RO: {
      INR: 0,
      USD: 0,
    },
    RU: {
      INR: 0,
      USD: 0,
    },
    RW: {
      INR: 0,
      USD: 0,
    },
    SA: {
      INR: 0,
      USD: 0,
    },
    SN: {
      INR: 0,
      USD: 0,
    },
    RS: {
      INR: 0,
      USD: 0,
    },
    SL: {
      INR: 0,
      USD: 0,
    },
    SG: {
      INR: 0,
      USD: 0,
    },
    SK: {
      INR: 0,
      USD: 0,
    },
    SI: {
      INR: 0,
      USD: 0,
    },
    SO: {
      INR: 0,
      USD: 0,
    },
    ZA: {
      INR: 0,
      USD: 0,
    },
    SS: {
      INR: 0,
      USD: 0,
    },
    ES: {
      INR: 0,
      USD: 0,
    },
    LK: {
      INR: 0,
      USD: 0,
    },
    SD: {
      INR: 0,
      USD: 0,
    },
    SE: {
      INR: 0,
      USD: 0,
    },
    CH: {
      INR: 0,
      USD: 0,
    },
    TW: {
      INR: 0,
      USD: 0,
    },
    TJ: {
      INR: 0,
      USD: 0,
    },
    TH: {
      INR: 0,
      USD: 0,
    },
    TG: {
      INR: 0,
      USD: 0,
    },
    TN: {
      INR: 0,
      USD: 0,
    },
    TR: {
      INR: 0,
      USD: 0,
    },
    TM: {
      INR: 0,
      USD: 0,
    },
    UG: {
      INR: 0,
      USD: 0,
    },
    UA: {
      INR: 0,
      USD: 0,
    },
    AE: {
      INR: 0,
      USD: 0,
    },
    GB: {
      INR: 0,
      USD: 0,
    },
    US: {
      INR: 0,
      USD: 0,
    },
    UY: {
      INR: 0,
      USD: 0,
    },
    UZ: {
      INR: 0,
      USD: 0,
    },
    VN: {
      INR: 0,
      USD: 0,
    },
    YE: {
      INR: 0,
      USD: 0,
    },
    ZM: {
      INR: 0,
      USD: 0,
    },
    TZ: {
      INR: 0,
      USD: 0,
    },
    SZ: {
      INR: 0,
      USD: 0,
    },
    MK: {
      INR: 0,
      USD: 0,
    },
    DO: {
      INR: 0,
      USD: 0,
    },
    JM: {
      INR: 0,
      USD: 0,
    },
    CI: {
      INR: 0,
      USD: 0,
    },
    OTHER: {
      INR: 0,
      USD: 0,
    },
  },
  marketing: {
    AF: {
      INR: 630600,
      USD: 8760,
    },
    AL: {
      INR: 740200,
      USD: 10280,
    },
    DZ: {
      INR: 193800,
      USD: 2690,
    },
    AO: {
      INR: 193800,
      USD: 2690,
    },
    AR: {
      INR: 532200,
      USD: 7390,
    },
    AM: {
      INR: 740200,
      USD: 10280,
    },
    AU: {
      INR: 630600,
      USD: 8760,
    },
    AT: {
      INR: 509700,
      USD: 7080,
    },
    AZ: {
      INR: 740200,
      USD: 10280,
    },
    BH: {
      INR: 293700,
      USD: 4080,
    },
    BD: {
      INR: 630600,
      USD: 8760,
    },
    BY: {
      INR: 740200,
      USD: 10280,
    },
    BE: {
      INR: 509700,
      USD: 7080,
    },
    BJ: {
      INR: 193800,
      USD: 2690,
    },
    BO: {
      INR: 636900,
      USD: 8850,
    },
    BW: {
      INR: 193800,
      USD: 2690,
    },
    BR: {
      INR: 538200,
      USD: 7480,
    },
    BG: {
      INR: 740200,
      USD: 10280,
    },
    BF: {
      INR: 193800,
      USD: 2690,
    },
    BI: {
      INR: 193800,
      USD: 2690,
    },
    KH: {
      INR: 630600,
      USD: 8760,
    },
    CA: {
      INR: 215200,
      USD: 2990,
    },
    CM: {
      INR: 193800,
      USD: 2690,
    },
    TD: {
      INR: 193800,
      USD: 2690,
    },
    CL: {
      INR: 765300,
      USD: 10630,
    },
    CN: {
      INR: 630600,
      USD: 8760,
    },
    CO: {
      INR: 107600,
      USD: 1500,
    },
    CG: {
      INR: 193800,
      USD: 2690,
    },
    CR: {
      INR: 636900,
      USD: 8850,
    },
    HR: {
      INR: 740200,
      USD: 10280,
    },
    CZ: {
      INR: 740200,
      USD: 10280,
    },
    DK: {
      INR: 509700,
      USD: 7080,
    },
    VE: {
      INR: 636900,
      USD: 8850,
    },
    EC: {
      INR: 636900,
      USD: 8850,
    },
    EG: {
      INR: 924100,
      USD: 12840,
    },
    SV: {
      INR: 636900,
      USD: 8850,
    },
    ER: {
      INR: 193800,
      USD: 2690,
    },
    ET: {
      INR: 193800,
      USD: 2690,
    },
    FI: {
      INR: 509700,
      USD: 7080,
    },
    FR: {
      INR: 1233600,
      USD: 17130,
    },
    GA: {
      INR: 193800,
      USD: 2690,
    },
    GM: {
      INR: 193800,
      USD: 2690,
    },
    GE: {
      INR: 740200,
      USD: 10280,
    },
    DE: {
      INR: 1175900,
      USD: 16330,
    },
    GH: {
      INR: 193800,
      USD: 2690,
    },
    GR: {
      INR: 740200,
      USD: 10280,
    },
    GT: {
      INR: 636900,
      USD: 8850,
    },
    GW: {
      INR: 193800,
      USD: 2690,
    },
    HT: {
      INR: 636900,
      USD: 8850,
    },
    HN: {
      INR: 636900,
      USD: 8850,
    },
    HK: {
      INR: 630600,
      USD: 8760,
    },
    HU: {
      INR: 740200,
      USD: 10280,
    },
    IN: {
      INR: 88000,
      USD: 1279,
    },
    ID: {
      INR: 353800,
      USD: 4910,
    },
    IQ: {
      INR: 293700,
      USD: 4080,
    },
    IE: {
      INR: 509700,
      USD: 7080,
    },
    IL: {
      INR: 304000,
      USD: 4220,
    },
    IT: {
      INR: 594700,
      USD: 8260,
    },
    JP: {
      INR: 630600,
      USD: 8760,
    },
    JO: {
      INR: 293700,
      USD: 4080,
    },
    KE: {
      INR: 193800,
      USD: 2690,
    },
    KW: {
      INR: 293700,
      USD: 4080,
    },
    LA: {
      INR: 630600,
      USD: 8760,
    },
    LV: {
      INR: 740200,
      USD: 10280,
    },
    LB: {
      INR: 293700,
      USD: 4080,
    },
    LS: {
      INR: 193800,
      USD: 2690,
    },
    LR: {
      INR: 193800,
      USD: 2690,
    },
    LY: {
      INR: 193800,
      USD: 2690,
    },
    LT: {
      INR: 740200,
      USD: 10280,
    },
    MG: {
      INR: 193800,
      USD: 2690,
    },
    MW: {
      INR: 193800,
      USD: 2690,
    },
    MY: {
      INR: 740700,
      USD: 10290,
    },
    ML: {
      INR: 193800,
      USD: 2690,
    },
    MR: {
      INR: 193800,
      USD: 2690,
    },
    MX: {
      INR: 375300,
      USD: 5210,
    },
    MD: {
      INR: 740200,
      USD: 10280,
    },
    MN: {
      INR: 630600,
      USD: 8760,
    },
    MA: {
      INR: 193800,
      USD: 2690,
    },
    MZ: {
      INR: 193800,
      USD: 2690,
    },
    NA: {
      INR: 193800,
      USD: 2690,
    },
    NP: {
      INR: 630600,
      USD: 8760,
    },
    NL: {
      INR: 1375700,
      USD: 19110,
    },
    NZ: {
      INR: 630600,
      USD: 8760,
    },
    NI: {
      INR: 636900,
      USD: 8850,
    },
    NE: {
      INR: 193800,
      USD: 2690,
    },
    NG: {
      INR: 444700,
      USD: 6180,
    },
    NO: {
      INR: 509700,
      USD: 7080,
    },
    OM: {
      INR: 293700,
      USD: 4080,
    },
    PK: {
      INR: 407500,
      USD: 5660,
    },
    PA: {
      INR: 636900,
      USD: 8850,
    },
    PG: {
      INR: 630600,
      USD: 8760,
    },
    PY: {
      INR: 636900,
      USD: 8850,
    },
    PE: {
      INR: 605500,
      USD: 8410,
    },
    PH: {
      INR: 630600,
      USD: 8760,
    },
    PL: {
      INR: 740200,
      USD: 10280,
    },
    PT: {
      INR: 509700,
      USD: 7080,
    },
    PR: {
      INR: 636900,
      USD: 8850,
    },
    QA: {
      INR: 293700,
      USD: 4080,
    },
    RO: {
      INR: 740200,
      USD: 10280,
    },
    RU: {
      INR: 690500,
      USD: 9590,
    },
    RW: {
      INR: 193800,
      USD: 2690,
    },
    SA: {
      INR: 391181,
      USD: 5437,
    },
    SN: {
      INR: 193800,
      USD: 2690,
    },
    RS: {
      INR: 740200,
      USD: 10280,
    },
    SL: {
      INR: 193800,
      USD: 2690,
    },
    SG: {
      INR: 630600,
      USD: 8760,
    },
    SK: {
      INR: 740200,
      USD: 10280,
    },
    SI: {
      INR: 740200,
      USD: 10280,
    },
    SO: {
      INR: 193800,
      USD: 2690,
    },
    ZA: {
      INR: 326900,
      USD: 4540,
    },
    SS: {
      INR: 193800,
      USD: 2690,
    },
    ES: {
      INR: 529300,
      USD: 7350,
    },
    LK: {
      INR: 630600,
      USD: 8760,
    },
    SD: {
      INR: 193800,
      USD: 2690,
    },
    SE: {
      INR: 509700,
      USD: 7080,
    },
    CH: {
      INR: 509700,
      USD: 7080,
    },
    TW: {
      INR: 630600,
      USD: 8760,
    },
    TJ: {
      INR: 630600,
      USD: 8760,
    },
    TH: {
      INR: 630600,
      USD: 8760,
    },
    TG: {
      INR: 193800,
      USD: 2690,
    },
    TN: {
      INR: 193800,
      USD: 2690,
    },
    TR: {
      INR: 93900,
      USD: 1300,
    },
    TM: {
      INR: 630600,
      USD: 8760,
    },
    UG: {
      INR: 193800,
      USD: 2690,
    },
    UA: {
      INR: 740200,
      USD: 10280,
    },
    AE: {
      INR: 330927,
      USD: 4589,
    },
    GB: {
      INR: 455277,
      USD: 6322,
    },
    US: {
      INR: 215200,
      USD: 2990,
    },
    UY: {
      INR: 636900,
      USD: 8850,
    },
    UZ: {
      INR: 630600,
      USD: 8760,
    },
    VN: {
      INR: 630600,
      USD: 8760,
    },
    YE: {
      INR: 293700,
      USD: 4080,
    },
    ZM: {
      INR: 193800,
      USD: 2690,
    },
    TZ: {
      INR: 193800,
      USD: 2690,
    },
    SZ: {
      INR: 193800,
      USD: 2690,
    },
    MK: {
      INR: 740200,
      USD: 10280,
    },
    DO: {
      INR: 636900,
      USD: 8850,
    },
    JM: {
      INR: 636900,
      USD: 8850,
    },
    CI: {
      INR: 193800,
      USD: 2690,
    },
    OTHER: {
      INR: 519900,
      USD: 7220,
    },
  },
  authentication: {
    AF: {
      INR: 365700,
      USD: 5080,
    },
    AL: {
      INR: 479500,
      USD: 6661,
    },
    DZ: {
      INR: 124101,
      USD: 1720,
    },
    AO: {
      INR: 124101,
      USD: 1720,
    },
    AR: {
      INR: 315900,
      USD: 4390,
    },
    AM: {
      INR: 479500,
      USD: 6661,
    },
    AU: {
      INR: 365700,
      USD: 5080,
    },
    AT: {
      INR: 325400,
      USD: 4520,
    },
    AZ: {
      INR: 479500,
      USD: 6661,
    },
    BH: {
      INR: 153100,
      USD: 2130,
    },
    BD: {
      INR: 365700,
      USD: 5080,
    },
    BY: {
      INR: 479500,
      USD: 6661,
    },
    BE: {
      INR: 325400,
      USD: 4520,
    },
    BJ: {
      INR: 124101,
      USD: 1720,
    },
    BO: {
      INR: 382800,
      USD: 5320,
    },
    BW: {
      INR: 124101,
      USD: 1720,
    },
    BR: {
      INR: 271300,
      USD: 3770,
    },
    BG: {
      INR: 479500,
      USD: 6661,
    },
    BF: {
      INR: 124101,
      USD: 1720,
    },
    BI: {
      INR: 124101,
      USD: 1720,
    },
    KH: {
      INR: 365700,
      USD: 5080,
    },
    CA: {
      INR: 116200,
      USD: 1610,
    },
    CM: {
      INR: 124101,
      USD: 1720,
    },
    TD: {
      INR: 124101,
      USD: 1720,
    },
    CL: {
      INR: 454000,
      USD: 6310,
    },
    CN: {
      INR: 365700,
      USD: 5080,
    },
    CO: {
      INR: 65900,
      USD: 920,
    },
    CG: {
      INR: 261901,
      USD: 3640,
    },
    CR: {
      INR: 382800,
      USD: 5320,
    },
    HR: {
      INR: 479500,
      USD: 6661,
    },
    CZ: {
      INR: 479500,
      USD: 6661,
    },
    DK: {
      INR: 325400,
      USD: 4520,
    },
    VE: {
      INR: 261901,
      USD: 3640,
    },
    EC: {
      INR: 382800,
      USD: 5320,
    },
    EG: {
      INR: 532500,
      USD: 7400,
    },
    SV: {
      INR: 382800,
      USD: 5320,
    },
    ER: {
      INR: 124101,
      USD: 1720,
    },
    ET: {
      INR: 124101,
      USD: 1720,
    },
    FI: {
      INR: 325400,
      USD: 4520,
    },
    FR: {
      INR: 595400,
      USD: 8270,
    },
    GA: {
      INR: 124101,
      USD: 1720,
    },
    GM: {
      INR: 124101,
      USD: 1720,
    },
    GE: {
      INR: 479500,
      USD: 6661,
    },
    DE: {
      INR: 661600,
      USD: 9190,
    },
    GH: {
      INR: 124101,
      USD: 1720,
    },
    GR: {
      INR: 479500,
      USD: 6661,
    },
    GT: {
      INR: 382800,
      USD: 5320,
    },
    GW: {
      INR: 124101,
      USD: 1720,
    },
    HT: {
      INR: 382800,
      USD: 5320,
    },
    HN: {
      INR: 382800,
      USD: 5320,
    },
    HK: {
      INR: 365700,
      USD: 5080,
    },
    HU: {
      INR: 479500,
      USD: 6661,
    },
    IN: {
      INR: 12500,
      USD: 168,
    },
    ID: {
      INR: 258254,
      USD: 3585,
    },
    IQ: {
      INR: 153100,
      USD: 2130,
    },
    IE: {
      INR: 325400,
      USD: 4520,
    },
    IL: {
      INR: 145300,
      USD: 2020,
    },
    IT: {
      INR: 325300,
      USD: 4520,
    },
    JP: {
      INR: 365700,
      USD: 5080,
    },
    JO: {
      INR: 153100,
      USD: 2130,
    },
    KE: {
      INR: 124101,
      USD: 1720,
    },
    KW: {
      INR: 153100,
      USD: 2130,
    },
    LA: {
      INR: 365700,
      USD: 5080,
    },
    LV: {
      INR: 479500,
      USD: 6661,
    },
    LB: {
      INR: 153100,
      USD: 2130,
    },
    LS: {
      INR: 124101,
      USD: 1720,
    },
    LR: {
      INR: 124101,
      USD: 1720,
    },
    LY: {
      INR: 124101,
      USD: 1720,
    },
    LT: {
      INR: 479500,
      USD: 6661,
    },
    MG: {
      INR: 124101,
      USD: 1720,
    },
    MW: {
      INR: 124101,
      USD: 1720,
    },
    MY: {
      INR: 155000,
      USD: 2150,
    },
    ML: {
      INR: 124101,
      USD: 1720,
    },
    MR: {
      INR: 124101,
      USD: 1720,
    },
    MX: {
      INR: 206100,
      USD: 2860,
    },
    MD: {
      INR: 479500,
      USD: 6661,
    },
    MN: {
      INR: 365700,
      USD: 5080,
    },
    MA: {
      INR: 124101,
      USD: 1720,
    },
    MZ: {
      INR: 124101,
      USD: 1720,
    },
    NA: {
      INR: 124101,
      USD: 1720,
    },
    NP: {
      INR: 365700,
      USD: 5080,
    },
    NL: {
      INR: 620200,
      USD: 8610,
    },
    NZ: {
      INR: 365700,
      USD: 5080,
    },
    NI: {
      INR: 382800,
      USD: 5320,
    },
    NE: {
      INR: 124101,
      USD: 1720,
    },
    NG: {
      INR: 247500,
      USD: 3440,
    },
    NO: {
      INR: 325400,
      USD: 4520,
    },
    OM: {
      INR: 153100,
      USD: 2130,
    },
    PK: {
      INR: 196200,
      USD: 2720,
    },
    PA: {
      INR: 382800,
      USD: 5320,
    },
    PG: {
      INR: 365700,
      USD: 5080,
    },
    PY: {
      INR: 382800,
      USD: 5320,
    },
    PE: {
      INR: 324600,
      USD: 4510,
    },
    PH: {
      INR: 365700,
      USD: 5080,
    },
    PL: {
      INR: 479500,
      USD: 6661,
    },
    PT: {
      INR: 325400,
      USD: 4520,
    },
    PR: {
      INR: 382800,
      USD: 5320,
    },
    QA: {
      INR: 153100,
      USD: 2130,
    },
    RO: {
      INR: 479500,
      USD: 6661,
    },
    RU: {
      INR: 369600,
      USD: 5130,
    },
    RW: {
      INR: 124101,
      USD: 1720,
    },
    SA: {
      INR: 194800,
      USD: 2710,
    },
    SN: {
      INR: 124101,
      USD: 1720,
    },
    RS: {
      INR: 479500,
      USD: 6661,
    },
    SL: {
      INR: 124101,
      USD: 1720,
    },
    SG: {
      INR: 365700,
      USD: 5080,
    },
    SK: {
      INR: 479500,
      USD: 6661,
    },
    SI: {
      INR: 479500,
      USD: 6661,
    },
    SO: {
      INR: 124101,
      USD: 1720,
    },
    ZA: {
      INR: 155300,
      USD: 2160,
    },
    SS: {
      INR: 124101,
      USD: 1720,
    },
    ES: {
      INR: 294300,
      USD: 4090,
    },
    LK: {
      INR: 365700,
      USD: 5080,
    },
    SD: {
      INR: 124101,
      USD: 1720,
    },
    SE: {
      INR: 325400,
      USD: 4520,
    },
    CH: {
      INR: 325400,
      USD: 4520,
    },
    TW: {
      INR: 365700,
      USD: 5080,
    },
    TJ: {
      INR: 365700,
      USD: 5080,
    },
    TH: {
      INR: 365700,
      USD: 5080,
    },
    TG: {
      INR: 124101,
      USD: 1720,
    },
    TN: {
      INR: 124101,
      USD: 1720,
    },
    TR: {
      INR: 71700,
      USD: 1000,
    },
    TM: {
      INR: 365700,
      USD: 5080,
    },
    UG: {
      INR: 124101,
      USD: 1720,
    },
    UA: {
      INR: 479500,
      USD: 6661,
    },
    AE: {
      INR: 153100,
      USD: 2130,
    },
    GB: {
      INR: 308400,
      USD: 4280,
    },
    US: {
      INR: 116200,
      USD: 1610,
    },
    UY: {
      INR: 382800,
      USD: 5320,
    },
    UZ: {
      INR: 365700,
      USD: 5080,
    },
    VN: {
      INR: 365700,
      USD: 5080,
    },
    YE: {
      INR: 153100,
      USD: 2130,
    },
    ZM: {
      INR: 124101,
      USD: 1720,
    },
    TZ: {
      INR: 261901,
      USD: 3640,
    },
    SZ: {
      INR: 261901,
      USD: 3640,
    },
    MK: {
      INR: 479500,
      USD: 6661,
    },
    DO: {
      INR: 382800,
      USD: 5320,
    },
    JM: {
      INR: 382800,
      USD: 5320,
    },
    CI: {
      INR: 261901,
      USD: 3640,
    },
    OTHER: {
      INR: 261901,
      USD: 3640,
    },
  },
  utility: {
    AF: {
      INR: 135243,
      USD: 1880,
    },
    AL: {
      INR: 303832,
      USD: 4219,
    },
    DZ: {
      INR: 52547,
      USD: 729,
    },
    AO: {
      INR: 52547,
      USD: 729,
    },
    AR: {
      INR: 292787,
      USD: 4063,
    },
    AM: {
      INR: 303832,
      USD: 4219,
    },
    AU: {
      INR: 135243,
      USD: 1880,
    },
    AT: {
      INR: 258289,
      USD: 3585,
    },
    AZ: {
      INR: 303832,
      USD: 4219,
    },
    BH: {
      INR: 135208,
      USD: 1877,
    },
    BD: {
      INR: 135243,
      USD: 1880,
    },
    BY: {
      INR: 303832,
      USD: 4219,
    },
    BE: {
      INR: 258289,
      USD: 3585,
    },
    BJ: {
      INR: 52547,
      USD: 729,
    },
    BO: {
      INR: 97267,
      USD: 1351,
    },
    BW: {
      INR: 52547,
      USD: 729,
    },
    BR: {
      INR: 68891,
      USD: 957,
    },
    BG: {
      INR: 303832,
      USD: 4219,
    },
    BF: {
      INR: 52547,
      USD: 729,
    },
    BI: {
      INR: 52547,
      USD: 729,
    },
    KH: {
      INR: 135243,
      USD: 1880,
    },
    CA: {
      INR: 34428,
      USD: 479,
    },
    CM: {
      INR: 52547,
      USD: 729,
    },
    TD: {
      INR: 52547,
      USD: 729,
    },
    CL: {
      INR: 172185,
      USD: 2392,
    },
    CN: {
      INR: 135243,
      USD: 1880,
    },
    CO: {
      INR: 1728,
      USD: 24,
    },
    CG: {
      INR: 66282,
      USD: 921,
    },
    CR: {
      INR: 97267,
      USD: 1351,
    },
    HR: {
      INR: 303832,
      USD: 4219,
    },
    CZ: {
      INR: 303832,
      USD: 4219,
    },
    DK: {
      INR: 258289,
      USD: 3585,
    },
    VE: {
      INR: 66282,
      USD: 921,
    },
    EC: {
      INR: 97267,
      USD: 1351,
    },
    EG: {
      INR: 44791,
      USD: 622,
    },
    SV: {
      INR: 97267,
      USD: 1351,
    },
    ER: {
      INR: 52547,
      USD: 729,
    },
    ET: {
      INR: 52547,
      USD: 729,
    },
    FI: {
      INR: 258289,
      USD: 3585,
    },
    FR: {
      INR: 258430,
      USD: 3588,
    },
    GA: {
      INR: 52547,
      USD: 729,
    },
    GM: {
      INR: 52547,
      USD: 729,
    },
    GE: {
      INR: 303832,
      USD: 4219,
    },
    DE: {
      INR: 473784,
      USD: 6584,
    },
    GH: {
      INR: 52547,
      USD: 729,
    },
    GR: {
      INR: 303832,
      USD: 4219,
    },
    GT: {
      INR: 97267,
      USD: 1351,
    },
    GW: {
      INR: 52547,
      USD: 729,
    },
    HT: {
      INR: 97267,
      USD: 1351,
    },
    HN: {
      INR: 97267,
      USD: 1351,
    },
    HK: {
      INR: 135243,
      USD: 1880,
    },
    HU: {
      INR: 303832,
      USD: 4219,
    },
    IN: {
      INR: 12500,
      USD: 168,
    },
    ID: {
      INR: 172173,
      USD: 2390,
    },
    IQ: {
      INR: 135208,
      USD: 1877,
    },
    IE: {
      INR: 258289,
      USD: 3585,
    },
    IL: {
      INR: 45649,
      USD: 634,
    },
    IT: {
      INR: 258195,
      USD: 3585,
    },
    JP: {
      INR: 135243,
      USD: 1880,
    },
    JO: {
      INR: 135208,
      USD: 1877,
    },
    KE: {
      INR: 52547,
      USD: 729,
    },
    KW: {
      INR: 135208,
      USD: 1877,
    },
    LA: {
      INR: 135243,
      USD: 1880,
    },
    LV: {
      INR: 303832,
      USD: 4219,
    },
    LB: {
      INR: 135208,
      USD: 1877,
    },
    LS: {
      INR: 52547,
      USD: 729,
    },
    LR: {
      INR: 52547,
      USD: 729,
    },
    LY: {
      INR: 52547,
      USD: 729,
    },
    LT: {
      INR: 303832,
      USD: 4219,
    },
    MG: {
      INR: 52547,
      USD: 729,
    },
    MW: {
      INR: 52547,
      USD: 729,
    },
    MY: {
      INR: 120579,
      USD: 1673,
    },
    ML: {
      INR: 52547,
      USD: 729,
    },
    MR: {
      INR: 52547,
      USD: 729,
    },
    MX: {
      INR: 86069,
      USD: 1195,
    },
    MD: {
      INR: 303832,
      USD: 4219,
    },
    MN: {
      INR: 135243,
      USD: 1880,
    },
    MA: {
      INR: 52547,
      USD: 729,
    },
    MZ: {
      INR: 52547,
      USD: 729,
    },
    NA: {
      INR: 52547,
      USD: 729,
    },
    NP: {
      INR: 135243,
      USD: 1880,
    },
    NL: {
      INR: 430708,
      USD: 5980,
    },
    NZ: {
      INR: 135243,
      USD: 1880,
    },
    NI: {
      INR: 97267,
      USD: 1351,
    },
    NE: {
      INR: 52547,
      USD: 729,
    },
    NG: {
      INR: 57752,
      USD: 802,
    },
    NO: {
      INR: 258289,
      USD: 3585,
    },
    OM: {
      INR: 135208,
      USD: 1877,
    },
    PK: {
      INR: 46530,
      USD: 646,
    },
    PA: {
      INR: 97267,
      USD: 1351,
    },
    PG: {
      INR: 135243,
      USD: 1880,
    },
    PY: {
      INR: 97267,
      USD: 1351,
    },
    PE: {
      INR: 172279,
      USD: 2392,
    },
    PH: {
      INR: 135243,
      USD: 1880,
    },
    PL: {
      INR: 303832,
      USD: 4219,
    },
    PT: {
      INR: 258289,
      USD: 3585,
    },
    PR: {
      INR: 97267,
      USD: 1351,
    },
    QA: {
      INR: 135208,
      USD: 1877,
    },
    RO: {
      INR: 303832,
      USD: 4219,
    },
    RU: {
      INR: 344405,
      USD: 4780,
    },
    RW: {
      INR: 52547,
      USD: 729,
    },
    SA: {
      INR: 98935,
      USD: 1375,
    },
    SN: {
      INR: 52547,
      USD: 729,
    },
    RS: {
      INR: 303832,
      USD: 4219,
    },
    SL: {
      INR: 52547,
      USD: 729,
    },
    SG: {
      INR: 135243,
      USD: 1880,
    },
    SK: {
      INR: 303832,
      USD: 4219,
    },
    SI: {
      INR: 303832,
      USD: 4219,
    },
    SO: {
      INR: 52547,
      USD: 729,
    },
    ZA: {
      INR: 65554,
      USD: 911,
    },
    SS: {
      INR: 52547,
      USD: 729,
    },
    ES: {
      INR: 172114,
      USD: 2390,
    },
    LK: {
      INR: 135243,
      USD: 1880,
    },
    SD: {
      INR: 52547,
      USD: 729,
    },
    SE: {
      INR: 258289,
      USD: 3585,
    },
    CH: {
      INR: 258289,
      USD: 3585,
    },
    TW: {
      INR: 135243,
      USD: 1880,
    },
    TJ: {
      INR: 135243,
      USD: 1880,
    },
    TH: {
      INR: 135243,
      USD: 1880,
    },
    TG: {
      INR: 52547,
      USD: 729,
    },
    TN: {
      INR: 52547,
      USD: 729,
    },
    TR: {
      INR: 45637,
      USD: 634,
    },
    TM: {
      INR: 135243,
      USD: 1880,
    },
    UG: {
      INR: 52547,
      USD: 729,
    },
    UA: {
      INR: 303832,
      USD: 4219,
    },
    AE: {
      INR: 135231,
      USD: 1877,
    },
    GB: {
      INR: 189434,
      USD: 2629,
    },
    US: {
      INR: 34428,
      USD: 479,
    },
    UY: {
      INR: 97267,
      USD: 1351,
    },
    UZ: {
      INR: 135243,
      USD: 1880,
    },
    VN: {
      INR: 135243,
      USD: 1880,
    },
    YE: {
      INR: 135208,
      USD: 1877,
    },
    ZM: {
      INR: 52547,
      USD: 729,
    },
    TZ: {
      INR: 66282,
      USD: 921,
    },
    SZ: {
      INR: 66282,
      USD: 921,
    },
    MK: {
      INR: 303832,
      USD: 4219,
    },
    DO: {
      INR: 97267,
      USD: 1351,
    },
    JM: {
      INR: 97267,
      USD: 1351,
    },
    CI: {
      INR: 66282,
      USD: 921,
    },
    OTHER: {
      INR: 66282,
      USD: 921,
    },
  },
};

module.exports = {
  AISENSY_WCC_PLAN,
};
