import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import {
  Facebook,
  SentimentDissatisfied,
  SentimentSatisfied,
} from "@material-ui/icons";
import Confetti from "react-confetti";

import WhatsAppLogo from "../../static/WhatsAppLogo.svg";
import WhatsAppLogoDark from "../../static/WhatsAppLogo.png";
import AiSensyLogo from "../../static/full_logo.png";
import Logo from "../../static/logo.jpg";
import { timeZones } from "../../config/timezone";
import {
  ADMIN_KEY,
  APP_URL,
  partnerURL,
  URL,
  APP_ID,
} from "../../config/config";
import { checkFbConnectRateLimit } from "../../helpers/rateLimit";
import { connect } from "react-redux";
import { fbInit } from "../../helpers/metaHelper";

export class RegisterWaba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      data: {},
      snackbar: {},
      readTnC: false,
      fbLoading: false,
      runConfetti: false,
      disableButton: false,
      completed: false,
      redirect: 5,
    };
  }

  validateUser = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: resData } = await axios.post(
        `${partnerURL}/api/verify-waba-link/${id}`
      );
      this.setState({ validated: true, data: resData });
      // Start Intercom only for AiSensy Embedded Signup
      if (
        resData &&
        (!resData.partnerId ||
          resData.data.partnerId === "627ea4876340e14cf7081778")
      ) {
        this.startIntercom();
      }
    } catch (error) {
      if (error.response?.data?.message) {
        this.setState({
          snackbar: {
            message: error.response.data.message,
            severity: "error",
            code: error.response?.status,
          },
        });
      }
      console.log(error);
    }
  };

  startIntercom = () => {
    window.Intercom("boot", {
      app_id: "idoa5x5u",
      hide_default_launcher: true,
    });
  };

  disableIntercom = () => {
    window.Intercom("shutdown");
  };

  componentDidMount = () => {
    this.validateUser();
    fbInit({ APP_ID });
  };

  componentWillUnmount = () => {
    this.disableIntercom();
  };

  closeSnackbar = () => {
    this.setState({ snackbar: {} });
  };

  redirectUser = (token) => {
    window.setInterval(() => {
      if (this.state.redirect <= 0) {
        window.location.replace(
          token ? `${APP_URL}/login?token=${token}` : APP_URL
        );
      } else {
        this.setState({ redirect: this.state.redirect - 1 });
      }
    }, 1000);
  };

  notifyMobileApp = (data) => {
    console.log(data);
    const myCustomEvent = new CustomEvent("notifyMobileApp", {
      detail: {
        message: data,
        time: new Date(),
      },
    });

    document.dispatchEvent(myCustomEvent);
  };

  launchWhatsAppSignup = async () => {
    const { data } = this.state;
    const {
      assistantId,
      businessCompany,
      businessEmail,
      businessContact,
      businessTimezone,
      businessWebsite,
      businessDescription,
      businessCategory,
      setup,
      specialAccess,
      partnerId,
      businessId,
    } = data || {};

    this.setState({ fbLoading: true });
    const { business, phone } = setup || {};

    const rateLimitBreached = await checkFbConnectRateLimit(
      assistantId,
      (data) => this.setState(data)
    );
    if (rateLimitBreached) return;

    const { id } = this.props.match.params;

    window.launchWhatsAppSignup && window.launchWhatsAppSignup();
    window.FB?.login &&
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const authResponse = response.authResponse;
            let url = partnerURL + "/api/submit-facebook-access-token";
            if (!partnerId) {
              url += "?redirect=true";
            }

            axios
              .post(url, {
                ...authResponse,
                key: id,
              })
              .then(async (data) => {
                if (data.data?.success) {
                  if (this.state.data && !this.state.data.partnerId) {
                    const token = data.data.token;
                    this.redirectUser(token);
                  }

                  this.setState({
                    validated: false,
                    snackbar: {
                      message: "WABA is Live!",
                      severity: "success",
                      alert: true,
                    },
                    runConfetti: true,
                    disableButton: true,
                    completed: true,
                  });
                  if (partnerId == "66fe33ed494c320b70b659e6") {
                    axios
                      .post(
                        partnerURL +
                          `/api/generate-connect-ads-link?version=v2`,
                        {
                          assistantId,
                          partnerId,
                          agentId: businessId,
                        }
                      )
                      .then((response) => {
                        const { link } = response.data;
                        window.open(link, "_blank");
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                } else {
                  this.setState({
                    snackbar: {
                      message:
                        "WABA in progress! Please Check after 10 minutes",
                      severity: "info",
                      alert: true,
                    },
                  });
                }
                this.notifyMobileApp("connected");
              })
              .catch((err) => {
                this.notifyMobileApp("error connecting");
                console.log(err);
                this.setState({
                  snackbar: {
                    message:
                      err?.response?.data?.message || "Something went wrong!",
                    severity: "error",
                    alert: true,
                  },
                });
              })
              .finally(() => {
                this.setState({
                  fbLoading: false,
                });
              });
          } else {
            this.setState({
              snackbar: {
                message: "Connection Failed!",
                severity: "error",
                alert: true,
              },
              fbLoading: false,
            });
          }
        },
        {
          scope:
            "business_management,whatsapp_business_management,whatsapp_business_messaging",
          extras: {
            feature: "whatsapp_embedded_signup",
            featureType: specialAccess?.coexistence ? "coexistence" : undefined,
            setup: {
              business: {
                name: business?.name || businessCompany || "",
                email: business?.email || businessEmail || "",
                phone: {
                  code: business?.phone?.code || 91,
                  number: business?.phone?.number || businessContact || "",
                },
                website: business?.website || businessWebsite || "",
                address: {
                  streetAddress1: business?.address?.streetAddress1 || "",
                  city: business?.address?.city || "",
                  state: business?.address?.state || "",
                  zipPostal: business?.address?.zipPostal || "",
                  country: business?.address?.country || "",
                },
                timezone:
                  business?.timezone ||
                  `UTC${
                    timeZones.find((i) => i.label.includes(businessTimezone))
                      ?.value || "+05:30"
                  }`,
              },
              phone: {
                displayName: phone?.displayName || businessCompany || "",
                category: phone?.category || businessCategory || "",
                description: phone?.description || businessDescription || "",
              },
            },
          },
          return_scopes: true,
        }
      );
  };

  render() {
    const {
      validated,
      snackbar,
      readTnC,
      data,
      fbLoading,
      runConfetti,
      disableButton,
      completed,
    } = this.state;
    const { tenantDetails } = this.props;
    const { classes } = this.props;
    const { assistantName, businessCompany, businessName, businessEmail } =
      data || {};
    let { partnerId, partnerLogo, partnerName } = data || {};
    if (!partnerId && partnerId === "627ea4876340e14cf7081778") {
      partnerLogo = AiSensyLogo;
      partnerName = "AiSensy";
    }

    const isTenant = !!Object.keys(this.props?.tenantDetails).length;

    const mobileScreen = window.innerWidth < 768;

    const confettiCount = window.innerHeight < 767 ? 150 : 500;

    return (
      <div className={classes.root}>
        {partnerName !== "AiSensy" && (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          >
            <Grid item xs>
              <Box
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <img
                  src={partnerLogo}
                  alt={partnerName}
                  style={{ maxHeight: 40 }}
                />

                {window.location.origin != "https://www.waba.api-wa.co" && (
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      maxWidth: "45%",
                      textAlign: "center",
                      height: "40px",
                      overflow: "hidden",
                      fontWeight: 600,
                    }}
                  >
                    {isTenant ? tenantDetails.name : "AiSensy"} is a business
                    Solution Provider and has been given the right by
                    Facebook/WhatsApp to grant their clients access to the
                    WhatsApp Business Platform.
                  </Typography>
                )}

                <img src={WhatsAppLogo} alt="whatsapp" style={{ height: 40 }} />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid container justify="center" style={{ margin: "3em 0" }}>
          {validated ? (
            <Grid item xs={12} md={6}>
              <Box
                className={classes.container}
                style={{ padding: partnerName !== "AiSensy" ? "2em" : 0 }}
                textAlign="center"
              >
                <div>
                  {partnerName !== "AiSensy" ? (
                    <img
                      src={partnerLogo}
                      alt={partnerName}
                      style={{ maxHeight: 60 }}
                    />
                  ) : (
                    <Box
                      px={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        background: "#b7b7b721",
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        padding: "1em 2em",
                      }}
                    >
                      <img
                        src={partnerLogo}
                        alt={partnerName}
                        style={{ maxHeight: mobileScreen ? 25 : 45 }}
                      />

                      <img
                        src={WhatsAppLogoDark}
                        alt="whatsapp"
                        style={{ height: mobileScreen ? 20 : 40 }}
                      />
                    </Box>
                  )}
                  <Box
                    style={{ padding: partnerName === "AiSensy" ? "2em" : 0 }}
                  >
                    {partnerName !== "AiSensy" && (
                      <Typography className={classes.partnerTitle}>
                        {partnerName}
                      </Typography>
                    )}
                    <Box textAlign="left" px={2} py={3}>
                      <div className={classes.containerSegment}>
                        <Typography variant="h4" color="primary" gutterBottom>
                          {partnerName === "AiSensy"
                            ? "Apply for WhatsApp Business API"
                            : "Get Your WhatsApp Business Platform Account"}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {partnerName === "AiSensy"
                            ? "WhatsApp Business API is an essential step for creating your WhatsApp Business Profile. Once this is done you can broadcast offers, automate notifications and solve for support using AiSensy Platform"
                            : "Please verify the information about your company and the phone number that you would like to connect to the WhatsApp Business API. Please make sure that your company follows Facebook's compliance policy."}
                        </Typography>
                      </div>
                      <div className={classes.containerSegment}>
                        <Typography variant="h4" color="primary" gutterBottom>
                          {partnerName === "AiSensy"
                            ? "Requirements"
                            : "BUSINESS INFORMATION"}
                        </Typography>
                        {partnerName === "AiSensy" ? (
                          <Typography variant="body1" color="textSecondary">
                            Please be ready with a mobile number on which use
                            wish to create your WhatsApp Business Profile.
                          </Typography>
                        ) : (
                          <Typography
                            className={classes.infoWrapper}
                            variant="body1"
                            color="textSecondary"
                          >
                            <div>
                              <b>Name :</b>
                              <span className={classes.info}>
                                {businessName}
                              </span>
                            </div>
                            <div>
                              <b>Email :</b>
                              <span className={classes.info}>
                                {businessEmail}
                              </span>
                            </div>
                            <div>
                              <b>Company :</b>
                              <span className={classes.info}>
                                {businessCompany}
                              </span>
                            </div>
                            <div>
                              <b>Project :</b>
                              <span className={classes.info}>
                                {assistantName}
                              </span>
                            </div>
                          </Typography>
                        )}
                      </div>
                      {partnerName === "AiSensy" ? (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ textAlign: "center" }}
                        >
                          Click on "
                          {partnerName === "AiSensy"
                            ? "Start WhatsApp API Registration"
                            : "Connect to Facebook"}
                          " to apply for WhatsApp Business API
                        </Typography>
                      ) : (
                        <div>
                          {!isTenant && (
                            <Typography
                              variant="h5"
                              color="primary"
                              gutterBottom
                            >
                              Terms & Conditions
                            </Typography>
                          )}

                          {!isTenant && (
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={readTnC}
                                  onChange={(e) => {
                                    this.setState({
                                      readTnC: e.target.checked,
                                    });
                                  }}
                                  color="primary"
                                  style={{ padding: 0 }}
                                />
                              }
                              label={
                                <Typography
                                  className={classes.info}
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  I agree to the{" "}
                                  <a
                                    href="https://m.aisensy.com/tos/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{
                                      cursor: "pointer",
                                      borderBottom: "solid 1px",
                                    }}
                                  >
                                    AiSensy Terms of Service
                                  </a>
                                </Typography>
                              }
                            />
                          )}
                        </div>
                      )}
                    </Box>
                    <Button
                      size="large"
                      disabled={
                        isTenant
                          ? false
                          : partnerName !== "AiSensy"
                          ? !readTnC || fbLoading || disableButton
                          : false
                      }
                      startIcon={<Facebook />}
                      onClick={this.launchWhatsAppSignup}
                      style={{
                        fontSize: 18,
                        color: "white",
                        background:
                          partnerName === "AiSensy" ||
                          isTenant ||
                          (readTnC && !fbLoading && !disableButton)
                            ? "#1877F2"
                            : "lightgrey",
                        fontWeight: 600,
                      }}
                      variant="contained"
                      color="primary"
                      endIcon={
                        fbLoading ? (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        ) : (
                          ""
                        )
                      }
                    >
                      {partnerName === "AiSensy"
                        ? "Start WhatsApp API Registration"
                        : "Connect to Facebook"}
                    </Button>
                  </Box>
                </div>
              </Box>
            </Grid>
          ) : completed ? (
            <Grid container justify="center">
              <Box textAlign="center" className={classes.successContainer}>
                <div className={classes.successSmile}>
                  <SentimentSatisfied style={{ fontSize: "8.1875em" }} />
                  <Typography variant="h2" style={{ fontWeight: "normal" }}>
                    Thank You
                  </Typography>
                </div>
                <div className={classes.successMessage}>
                  <Typography variant="h2">
                    Your WhatsApp Business Account is now live!
                  </Typography>
                </div>
                {partnerName === "AiSensy" && (
                  <Typography variant="v4">
                    Redirecting in {this.state.redirect}
                  </Typography>
                )}
              </Box>
            </Grid>
          ) : snackbar?.code ? (
            <Grid container justify="center">
              <Box textAlign="center" className={classes.errorContainer}>
                <div className={classes.errorSmile}>
                  <SentimentDissatisfied style={{ fontSize: "8.1875em" }} />
                  <Typography variant="h2" style={{ fontWeight: "normal" }}>
                    {"ERROR " + snackbar.code}
                  </Typography>
                </div>
                <div className={classes.errorMessage}>
                  <Typography variant="h2">{snackbar.message}</Typography>
                </div>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <div>
                  <Skeleton
                    variant="rounded"
                    width={60}
                    height={60}
                    className={classes.skeleton}
                  />
                  <Box textAlign="left" px={2} py={3}>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="60%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      <Skeleton variant="rounded" height={60} />
                    </div>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      {[1, 2, 3, 4].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={16}
                          width="40%"
                          style={{ marginBottom: "0.3em", marginLeft: "2.5em" }}
                        />
                      ))}
                    </div>
                    <div>
                      {[1, 2].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={18}
                          width="40%"
                          style={{ marginBottom: "0.4em" }}
                        />
                      ))}
                    </div>
                  </Box>
                  <Skeleton
                    variant="rounded"
                    width={230}
                    height={50}
                    className={classes.skeleton}
                  />
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {partnerName !== "AiSensy" &&
          !isTenant &&
          window.location.origin != "https://www.waba.api-wa.co" && (
            <Box position="fixed" bottom="5px" right="20px" textAlign="center">
              <Typography variant="body2" color="textSecondary">
                Powered⚡ by
              </Typography>
              <Grid container alignItems="center">
                <Grid item>
                  <img
                    alt="AiSensy"
                    src={AiSensyLogo}
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        <Snackbar
          open={snackbar.alert}
          onClose={this.closeSnackbar}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Confetti
          run={runConfetti}
          numberOfPieces={confettiCount}
          gravity={0.3}
          initialVelocityY={{ min: 12, max: 30 }}
          recycle={false}
          onConfettiComplete={() => this.setState({ runConfetti: false })}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#e8ece1",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "fixed",
    left: 0,
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 70,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "#0a474c",
  },
  container: {
    background: "white",
    borderRadius: 8,
    padding: "0em",
  },
  partnerTitle: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 16,
  },
  successContainer: {
    height: "27em",
    border: "solid #32CD32 0.5em",
    borderRadius: "0.5em",
    width: "20em",
  },
  successSmile: {
    background: "#32CD32",
    height: "11em",
    padding: "2em",
    color: "white",
  },
  successMessage: {
    height: "12em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 16,
  },
  errorContainer: {
    height: "27em",
    border: "solid #db5046 0.5em",
    borderRadius: "0.5em",
    width: "20em",
  },
  errorSmile: {
    background: "#db5046",
    height: "11em",
    padding: "2em",
    color: "white",
  },
  errorMessage: {
    height: "12em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#db5046",
  },
  containerSegment: {
    marginBottom: "1.5em",
  },
  infoWrapper: {
    marginLeft: "2em",
  },
  info: {
    marginLeft: "0.5em",
  },
  logo: {
    height: 30,
    verticalAlign: "middle",
  },
  skeleton: {
    margin: "auto",
  },
});

const connectedRegisterWABA = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(RegisterWaba);

export default withStyles(styles)(withRouter(connectedRegisterWABA));
