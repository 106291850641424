import React, { Component } from "react";
import {
  withStyles,
  Box,
  Typography,
  Dialog,
  CircularProgress,
} from "@material-ui/core";

import clsx from "clsx";
import AssistantsDetails from "./AssistantsDetails";
import { BorderRight } from "@material-ui/icons";
import BvbpDialog from "./BvbpDialog";

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];
class AssistantsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }

  getFields = () => {
    const { bvbp, value } = this.props;

    const conditionalFields = [
      { name: "User Submission", key: "kycApplication" },
      { name: "Meta Submission", key: "metaSubmission" },
      { name: "Limit Increased On", key: "limitIncreased" },
    ];

    const commonFields = [
      { name: "Assistant Name", key: "assistantName" },
      { name: "Created At", key: "createdAt" },
      { name: "App Name", key: "appName" },
      { name: "Messaging Limit", key: "dailyTemplateLimit" },
      { name: "Verification Status", key: "isWhatsappVerified" },
      { name: "Active Plan", key: "activePlan" },
      { name: "Status", key: "status" },
      { name: "Template Credit", key: "templateCredit" },
    ];

    const extraFields = [
      { name: "Test Number", key: "testNumber" },
      { name: "WhatsApp Number", key: "whatsappNumber" },
      { name: "Plan Activated", key: "planActivatedOn" },
      { name: "Rem Credit", key: "remainingCredit" },
      { name: "Rem MAC", key: "remainingMac" },
      { name: "Excess MAC", key: "excessMac" },
      { name: "Total Quota", key: "macQuota" },
      { name: "MAC Usage", key: "macUsage" },
      { name: "Template Tier", key: "templateTier" },
      { name: "Provider Type", key: "providerType" },
      { name: "Pricing Type", key: "type" },
      { name: "Session Msgs", key: "remainingSessionMessages" },
      { name: "Enterprise Msgs", key: "remainingEnterpriseSessions" },
      { name: "Excess Usage", key: "excessUsage" },
      { name: "Df Project Id", key: "dfProjectId" },
      { name: "Max Agents", key: "maxAgents" },
    ];

    let field = [{ name: "Assistant Id", key: "_id" }, ...commonFields];

    if (!bvbp) {
      field = [...field, ...extraFields];
    } else if (
      typeof value === "number" &&
      value !== 3 &&
      value >= 0 &&
      value < conditionalFields.length
    ) {
      field = [field[0], conditionalFields[value], ...field.slice(1)];
    }

    return field;
  };

  // fields =

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openTemplate = (e) => {
    const templates = [...this.props.templates];
    const foundIndex = templates.findIndex((x) => x._id == e._id);
    this.setState({ openDialog: true, selectedTemplate: foundIndex });
  };
  render() {
    const {
      classes,
      templates,
      isLoading,
      selectedTemplate,
      partnerPage,
      bvbp,
      value,
      alertHandler,
    } = this.props;
    return (
      <div className={partnerPage ? classes.partnerPageRoot : classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {this.getFields().map((field) => (
                <th className={clsx(classes.column, classes.dataColumn)}>
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
            {isLoading ? (
              <Box my={4} display="flex" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <React.Fragment>
                {templates.map((e, index) => (
                  <tr
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                      [classes.stayToLeft]: e.key == "assistantName",
                    })}
                  >
                    {this.getFields().map((field) => (
                      <th
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => {
                          this.openTemplate(e);
                        }}
                      >
                        {columnType(classes, e, field)}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        <Dialog
          open={this.state.openDialog}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          fullScreen={!bvbp}
        >
          {/* Assistants Details */}

          {!bvbp ? (
            <AssistantsDetails
              templateIndex={this.state.selectedTemplate}
              templates={this.props.templates}
              toggleDialog={this.toggleDialog}
              setTemplate={this.props.setTemplate}
            />
          ) : (
            <>
              <BvbpDialog
                value={value}
                toggleDialog={this.toggleDialog}
                open={this.state.openDialog}
                AssistantsDetails={
                  this.props.templates[this.state.selectedTemplate]
                }
                alertHandler={alertHandler}
                setTemplateData={this.props.setTemplateData}
              />
            </>
          )}
        </Dialog>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName) {
  const key = fieldName.key;

  switch (key) {
    case "remainingCredit":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] / 100000}
        </Typography>
      );
      break;
    case "templateCredit":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] / 100000}
        </Typography>
      );
      break;
    case "excessUsage":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] / 100000}
        </Typography>
      );
      break;
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    case "planActivatedOn":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    case "kycApplication":
      return (
        <Typography variant="body1" align="center">
          { getFormattedDate(
            columnData?.gstInfo?.uploadDate ||
              columnData?.aadhaarInfo?.uploadDate
          )}
        </Typography>
      );
    case "metaSubmission":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData?.aadhaarInfo?.application)}
        </Typography>
      );
    case "limitIncreased":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData?.aadhaarInfo?.approved)}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  partnerPageRoot: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    borderRadius: "8px",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#EBF5F3",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
    // minHeight: "80px",
    // display: "flex",
    // background: "red"
    // flexWrap: "wrap"
  },
  stayToLeft: {
    position: "sticky",
    left: 0,
    top: 0,
  },
  checkboxColumn: {
    width: "60px",
    // background: "red"
  },
});

export default withStyles(styles)(AssistantsTable);
