import React from "react";
import {
  ChatOutlined,
  PersonOutlined,
  AssistantOutlined,
  TimelineOutlined,
  DashboardOutlined,
  GroupWorkOutlined,
  PermIdentity,
  Call,
  StorefrontOutlined,
  FeaturedPlayListOutlined,
  AssignmentInd,
} from "@material-ui/icons";

import AgentProfilePage from "../../../pages/AgentProfile/AgentProfilePage";
import DashboardPage from "../../../pages/Dashboard/DashboardPage";
import TemplateLibraryPage from "../../../pages/TemplateLibrary/TemplateLibraryPage";
import TemplateMsgPage from "../../../pages/TemplateMsg/TemplateMsgPage";
import AssistantsPage from "../../../pages/Assistants/AssistantsPage";
import OrdersPage from "../../../pages/Orders/OrdersPage";
import ClientsPage from "../../../pages/Clients/ClientsPage";
import PartnersPage from "../../../pages/Partners/PartnersPage";
import CRMPage from "../../../pages/CRM/CRMPage";
import VirtualNumberPage from "../../../pages/VirtualNumber/VirtualNumberPage";
import Report from "../../../pages/Reporting/Report";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AffiliatePage from "../../../pages/Affiliates/AffiliatePage";
import SettingsIcon from "@material-ui/icons/Settings";
import ManageAgents from "../../Manage/ManageAgents";
import KycSignalPage from "../../../pages/KycSignal/KycSignalPage";
import AccessCodeDashboard from "../../../pages/AccessCode/AccessCodeDashboard";
import FireplaceIcon from "@material-ui/icons/Fireplace";

const tenantRoutes = [
  {
    name: "Employee",
    to: "/employee",
    component: ManageAgents,
    icon: <SettingsIcon style={{ fontSize: "20px" }} />,
    power: 20,
    manage: 0,
  },
  {
    name: "Assistants",
    to: "/assistants",
    component: AssistantsPage,
    icon: <FeaturedPlayListOutlined style={{ fontSize: "20px" }} />,
    power: 12,
    manage: 0,
  },
  {
    name: "Partners",
    to: "/",
    component: PartnersPage,
    icon: <GroupWorkOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
];

const topRoutes = [
  {
    name: "Reporting",
    to: "/reporting",
    component: Report,
    icon: <AssessmentIcon style={{ fontSize: "20px" }} />,
    power: 9,
    manage: 0,
  },
  {
    name: "CRM",
    to: "/crm",
    component: CRMPage,
    icon: <PermIdentity style={{ fontSize: "20px" }} />,
    power: 9,
    manage: 0,
  },
  {
    name: "Templates",
    to: "/template-messages",
    component: TemplateMsgPage,
    icon: <ChatOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
  {
    name: "Assistants",
    to: "/assistants",
    component: AssistantsPage,
    icon: <FeaturedPlayListOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },

  {
    name: "Kyc Signal",
    to: "/kyc",
    component: KycSignalPage,
    icon: <AssignmentInd style={{ fontSize: "20px" }} />,
    power: 11,
    manage: 0,
  },
  {
    name: "Affiliates",
    to: "/affiliates",
    component: AffiliatePage,
    icon: <GroupAddIcon style={{ fontSize: "20px" }} />,
    power: 11,
    manage: 0,
  },
  {
    name: "Offer Codes",
    to: "/diwali-offer-codes",
    component: AccessCodeDashboard,
    icon: <FireplaceIcon style={{ fontSize: "20px" }} />,
    power: 9,
    manage: 0,
  },
  {
    name: "Clients",
    to: "/clients",
    component: ClientsPage,
    icon: <StorefrontOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
  {
    name: "Orders",
    to: "/orders",
    component: OrdersPage,
    icon: <TimelineOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
  {
    name: "Library",
    to: "/library",
    component: TemplateLibraryPage,
    icon: <AssistantOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
  {
    name: "Partners",
    to: "/partners",
    component: PartnersPage,
    icon: <GroupWorkOutlined style={{ fontSize: "20px" }} />,
    power: 10,
    manage: 0,
  },
  {
    name: "Virtual Numbers",
    to: "/numbers",
    component: VirtualNumberPage,
    icon: <Call style={{ fontSize: "20px" }} />,
    power: 11,
    manage: 1,
  },
  {
    name: "Employee",
    to: "/employee",
    component: ManageAgents,
    icon: <SettingsIcon style={{ fontSize: "20px" }} />,
    power: 20,
    manage: 0,
  },
  {
    name: "Dashboard",
    to: "/",
    component: DashboardPage,
    icon: <DashboardOutlined style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
  },

  // {
  //   name: "Dialogflow",
  //   to: "/dialogflow",
  //   component: DfIntegrationPage,
  //   icon: <AssistantOutlined style={{ fontSize: "20px" }} />,
  //   power: 2,
  //   manage: 1,
  // },
  // {
  //   name: "Template Message",
  //   to: "/template-message",
  //   component: TemplateMsgPage,
  //   icon: <Message style={{ fontSize: "20px" }} />,
  //   power: 2,
  //   manage: 1,
  // },
  // {
  //   name: "Tags",
  //   to: "/tags",
  //   component: TagsPage,
  //   icon: <LoyaltyOutlined style={{fontSize: "20px"}}/>,
  //   power: 0,
  //   manage: 1
  // },
  // {
  //   name: "Campaigns",
  //   to: "/campaigns",
  //   component: CampaignsPage,
  //   icon: <FlightTakeoffOutlined style={{ fontSize: "20px" }} />,
  //   power: 2,
  //   manage: 0,
  // },
  // {
  //   name: "Canned",
  //   to: "/canned-messages",
  //   component: CannedMessagesPage,
  //   icon: <LoyaltyOutlined style={{fontSize: "20px"}}/>,
  //   power: 2,
  //   manage: 0
  // },
  // {
  //   name: "Billings",
  //   to: "/billings",
  //   component: BillingsPage,
  //   icon: <AccountBalanceWallet style={{ fontSize: "20px" }} />,
  //   power: 2,
  //   manage: 1,
  // },
];

const bottomRoutes = [
  {
    name: "Profile",
    to: "/agentProfile",
    component: AgentProfilePage,
    icon: <PersonOutlined />,
  },
];

export { topRoutes, bottomRoutes, tenantRoutes };
