import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "lodash";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import {
  CheckCircleOutline,
  Clear,
  Facebook,
  OpenInNew,
  SentimentDissatisfied,
} from "@material-ui/icons";
import Confetti from "react-confetti";

import WhatsAppLogo from "../../static/WhatsAppLogo.svg";
import AiSensyLogo from "../../static/full_logo.png";
import { partnerURL, URL as baseUrl, APP_ID } from "../../config/config";
import { checkFbConnectRateLimit } from "../../helpers/rateLimit";
import { connect } from "react-redux";
import { fbInit } from "../../helpers/metaHelper";
import { getHostInfo } from "../../helpers/tenantHelper";
import { getOptionLabel } from "./AdsHelper";

function SetupComponent(props) {
  const { classes, data, showSnackbar } = props;
  const {
    assistantId,
    clientId,
    partnerId,
    agentId,
    partnerLogo,
    partnerName,
    partnerBrand,
    businessCompany,
    businessName,
    businessEmail,
    businessContact,
    businessTimezone,
    version,
    v2SetupData,
    isTSP,
    key,
  } = data;

  const {
    fbConnectedBy,
    selectedMetaPageId,
    selectedMetaAdAccountId,
    fbGrantedScopes,
    freezedMetaAdAccountId,
    freezedMetaPageId,
    adSetupDetails,
    isWhatsappVerified,
    specialAccess,
    assistantName,
    fbUserId,
    currency,
  } = v2SetupData || {};

  const [fbConnected, setFbConnected] = useState(v2SetupData?.fbConnected);
  const [whatsappNumber, setWhatsappNumber] = useState(
    v2SetupData?.whatsappNumber
  );
  const [fbpages, setFbPages] = useState(data.v2SetupData.fbPages);
  const { isPartner } = getHostInfo(partnerId);
  const [fbaccount, setFbaccount] = useState();
  const [accountValue, setAccountValue] = useState();
  const [loading, setLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const [submitOtpLoader, setSubmitOtpLoader] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [otp, setOtp] = useState();
  const [dialog, setDialog] = useState(false);
  const [otpDialog, setOtpDialog] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [pageValue, setPageValue] = useState({});
  const [businessNumber, setBusinessNumber] = useState(
    isWhatsappVerified ? whatsappNumber : ""
  );

  const [assistantStore, setAssistantStore] = useState({
    fbConnectedBy,
    selectedMetaPageId,
    selectedMetaAdAccountId,
    fbGrantedScopes,
    freezedMetaAdAccountId,
    freezedMetaPageId,
    fbUserId,
  });
  console.log({ props });

  const modifyList = (data, assistantObj, id) => {
    const { freezedMetaPageId, freezedMetaAdAccountId } = assistantObj;

    const result = isEmpty(data)
      ? []
      : data?.map((obj) => {
          const { metaBusinessId, isAssigned, pageId, accountId } = obj || {};

          let freezed = false;
          if (pageId && id !== "") {
            id = id || freezedMetaPageId;
            freezed = pageId === id ? true : false;
          } else if (accountId && id !== "") {
            id = id || freezedMetaAdAccountId;
            freezed = accountId === id ? true : false;
          }

          let tag = "Limited";
          if (isAssigned) tag = "Assigned";
          else if (!metaBusinessId) tag = "Personal";
          return { ...obj, tag, freezed };
        });

    return result;
  };

  const getFbPages = (assistantId, assistantObj) => {
    if (assistantId) {
      axios
        .post(`${partnerURL}/api/connect-ads/fetch-pages`, {
          assistantId: assistantId,
          key,
        })
        .then((res) => {
          const {
            selectedMetaPageId,
            freezedMetaPageId,
            freezedMetaAdAccountId,
            fbConnectedBy,
          } = assistantObj;

          const filteredPages = modifyList(res.data, assistantObj);
          setFbPages(filteredPages);

          if (
            fbConnectedBy &&
            fbConnectedBy !== agentId &&
            freezedMetaAdAccountId
          ) {
            const selectedPageValue = freezedMetaPageId
              ? res.data?.find(({ pageId }) => pageId === freezedMetaPageId)
              : {};
            setPageValue(selectedPageValue);
          } else if (selectedMetaPageId) {
            const selectedPageValue = res.data?.find(
              ({ pageId }) => pageId === selectedMetaPageId
            );
            setPageValue(selectedPageValue);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const getFbAcc = (assistantId) => {
    if (assistantId) {
      axios
        .post(`${partnerURL}/api/connect-ads/fetch-ad-accounts`, {
          assistantId: assistantId,
          key,
        })
        .then((res) => {
          // const {
          //   selectedMetaAdAccountId,
          //   freezedMetaAdAccountId,
          //   fbConnectedBy,
          // } = v2SetupData;
          // const id = agentId;

          const filteredAccounts = modifyList(res.data);

          setFbaccount(filteredAccounts);

          // if (fbConnectedBy && fbConnectedBy !== id && freezedMetaAdAccountId) {
          //   const selectedAccountValue = res.data?.find(
          //     ({ isChildBm }) => isChildBm
          //   );
          //   this.setState(
          //     {
          //       accountValue: selectedAccountValue,
          //     },
          //     () => {
          //       this.syncMetaAcc();
          //     }
          //   );
          // } else if (selectedMetaAdAccountId) {
          //   const selectedAccountValue = res.data?.find(
          //     ({ accountId }) => accountId === selectedMetaAdAccountId
          //   );
          //   this.setState(
          //     {
          //       accountValue: selectedAccountValue,
          //     },
          //     () => {
          //       this.syncMetaAcc();
          //     }
          //   );
          // }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const saveAdProfile = (accountValue, pageValue) => {
    if (!isEmpty(accountValue) && !isEmpty(pageValue)) {
      axios
        .post(`${partnerURL}/api/connect-ads/select-ad-profile`, {
          assistantId,
          selectedMetaAdAccountId: accountValue.accountId,
          selectedMetaPageId: pageValue.pageId,
          key,
        })
        .then((res) => {})
        .catch((error) => console.log("error:", error));
    }
  };

  const selectPageAndAdAcc = (value, accountValue) => {
    setPageValue(value);
    setAccountValue(accountValue);

    const fbOwner = !fbConnectedBy || fbConnectedBy === agentId;
    if (fbOwner || !freezedMetaAdAccountId) {
      setAssistantStore({
        ...assistantStore,
        selectedMetaPageId: value.pageId,
        selectedMetaAdAccountId: accountValue.accountId,
      });
      saveAdProfile(accountValue, value);
    }
  };

  const setupChildBusinessAccount = async (value) => {
    setDialog(false);
    setLoading(true);

    await axios
      .post(`${partnerURL}/api/connect-ads/setup-business-account`, {
        assistantId,
        pageId: value?.pageId,
        timezoneId: currency === "INR" ? 71 : 1,
        vertical: "OTHER",
        bmName: assistantName,
        key,
      })
      .then((res) => {
        setDialog(false);
        setLoading(true);
        selectPageAndAdAcc(value, res.data.adAccount);
        setLoading(false);
        showSnackbar({
          snack: true,
          snackiv: "success",
          snackmessage: "Account Setup Successfully",
        });
      })
      .catch((error) => {
        setLoading(false);
        setSelectedPage(null);
        showSnackbar({
          snack: true,
          snackiv: "error",
          snackmessage:
            error?.response?.data?.message ||
            "Unable to setup account to run ads",
        });
      });
  };

  const { whatsappNumber: pageWhatsappNumber } = pageValue || {};
  const onDialogClose = () => {
    setDialog(false);
    setSelectedPage(null);
  };

  const notifyMobileApp = (data) => {
    console.log(data);
    const myCustomEvent = new CustomEvent("notifyMobileApp", {
      detail: {
        message: data,
        time: new Date(),
      },
    });

    document.dispatchEvent(myCustomEvent);
  };
  useEffect(() => {
    notifyMobileApp("PageIntialLoad");
    setPageValue(
      fbpages.find((item) => {
        return item.pageId == selectedMetaPageId;
      })
    );
  }, []);
  const sortByBusinessName = (pages) => {
    return (
      pages?.sort(function (a, b) {
        if (a.metaBusinessName < b.metaBusinessName) {
          return -1;
        }
        if (a.metaBusinessName > b.metaBusinessName) {
          return 1;
        }
        return 0;
      }) || []
    );
  };

  const checkPermissions = async (token, assistantId) => {
    const { adsPageOptional } = specialAccess || {};

    return await axios
      .post(partnerURL + `/api/connect-ads/get-fb-token-info`, {
        inputToken: token,
        assistantId,
        key,
      })
      .then((response) => {
        const { granularScopes } = response.data;

        let isTargetIds = false;

        for (let index = 0; index < (granularScopes || []).length; index++) {
          const element = granularScopes[index];
          if (
            (!adsPageOptional &&
              (element.scope === "pages_show_list" ||
                element.scope === "pages_manage_metadata")) ||
            element.scope === "business_management"
          ) {
            isTargetIds = (element.target_ids || []).length > 0;
            if (isTargetIds) {
              break;
            }
          }
        }

        if (isTargetIds) {
          setFbLoading(false);
          showSnackbar({
            snack: true,
            snackmessage:
              "Please select OPT-IN to all for Business and Page to proceed.",
            snackiv: "error",
          });
          return false;
        }
        return true;
      })
      .catch((error) => {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Something went wrong! Please contact support",
          snackiv: "error",
        });
        console.error(error);
        return false;
      });
  };

  //yet to work
  const setupClientAccount = async (response) => {
    const { assistantId, partnerId } = data || {};
    const { authResponse, status } = response;
    const { grantedScopes } = authResponse || {};
    console.log({ response });

    if (status === "connected" && assistantId && grantedScopes) {
      if (!grantedScopes.includes("business_management")) {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Provide Business Management permission!",
          snackiv: "error",
        });
      } else if (!grantedScopes.includes("ads_management")) {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Provide Ads Management permission!",
          snackiv: "error",
        });
      } else if (!grantedScopes.includes("ads_read")) {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Provide Ads Read permission!",
          snackiv: "error",
        });
      } else if (!grantedScopes.includes("pages_show_list")) {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Provide Page Read permission!",
          snackiv: "error",
        });
      } else if (!grantedScopes.includes("pages_manage_metadata")) {
        setFbLoading(false);
        showSnackbar({
          snack: true,
          snackmessage: "Provide Page Management permission!",
          snackiv: "error",
        });
      } else {
        let permissionCheck = await checkPermissions(
          authResponse.accessToken,
          assistantId
        );
        if (permissionCheck) {
          axios
            .post(`${partnerURL}/api/connect-ads/fb-connect`, {
              assistantId,
              authResponse,
              status,
              partnerId,
              key,
            })
            .then((res) => {
              const { assistantData } = res?.data;
              const {
                fbConnected,
                fbUserId,
                fbGrantedScopes,
                fbConnectedBy,
                selectedMetaPageId,
                selectedMetaAdAccountId,
                freezedMetaAdAccountId,
                freezedMetaPageId,
              } = assistantData;

              setAssistantStore({
                fbConnected,
                fbUserId,
                fbGrantedScopes,
                fbConnectedBy,
                selectedMetaPageId,
                selectedMetaAdAccountId,
                freezedMetaAdAccountId,
                freezedMetaPageId,
              });

              getFbAcc(assistantId);
              getFbPages(assistantId, {
                selectedMetaPageId,
                freezedMetaPageId,
                freezedMetaAdAccountId,
                fbConnectedBy,
              });

              setFbLoading(false);

              notifyMobileApp("connected");
              setFbConnected(true);

              showSnackbar({
                snack: true,
                snackmessage: "Facebook connected!",
                snackiv: "success",
              });
            })
            .catch((error) => {
              notifyMobileApp("error connecting");
              console.log(error);
              setFbLoading(false);
              showSnackbar({
                snack: true,
                snackmessage: error?.response?.data?.message,
                snackiv: "error",
              });
            });
        }
      }
    } else {
      setFbLoading(false);
      showSnackbar({
        snack: true,
        snackmessage: "Connection Failure!",
        snackiv: "error",
      });
    }
  };

  const setupconnect = async () => {
    const { assistantId } = data || {};

    setFbLoading(true);
    const rateLimitBreached = await checkFbConnectRateLimit(
      assistantId,
      (data) => {
        showSnackbar({
          snack: data.alert,
          snackmessage: data.message,
          snackiv: data.severity,
        });
        setFbLoading(false);
      }
    );
    if (rateLimitBreached) return;

    window.FB.login(
      (response) => {
        setupClientAccount(response); //yet to work
      },
      {
        scope:
          "public_profile,email,ads_read,ads_management,pages_show_list,business_management,pages_manage_metadata,pages_read_engagement,pages_manage_engagement,pages_read_user_content,pages_messaging,page_events,catalog_management",
        auth_type: "rerequest",
        return_scopes: true,
      }
    );
  };

  const updateWhatsappNumber = (number) => {
    const pages = fbpages.map((p) => {
      if (p.pageId === pageValue?.pageId) {
        p["whatsappNumber"] = number;
      }
      return p;
    });
    setPageValue({ ...pageValue, whatsappNumber: number });
    setFbPages(pages);
  };

  const linkWhatsappNumber = async (verify) => {
    if (!pageValue?.pageId) {
      showSnackbar({
        snack: true,
        snackmessage: "Select facebook page !!",
        snackiv: "error",
      });
      return;
    }

    if (businessNumber.length < 10) {
      showSnackbar({
        snack: true,
        snackmessage: "Input valid whatsapp number !!",
        snackiv: "error",
      });
      return;
    }

    if (verify && !otp) {
      showSnackbar({
        snack: true,
        snackmessage: "Enter OTP !!",
        snackiv: "error",
      });
      return;
    }

    if (verify) setSubmitOtpLoader(true);
    else setOtpLoader(true);

    const verificationCode = verify ? otp : "";
    await axios
      .post(`${partnerURL}/api/connect-ads/link-whatsapp-number-with-page`, {
        assistantId,
        verificationCode,
        pageId: pageValue.pageId,
        number: businessNumber || whatsappNumber,
        timezoneId: currency === "INR" ? 71 : 1,
        vertical: "OTHER",
        bmName: assistantName,
        key,
      })
      .then((res) => {
        const { otpState, message } = res?.data;
        console.log(message);
        if (otpState === "send") {
          setOtpDialog(true);
          setOtpLoader(false);
        } else if (otpState === "verified") {
          updateWhatsappNumber(businessNumber || whatsappNumber);
          setOtpDialog(false);
          setOtpLoader(false);
          setSubmitOtpLoader(false);
          showSnackbar({
            snack: true,
            snackmessage: message,
            snackiv: "success",
          });
        }
      })
      .catch((error) => {
        console.log("error:", error);

        showSnackbar({
          snack: true,
          snackmessage:
            error?.response?.data?.message || "Something went wrong",
          snackiv: "error",
        });
        setOtpLoader(false);
        setSubmitOtpLoader(false);

        const { action, assistant } = error?.response?.data || {};
        if (action === "reconnect" && assistant) {
          setPageValue({});
          setAccountValue({});
          setFbPages({});
          setFbaccount({});

          const {
            fbConnected,
            fbUserId,
            fbGrantedScopes,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          } = v2SetupData || {};

          setAssistantStore({
            fbConnected,
            fbUserId,
            fbGrantedScopes,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          });
        }
      });
  };

  // disconnect account

  const disconnectClientAccount = () => {
    const { assistantId, partnerId } = data || {};
    const { fbUserId } = assistantStore;

    if (assistantId && fbUserId) {
      setFbLoading(true);
      axios
        .post(`${partnerURL}/api/connect-ads/fb-disconnect`, {
          assistantId,
          fbUserId,
          partnerId,
          key,
        })
        .then((res) => {
          const { assistantData } = res?.data;
          const {
            fbConnected,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          } = assistantData;

          setAssistantStore({
            fbConnected,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          });
          setPageValue();

          setFbConnected(false);
          setFbLoading(false);
          showSnackbar({
            snack: true,
            snackmessage: "Facebook disconnected!",
            snackiv: "success",
          });
          notifyMobileApp("disconnected");
        })

        .catch((error) => {
          notifyMobileApp("error disconnecting");
          console.log(error);

          setFbLoading(false);
          showSnackbar({
            snack: true,
            snackmessage: error?.response?.data?.message,
            snackiv: "error",
          });
        });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} md={10} lg={10}>
          <div className={classes.contentContainer} style={{ opacity: 1 }}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Connect your Facebook account
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {`Allow ${
                    !partnerId ? "AiSensy" : "us"
                  } to receive advertisement analytics and
                events from Facebook`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <>
                  {fbConnected ? (
                    <Tooltip
                      title={"This will disconnect your Facebook Account"}
                    >
                      <div>
                        <Button
                          variant="outlined"
                          onClick={disconnectClientAccount}
                          disabled={fbLoading}
                          style={{
                            color: fbLoading ? "grey" : "#fa5c5c",
                            borderColor: fbLoading ? "grey" : "#fa5c5c",
                            padding: "5px 18px",
                          }}
                          size="large"
                          endIcon={
                            fbLoading ? (
                              <CircularProgress
                                size={18}
                                style={{ color: "grey" }}
                              />
                            ) : (
                              ""
                            )
                          }
                        >
                          Disconnect
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip interactive title={""}>
                      <Box>
                        <Button
                          disabled={fbLoading}
                          style={{
                            backgroundColor: fbLoading
                              ? "lightgrey"
                              : "#1877f2",
                            color: "#fff",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: "bold",
                            padding: "5px 18px",
                          }}
                          size="large"
                          onClick={
                            isPartner && !isTSP ? setupconnect : setupconnect
                          }
                          endIcon={
                            fbLoading ? (
                              <CircularProgress
                                size={18}
                                style={{ color: "white" }}
                              />
                            ) : partnerId ? (
                              <OpenInNew size={18} style={{ color: "white" }} />
                            ) : (
                              ""
                            )
                          }
                        >
                          {partnerId ? "Connect" : "Continue With Facebook"}
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </>
              </Grid>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontSize: "14px",
                  marginLeft: "8px",
                }}
              >
                *Please select{" "}
                <b style={{ fontWeight: "900", color: "black" }}>
                  OPT into all
                </b>{" "}
                option for Business and Pages*
              </Typography>
            </Grid>
            <Box my={4}>
              <Divider />
            </Box>

            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Choose your Facebook page
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Select Facebook page which will be used to ad
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip
                  title={
                    fbConnectedBy !== agentId && freezedMetaPageId
                      ? "You don't have permission to change Facebook Page"
                      : ""
                  }
                >
                  <Box className={classes.selectContainer}>
                    <Autocomplete
                      classes={{ groupUl: classes.autoComplete }}
                      disableClearable
                      id="grouped-demo"
                      disabled={
                        !fbConnected ||
                        loading ||
                        assistantStore?.selectedMetaPageId
                      }
                      renderOption={(a, b) =>
                        getOptionLabel(a, b, classes, true)
                      }
                      options={sortByBusinessName(fbpages)}
                      getOptionDisabled={(option) => {
                        return !option.isAssigned && !!option.metaBusinessId;
                      }}
                      getOptionLabel={(option) => {
                        const { pageName, pageId } = option;
                        if (pageName && pageId)
                          return `${pageName} - ${pageId}`;
                        return "";
                      }}
                      onChange={(e, value) => {
                        setSelectedPage(value);
                        setDialog(true);
                      }}
                      style={{
                        marginRight: "1em",
                      }}
                      groupBy={(option) => option.metaBusinessName}
                      value={pageValue}
                      name="fbpage"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Choose your page"
                          classes={{
                            root: classes.textFieldRootAutocomplete,
                          }}
                          InputProps={{ ...params.InputProps }}
                        />
                      )}
                    />
                  </Box>
                </Tooltip>
                {loading ? (
                  <CircularProgress size={18} color="rgba(0, 0, 0, 0.26)" />
                ) : null}
              </Grid>
            </Grid>

            <Box my={4}>
              <Divider />
            </Box>

            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Link Whatsapp Number</Typography>
                <Typography variant="body2" color="textSecondary">
                  Link your WhatsApp business number with selected Facebook page
                  to receive messages directly over WhatsApp
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip
                  title={
                    !!pageWhatsappNumber
                      ? ""
                      : "WhatsApp API Number is linked with selected Facebook Page"
                  }
                >
                  <Box className={classes.selectContainer}>
                    <TextField
                      className={`${classes.otpTextField} ${
                        fbConnected ? classes.otpTextFieldDisabled : ""
                      }`}
                      name="whatsappNumber"
                      value={businessNumber || pageWhatsappNumber}
                      onChange={(e) => {
                        setBusinessNumber(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: !!pageWhatsappNumber && (
                          <InputAdornment>
                            <CheckCircleOutline style={{ color: "#20A60E" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Tooltip>

                <>
                  {!!pageWhatsappNumber ? (
                    <></>
                  ) : (
                    <Tooltip
                      title={
                        !fbConnected
                          ? "Connect your Facebook Account"
                          : !pageValue?.pageId
                          ? "Select a Meta Page to send OTP"
                          : "Click to link your WhatsApp Number"
                      }
                    >
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: "0.3em" }}
                          onClick={() => linkWhatsappNumber()}
                          endIcon={
                            otpLoader ? (
                              <CircularProgress
                                size={18}
                                color="rgba(0, 0, 0, 0.26)"
                              />
                            ) : (
                              ""
                            )
                          }
                          disabled={
                            otpLoader || !fbConnected || !pageValue?.pageId
                          }
                        >
                          <Typography>Verify</Typography>
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              </Grid>

              <Box my={4} />
            </Grid>
          </div>
          <Box my={8} />
        </Grid>
      </Grid>

      <Dialog
        open={otpDialog}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
        className={classes.projectdialogContainer}
        disableBackdropClick
      >
        <Grid className={classes.dialogHeader}>
          <Typography variant="h3">One Time Password</Typography>
          <IconButton
            onClick={() => {
              setOtpDialog(false);
              setOtpLoader(false);
            }}
          >
            <Clear />
          </IconButton>
        </Grid>
        <DialogContent className={classes.Dialog}>
          <TextField
            name="project"
            value={otp}
            className={classes.textField}
            onChange={(e, value) => {
              setOtp(e.target.value);
            }}
            placeholder="Waiting for OTP..."
            gutterBot
          />
          <Typography variant="body2" gutterBottom color="textSecondary">
            OTP recieved on registered WhatsApp Number
          </Typography>
        </DialogContent>
        <DialogActions className={classes.Actions}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                linkWhatsappNumber(true);
              }}
              disabled={submitOtpLoader}
              endIcon={
                submitOtpLoader ? (
                  <CircularProgress size={18} color="rgba(0, 0, 0, 0.26)" />
                ) : (
                  ""
                )
              }
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog} onClose={onDialogClose} disableBackdropClick>
        <DialogContent>
          <Grid container direction="column" className={classes.content}>
            <Typography
              variant="body1"
              style={{ minHeight: 30, marginTop: "20px" }}
            >
              Do you want to use this page to run ads ?
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "8px 32px 16px" }}>
          <Button variant="outlined" onClick={onDialogClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setupChildBusinessAccount(selectedPage)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export class MetaAdsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      data: {},
      snackbar: {},
      readTnC: false,
      fbLoading: false,
      runConfetti: false,
      disableButton: false,
    };
  }

  validateUser = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: resData } = await axios.post(
        `${partnerURL}/api/verify-connect-ads-link/${id}`
      );
      this.setState({ validated: true, data: { ...resData, key: id } });
    } catch (error) {
      if (error.response?.data?.message) {
        this.setState({
          snackbar: {
            message: error.response.data.message,
            severity: "error",
            code: error.response?.status,
          },
        });
      }
      console.log(error);
    }
  };

  componentDidMount = () => {
    this.validateUser();
    fbInit({ APP_ID });
  };

  closeSnackbar = () => {
    this.setState({ snackbar: {} });
  };

  showSnackbar = (snackObj) => {
    this.setState({
      snackbar: snackObj,
    });
  };

  launchWhatsAppSignup = async () => {
    this.setState({ fbLoading: true });
    const { id } = this.props.match.params;
    const {
      assistantId,
      partnerId,
      clientId,
      agentId,
      version = "v1",
    } = this.state.data || {};

    const rateLimitBreached = await checkFbConnectRateLimit(
      assistantId,
      (data) => this.setState(data)
    );
    if (rateLimitBreached) return;

    window.launchWhatsAppSignup && window.launchWhatsAppSignup();
    window.FB?.login &&
      window.FB.login(
        (response) => {
          const { authResponse, status } = response || {};
          const { grantedScopes } = authResponse || {};
          if (status === "connected" && assistantId && grantedScopes) {
            if (!grantedScopes.includes("business_management"))
              this.setState({
                fbLoading: false,
                snackbar: {
                  message: "Provide Business Management permission!",
                  severity: "error",
                  alert: true,
                },
              });
            else if (!grantedScopes.includes("ads_management"))
              this.setState({
                fbLoading: false,
                snackbar: {
                  message: "Provide Ads Management permission!",
                  severity: "error",
                  alert: true,
                },
              });
            else if (!grantedScopes.includes("ads_read"))
              this.setState({
                fbLoading: false,
                snackbar: {
                  message: "Provide Ads Read permission!",
                  severity: "error",
                  alert: true,
                },
              });
            else if (!grantedScopes.includes("pages_show_list"))
              this.setState({
                fbLoading: false,
                snackbar: {
                  message: "Provide Page Read permission!",
                  severity: "error",
                  alert: true,
                },
              });
            else if (!grantedScopes.includes("pages_manage_metadata"))
              this.setState({
                fbLoading: false,
                snackbar: {
                  message: "Provide Page Management permission!",
                  severity: "error",
                  alert: true,
                },
              });
            else {
              axios
                .post(URL + "/meta-ads/fb-connect", {
                  hashKey: id,
                  assistantId,
                  authResponse,
                  status,
                  partnerId,
                  clientId,
                  agentId,
                  version,
                })
                .then((data) => {
                  if (data.data?.success) {
                    this.setState({
                      snackbar: {
                        message: "Meta Ads integration completed!!",
                        severity: "success",
                        alert: true,
                      },
                      runConfetti: true,
                      disableButton: true,
                    });
                  } else {
                    this.setState({
                      snackbar: {
                        message:
                          "Something went wrong, kindly connect with support!!",
                        severity: "error",
                        alert: true,
                      },
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({
                    snackbar: {
                      message:
                        err?.response?.data?.message || "Something went wrong!",
                      severity: "error",
                      alert: true,
                    },
                  });
                })
                .finally(() => {
                  this.setState({ fbLoading: false });
                });
            }
          } else {
            this.setState({
              snackbar: {
                message: "Connection Failed!",
                severity: "error",
                alert: true,
              },
              fbLoading: false,
            });
          }
        },
        {
          scope:
            "public_profile,email,ads_read,ads_management,pages_show_list,business_management,pages_manage_metadata,pages_read_engagement,pages_manage_engagement,pages_read_user_content,pages_messaging,page_events",
          auth_type: "rerequest",
          return_scopes: true,
        }
      );
  };

  OldComponent = (props) => {
    const { readTnC, classes, data, fbLoading, disableButton } = props;
    const {
      partnerLogo,
      partnerName,
      assistantName,
      businessCompany,
      businessName,
      businessEmail,
    } = data || {};

    return (
      <Grid item xs={12} md={6}>
        <Box className={classes.container} textAlign="center">
          <div>
            <img
              src={partnerLogo}
              alt={partnerName}
              style={{ maxHeight: 60 }}
            />
            <Box textAlign="left" px={2} py={3}>
              <div className={classes.containerSegment}>
                <Typography variant="h4" color="primary" gutterBottom>
                  Manage Facebook Advertisements with your WhatsApp Business
                  Platform Account
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Please verify the information about your company and the phone
                  number that you would like to use to manage Facebook
                  Advertisements with your WhatsApp Business Account. Please
                  make sure that your company follows Facebook's compliance
                  policy.
                </Typography>
              </div>
              <div className={classes.containerSegment}>
                <Typography variant="h4" color="primary" gutterBottom>
                  BUSINESS INFORMATION
                </Typography>
                <Typography
                  className={classes.infoWrapper}
                  variant="body1"
                  color="textSecondary"
                >
                  <div>
                    <b>Name :</b>
                    <span className={classes.info}>{businessName}</span>
                  </div>
                  <div>
                    <b>Email :</b>
                    <span className={classes.info}>{businessEmail}</span>
                  </div>
                  <div>
                    <b>Company :</b>
                    <span className={classes.info}>{businessCompany}</span>
                  </div>
                  <div>
                    <b>Project :</b>
                    <span className={classes.info}>{assistantName}</span>
                  </div>
                </Typography>
              </div>
              {!Object.keys(this.props.tenantDetails).length && (
                <div>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Terms & Conditions
                  </Typography>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={readTnC}
                        onChange={(e) => {
                          this.setState({ readTnC: e.target.checked });
                        }}
                        color="primary"
                        style={{ padding: 0 }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.info}
                        color="textSecondary"
                        variant="body2"
                      >
                        I agree to the{" "}
                        <a
                          href="https://m.aisensy.com/tos/"
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{
                            cursor: "pointer",
                            borderBottom: "solid 1px",
                          }}
                        >
                          AiSensy Terms of Service
                        </a>
                      </Typography>
                    }
                  />
                </div>
              )}
            </Box>

            <Button
              size="large"
              disabled={
                Object.keys(this.props.tenantDetails).length
                  ? false
                  : !readTnC || fbLoading || disableButton
              }
              startIcon={<Facebook />}
              onClick={this.launchWhatsAppSignup}
              style={{
                fontSize: 18,
                color: "white",
                background:
                  Object.keys(this.props.tenantDetails).length ||
                  (readTnC && !fbLoading && !disableButton)
                    ? "#1877F2"
                    : "lightgrey",
                fontWeight: 600,
              }}
              variant="contained"
              color="primary"
              endIcon={
                fbLoading ? (
                  <CircularProgress size={18} style={{ color: "white" }} />
                ) : (
                  ""
                )
              }
            >
              Connect to Facebook
            </Button>
          </div>
        </Box>
      </Grid>
    );
  };

  render() {
    const {
      validated,
      snackbar,
      readTnC,
      data,
      fbLoading,
      runConfetti,
      disableButton,
    } = this.state;
    const { classes } = this.props;
    const {
      partnerLogo,
      partnerName,
      assistantName,
      businessCompany,
      businessName,
      businessEmail,
    } = data || {};

    const confettiCount = window.innerHeight < 767 ? 150 : 500;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <img
                src={partnerLogo}
                alt={partnerName}
                style={{ maxHeight: 40 }}
              />

              {window.location.origin != "https://www.waba.api-wa.co" && (
                <Typography
                  variant="body1"
                  style={{
                    color: "white",
                    maxWidth: "45%",
                    textAlign: "center",
                    height: "40px",
                    overflow: "hidden",
                    fontWeight: 600,
                  }}
                >
                  {Object.keys(this.props.tenantDetails).length
                    ? this.props.tenantDetails.name
                    : "AiSensy"}{" "}
                  is a business Solution Provider and has been given the right
                  by Facebook/WhatsApp to grant their clients access to the
                  WhatsApp Business Platform.
                </Typography>
              )}

              <img src={WhatsAppLogo} alt="whatsapp" style={{ height: 40 }} />
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ margin: "3em 0" }}>
          {validated ? (
            <SetupComponent
              classes={classes}
              data={data}
              showSnackbar={this.showSnackbar}
            />
          ) : snackbar?.code ? (
            <Grid container justify="center">
              <Box textAlign="center" className={classes.errorContainer}>
                <div className={classes.errorSmile}>
                  <SentimentDissatisfied style={{ fontSize: "8.1875em" }} />
                  <Typography variant="h2" style={{ fontWeight: "normal" }}>
                    {"ERROR " + snackbar.code}
                  </Typography>
                </div>
                <div className={classes.errorMessage}>
                  <Typography variant="h2">{snackbar.message}</Typography>
                </div>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <div>
                  <Skeleton
                    variant="rounded"
                    width={60}
                    height={60}
                    className={classes.skeleton}
                  />
                  <Box textAlign="left" px={2} py={3}>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="60%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      <Skeleton variant="rounded" height={60} />
                    </div>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      {[1, 2, 3, 4].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={16}
                          width="40%"
                          style={{ marginBottom: "0.3em", marginLeft: "2.5em" }}
                        />
                      ))}
                    </div>
                    <div>
                      {[1, 2].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={18}
                          width="40%"
                          style={{ marginBottom: "0.4em" }}
                        />
                      ))}
                    </div>
                  </Box>
                  <Skeleton
                    variant="rounded"
                    width={230}
                    height={50}
                    className={classes.skeleton}
                  />
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {!Object.keys(this.props.tenantDetails).length &&
          window.location.origin != "https://www.waba.api-wa.co" && (
            <Box position="fixed" bottom="5px" right="20px" textAlign="center">
              <Typography variant="body2" color="textSecondary">
                Powered⚡ by
              </Typography>
              <Grid container alignItems="center">
                <Grid item>
                  <img
                    alt="AiSensy"
                    src={AiSensyLogo}
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        <Snackbar
          open={snackbar.snack}
          onClose={this.closeSnackbar}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity={snackbar.snackiv}>
            {snackbar.snackmessage}
          </Alert>
        </Snackbar>
        <Confetti
          run={runConfetti}
          numberOfPieces={confettiCount}
          gravity={0.3}
          initialVelocityY={{ min: 12, max: 30 }}
          recycle={false}
          onConfettiComplete={() => this.setState({ runConfetti: false })}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#e8ece1",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "fixed",
    left: 0,
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 70,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "#0a474c",
  },
  container: {
    background: "white",
    borderRadius: 8,
    padding: "2em",
  },
  errorContainer: {
    height: "27em",
    border: "solid #db5046 0.5em",
    borderRadius: "0.5em",
    width: "20em",
  },
  errorSmile: {
    background: "#db5046",
    height: "11em",
    padding: "2em",
    color: "white",
  },
  errorMessage: {
    height: "12em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#db5046",
  },
  containerSegment: {
    marginBottom: "1.5em",
  },
  infoWrapper: {
    marginLeft: "2em",
  },
  info: {
    marginLeft: "0.5em",
  },
  logo: {
    height: 40,
    verticalAlign: "middle",
  },
  skeleton: {
    margin: "auto",
  },
  contentContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: "40px",
    minHeight: 200,
    backgroundColor: "white",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 15px",
    },
  },
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
  },
  message: {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "19.8px",
    lineWidth: "0.1px",
    marginTop: "1.5rem",
  },
  autoComplete: {
    '& .MuiAutocomplete-option[aria-disabled="true"]': {
      pointerEvents: "inherit",
      background: "white",
    },
    '& .MuiAutocomplete-option[aria-disabled="true"]:active': {
      pointerEvents: "none",
      background: "white",
    },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",

    background: "white",
    border: "1px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  truncate: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textField: {
    width: "100%",
    height: 42,
    marginBottom: "0.25em",
  },
  Actions: {
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    padding: "0 16px 16px",
  },
  Dialog: {
    flex: "1 1 auto",
    padding: "10px 16px",
    overflowY: "auto",
  },
  dialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 4px 0 16px",
  },
  arrow: {
    color: "white",
  },
  snackbar: {
    zIndex: 3000,
    top: 70,
  },
  selectContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  otpTextField: {
    width: "100%",
    padding: "8px 16px 7px 20px",
    background: "white",
    border: "1px solid grey",
  },
  otpTextFieldDisabled: {
    "& div": {
      color: "black !important",
    },
  },
  lineItemGrid: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "6em",
    },
  },
});

const MetaAdsSetupComponent = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(MetaAdsSetup);

export default withStyles(styles)(withRouter(MetaAdsSetupComponent));
