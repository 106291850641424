import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  ADMIN_KEY,
  URL as CLIENT_URL,
  TENANT_ID,
  PARTNER_APP_URL,
} from "../../config/config";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  Collapse,
  Box,
  Snackbar,
  Link,
  Tooltip,
  Divider,
  Drawer,
  Tab,
  Tabs,
  Fade,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackOutlined from "@material-ui/icons/ArrowBackOutlined";
import { formatCurrency } from "../../helpers/currency";
import PartnerBalanceInput from "./PartnerBalanceInput";
import PartnerNameInput from "./PartnerNameInput";
import PartnerCredentialsInput from "./PartnerCredentialsInput";
import PartnerToggles from "./PartnerToggles";
import PartnerEmbeddedInput from "./PartnerEmbeddedInput";
import { createRandomString } from "../../helpers/hashing";
import { Sync } from "@material-ui/icons";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import ReactJson from "react-json-view";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PersonIcon from "@material-ui/icons/Person";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import AssistantsTable from "../Assistants/AssistantsTable";
import AssistantsPage from "../Assistants/AssistantsPage";

/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnerDetailsProps
 * @property {Partner} partner
 * @property {() => void} onClose
 * @property {(partner: Partner) => void} setPartner
 */

/**
 * @typedef {PartnerDetailsProps & WithStyles<typeof styles>} Props
 */

/**
 * @extends Component<Props>
 */
class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.partner,
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      invoiceData: [],
      showCode: false,
      trialSetting: {
        enableTrial: this.props.partner.enableTrial || false,
        enableTrialAmount: this.props.partner.enableTrialAmount || false,
        enableSignUp: this.props.partner.enableSignUp || false,
        enableMetaAdsWithoutAPI:
          this.props.partner.enableMetaAdsWithoutAPI || false,
      },
      isDrawerOpen: false,
      logs: [],
      value: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
      },
    };
  }

  onClose = this.props.onClose;

  componentDidMount() {
    axios
      .post(`${CLIENT_URL}/superadmin/partner-super-admin-logs`, {
        partnerId: this.props.partner._id,
      })
      .then((response) => {
        const newLogs = response.data
          .map((log) => {
            const newLog = { ...log };
            delete newLog.body.partnerId;
            return Object.keys(newLog.body).length > 0 ? newLog : null;
          })
          .filter((log) => log !== null);
        this.setState({
          logs: newLogs,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.getPartnerZohoInvoices();
  }

  skipBalanceCheck = (event) => {
    const partnerId = this.props.partner._id;
    const checked = event.target.checked;
    this.setState({ [event.target.name]: checked }, async () => {
      try {
        await axios.post(
          `${CLIENT_URL}/superadmin/toggle-partner-balanceCheck`,
          {
            partnerId,
            checked: checked,
          }
        );
        this.showSuccessMessage("Balance check updated successfully");
        this.updatePartner({ skipBalanceCheck: checked });
      } catch (error) {
        this.showErrorMessage("Unable to update balance check");
      }
    });
  };

  handleDrawerOpen = async () => {
    this.setState({ isDrawerOpen: true });
  };
  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };

  /** @param {Partial<Partner>} obj */
  updatePartner = (obj) =>
    this.props.setPartner({
      ...this.props.partner,
      ...obj,
    });

  showSuccessMessage = (message) =>
    this.setState({
      alert: true,
      alertMsg: message,
      alertSeverity: "success",
    });

  showErrorMessage = (message) =>
    this.setState({
      alert: true,
      alertMsg: message,
      alertSeverity: "error",
    });

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  /**
   *
   * @param {'ADD' | 'SUBTRACT'} action
   * @param {number} amount
   */
  updateCentralBalance = async (action, rawAmount, paymentRemark) => {
    try {
      const amount = rawAmount * 100000;
      const payload = {
        partnerId: this.props.partner._id,
        action,
        amount,
        paymentRemark,
      };

      await axios.post(
        `${CLIENT_URL}/superadmin/update-partner-central-balance`,
        payload
      );

      const centralBalance = this.props.partner.centralBalance || 0;

      this.updatePartner({
        centralBalance: centralBalance + (action === "ADD" ? amount : -amount),
      });

      this.showSuccessMessage("Balance Updated!");
    } catch (error) {
      this.showErrorMessage("Unable to update Balance");
    }
  };

  updateAttribute = async (key, value) => {
    try {
      if (key === "minAllowedBalance") value *= 100000;
      const payload = {
        partnerId: this.props.partner._id,
        [key]: value,
      };
      await axios.post(
        `${CLIENT_URL}/superadmin/update-partner-attributes`,
        payload
      );
      this.showSuccessMessage("Attribute Updated!");
      this.updatePartner({ [key]: value });
    } catch (error) {
      this.showErrorMessage("Unable to update attribute");
    }
  };

  activateAddon = async (
    addonKey,
    aisensyPrice,
    aisensyPlans,
    topics,
    dashboardKey,
    startDate
  ) => {
    try {
      const payload = {
        partnerId: this.props.partner._id,
        addonKey,
      };

      if (addonKey === "WEBHOOKS") payload.topics = topics;
      if (addonKey === "META_ADS") payload.dashboardKey = dashboardKey;
      if (addonKey === "FLOW_BUILDER") payload.aisensyPlans = aisensyPlans;
      if (startDate) payload.startDate = startDate;
      if (aisensyPrice) payload.aisensyPrice = aisensyPrice;

      const { data } = await axios.post(
        `${CLIENT_URL}/superadmin/addon`,
        payload
      );
      this.showSuccessMessage("Addon activated!");
      this.updatePartner({
        addons: { ...this.props.partner.addons, [addonKey]: data.addon },
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      this.showErrorMessage(`Unable to disable addon: ${errMsg}`);
    }
  };

  updateAddon = async (
    addonKey,
    aisensyPrice,
    aisensyPlans,
    topics,
    dashboardKey
  ) => {
    try {
      const payload = {
        partnerId: this.props.partner._id,
        addonKey,
        aisensyPrice,
      };

      if (addonKey === "WEBHOOKS") payload.topics = topics;
      if (addonKey === "META_ADS") payload.dashboardKey = dashboardKey;
      if (addonKey === "FLOW_BUILDER") payload.aisensyPlans = aisensyPlans;

      const { data } = await axios.patch(
        `${CLIENT_URL}/superadmin/addon`,
        payload
      );
      this.showSuccessMessage("Addon updated!");
      this.updatePartner({
        addons: { ...this.props.partner.addons, [addonKey]: data.addon },
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      this.showErrorMessage(`Unable to disable addon: ${errMsg}`);
    }
  };

  disableAddon = async (addonKey) => {
    try {
      const payload = {
        partnerId: this.props.partner._id,
        addonKey,
      };
      await axios.delete(`${CLIENT_URL}/superadmin/addon`, { data: payload });
      this.showSuccessMessage("Addon deactivated!");
      this.updatePartner({
        addons: {
          ...this.props.partner.addons,
          [addonKey]: {
            ...this.props.partner.addons[addonKey],
            status: "paused",
          },
        },
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      this.showErrorMessage(`Unable to disable addon: ${errMsg}`);
    }
  };

  handleImageChange = async (img, assetKey) => {
    try {
      const formData = new FormData();
      formData.append("file", img);
      formData.append("partnerId", this.props.partner._id);
      formData.append("assetKey", assetKey);
      const { data } = await axios.patch(
        `${CLIENT_URL}/superadmin/update-partner-asset`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.showSuccessMessage("Image updated successfully!");
      this.updatePartner({
        [`${assetKey}`]: data.image,
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      this.showErrorMessage(`Unable to update ${assetKey}: ${errMsg}`);
    }
  };

  updateSharedSecret = async () => {
    try {
      const payload = {
        partnerId: this.props.partner._id,
      };
      const response = await axios.post(
        `${CLIENT_URL}/superadmin/update-partner-webhook`,
        payload
      );
      this.showSuccessMessage("Webhook Shared Secret Updated!");
      return response.data;
    } catch (error) {
      this.showErrorMessage("Unable to update Webhook URL");
    }
  };

  handleWebhookTopic = async (webhookUrl, partnerWebhooks) => {
    try {
      const payload = {
        partnerId: this.props.partner._id,
        webhookUrl,
        partnerWebhooks,
      };
      const response = await axios.post(
        `${CLIENT_URL}/superadmin/update-partner-webhook-subscription`,
        payload
      );

      this.showSuccessMessage("Webhook Subscription Updated!");

      this.updatePartner({
        webhookUrl,
        partnerWebhooks,
      });
      return response.data;
    } catch (error) {
      this.showErrorMessage("Unable to update Webhook Subscription");
    }
  };

  resetPassword = async () => {
    try {
      const loginPassword = createRandomString();
      await axios.patch(`${CLIENT_URL}/superadmin/reset-partner-password`, {
        partnerId: this.props.partner._id,
        loginPassword,
      });

      this.showSuccessMessage("Password Reset Successful!");
      return loginPassword;
    } catch (error) {
      this.showErrorMessage("Unable to reset password");
    }
  };

  resetApiKey = async () => {
    try {
      const response = await axios.patch(
        `${CLIENT_URL}/superadmin/reset-partner-api-key`,
        {
          partnerId: this.props.partner._id,
        }
      );
      this.showSuccessMessage("API Key Reset Successful!");
      return response.data;
    } catch (error) {
      this.showErrorMessage("Unable to reset API Key");
    }
  };

  switchPlanType = async (planType) => {
    try {
      const partnerId = this.props.partner._id;
      const agentId = this.props.partner.agentId;
      const {
        data: { token },
      } = await axios.post(
        `${CLIENT_URL}/superadmin/generate-any-token`,
        {
          _id: agentId,
          type: "partner",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );

      await axios.patch(
        CLIENT_URL +
          `/partner-apis/v1/partner/${partnerId}/switch-partner-plan-type`,
        { planType },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      this.showSuccessMessage(`Plan Type switched to ${planType}!`);
      this.updatePartner({ planType });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      this.showErrorMessage(`Unable to switch plan: ${errMsg}`);
    }
  };

  changeTrialSettings = async (data) => {
    const name = data.target.name;
    try {
      const partnerId = this.props.partner._id;
      await axios
        .patch(`${CLIENT_URL}/superadmin/update-trial-settings`, {
          partnerId,
          [name]: data.target.checked,
        })
        .then((res) => {
          this.setState((prevState) => ({
            trialSetting: {
              ...prevState.trialSetting,
              [name]: !this.state.trialSetting[name],
            },
          }));
          this.showSuccessMessage("updates Successfully");
        });
    } catch (error) {
      console.error(error);
      this.showErrorMessage("something went wrong");
    }
  };

  updatePartnerInCache = async () => {
    this.setState({ cacheSyncLoader: true });
    try {
      const partnerId = this.props.partner._id;
      await axios.post(`${CLIENT_URL}/superadmin//update-partner-in-cache`, {
        partnerId,
      });
      this.showSuccessMessage("Successfully synced partner in cache!");
    } catch (error) {
      this.showErrorMessage("Unable to sync partner in cache!");
    }
    this.setState({ cacheSyncLoader: false });
  };

  groupLogsByMonth = (logs) => {
    return logs.reduce((groups, log) => {
      const date = new Date(log.createdAt);
      const monthYearKey = `${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;

      if (!groups[monthYearKey]) {
        groups[monthYearKey] = [];
      }

      groups[monthYearKey].push(log);

      return groups;
    }, {});
  };

  getPartnerZohoInvoices = async () => {
    try {
      const { data } = await axios.get(
        `${CLIENT_URL}/superadmin/get-partner-zoho-invoices`
      );
      this.setState({ invoiceData: data });

      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  getButtonIcon = (log, invoiceData) => {
    // Case 1: Invoice Amount and Payment Amount are same
    if (
      invoiceData[log.body.paymentRemark] &&
      invoiceData[log.body.paymentRemark] === log.body.amount / 100000
    ) {
      return <CheckIcon style={{ color: "#05ea3eb0" }} />;
    }

    // Case 2: No Invoice Found
    if (!invoiceData[log.body.paymentRemark]) {
      return <ErrorIcon style={{ color: "#ea0505b0" }} />;
    }

    // Case 3: Duplicate Payment Remarks
    const duplicatePaymentRemarks = this.state.logs.filter(
      (l) => l.body.paymentRemark === log.body.paymentRemark
    );
    if (duplicatePaymentRemarks.length > 1) {
      return <WarningIcon style={{ color: "#eaea05b0" }} />;
    }

    return null;
  };

  getReport = () => {
    const partnerId = this.state._id;
    const startDate = this.state.applied["createdAt"].startDate;
    const endDate = this.state.applied["createdAt"].endDate;

    axios
      .post(
        `${CLIENT_URL}/superadmin/cb-recharge-log`,
        { partnerId, startDate, endDate },
        { responseType: "stream" }
      )

      .then((res) => {
        const cbLogs = res.data?.data;
        const headers = {
          createdAt: "Date",
          amount: "Recharge Amount",
          message: "Remarks",
        };

        const items = [...cbLogs];
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(headers);
        let csv = items.map((row) =>
          header
            .map((fieldName) => {
              let value = row[fieldName]
                ? row[fieldName]
                : row.params
                ? row.params[fieldName]
                : null;
              if (fieldName === "createdAt") {
                value = value
                  ? new Date(value).toLocaleDateString([], {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  : null;
              }

              if (fieldName === "amount") {
                value = value ? (value / 100000).toFixed(5) : null;
              }

              return JSON.stringify(value, replacer);
            })
            .join(",")
        );
        csv.unshift(Object.values(headers).join(","));
        csv = csv.join("\r\n");

        //Download the file as CSV
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download =
          (this.state.brandName || "CB_RECHARGE_LOG") + ".csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          status: "error",
          statusMessage: "Unable to fetch Data",
          loading: false,
        });
      });
  };

  clearDate = () => {
    const filterKey = "createdAt";
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };

  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };

  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  loginAsOwner = async () => {
    const { partner } = this.props;
    const agentId = partner.agentId;
    try {
      const {
        data: { token },
      } = await axios.post(
        `${CLIENT_URL}/superadmin/generate-any-token`,
        {
          _id: agentId,
          type: "partner",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );
      window.open(`${PARTNER_APP_URL}/login?token=${token}`, "_blank");
    } catch (error) {}
  };

  render() {
    const { classes, partner, agent } = this.props;
    const t = this.state;
    const groupedLogs = this.groupLogsByMonth(this.state.logs);
    const { value } = this.state;
    return (
      <>
        <DialogTitle className={classes.title}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                size="small"
                aria-label="close"
                onClick={this.onClose}
              >
                <ArrowBackOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              <Link href={partner.website} target="_blank">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <img
                      className={classes.logo}
                      alt="Brand Logo"
                      src={partner.brandLogo}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" component="p">
                      {partner.brandName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Sync Partner Cache">
                      <Sync
                        style={{ fontSize: 18, color: "#808080", marginTop: 8 }}
                        onClick={this.updatePartnerInCache}
                        className={
                          this.state.cacheSyncLoader
                            ? classes.rotateIcon
                            : classes.iconButton
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item xs />
            {/* <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<SyncIcon />}
              >
                Refresh
              </Button>
            </Grid> */}
            <Grid item>
              <Typography variant="h4" component="p">
                Live Projects:{"  "}
                <strong>{partner.liveProjects || 0}</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" component="p">
                Central Balance:{"  "}
                <strong>
                  {partner.centralBalance
                    ? `${formatCurrency(
                        partner.currency,
                        (partner.centralBalance ?? 0) / 100000
                      )}`
                    : "Nill"}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDrawerOpen}
              >
                View Logs
              </Button>
            </Grid>

            {agent.power <= 11 ? (
              <></>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.loginAsOwner}
                >
                  Owner Login
                </Button>
              </Grid>
            )}

            <div>
              <Drawer
                anchor="right"
                open={this.state.isDrawerOpen}
                onClose={this.handleDrawerClose}
                className={classes.drawerWidth}
              >
                <Box style={{ padding: "16px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "50px",
                      alignItems: "center",
                      marginBottom: "20px",
                      background: "#EBF5F3",
                    }}
                  >
                    Partner Admin Logs
                  </Typography>
                  {!Object.keys(groupedLogs)?.length
                    ? "No Logs found"
                    : Object.entries(groupedLogs).map(([monthYear, logs]) => {
                        const totalAmount = logs.reduce((total, log) => {
                          return total + (log?.body?.amount || 0);
                        }, 0);

                        return (
                          <Accordion key={monthYear}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                {monthYear} (CB Recharge: {partner.currency}{" "}
                                <b> {totalAmount / Math.pow(10, 5)} </b> )
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                              {logs.map((log, index) => {
                                const createdAtDate = new Date(log.createdAt);
                                const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                                const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                      borderBottom: "1px solid #ccc",
                                      paddingBottom: "10px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          fontSize: "12px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <ReactJson
                                          src={log.body}
                                          displayDataTypes={false}
                                          displayObjectSize={false}
                                          enableClipboard={false}
                                          name="Change log"
                                          shouldCollapse={(field) => {
                                            if (
                                              field?.name?.includes(
                                                "marketing"
                                              ) ||
                                              field?.name?.includes(
                                                "utility"
                                              ) ||
                                              field?.name?.includes(
                                                "authentication"
                                              ) ||
                                              field?.name?.includes("service")
                                            ) {
                                              return true;
                                            }
                                            return false;
                                          }}
                                        />
                                      </div>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <PersonIcon fontSize="small" />
                                        {"  "}
                                        {log.displayName}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CalendarTodayIcon fontSize="small" />
                                        {"  "}
                                        {formattedDate}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ScheduleIcon fontSize="small" />
                                        {"  "}
                                        {formattedTime}
                                      </Typography>
                                    </div>
                                    <br />
                                    {log?.body?.amount && (
                                      <Typography
                                        variant="body1"
                                        style={{
                                          color: "#0a474c",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Central Balance :{" "}
                                        {`${partner.currency} `}
                                        {log?.body?.action === "ADD"
                                          ? "(+)"
                                          : "(-)"}
                                        {log?.body?.amount / Math.pow(10, 5)}
                                      </Typography>
                                    )}

                                    {log?.body?.paymentRemark && (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            variant="body1"
                                            style={{
                                              color: "#0a474c",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Invoice Number:{" "}
                                            {t.invoiceData[
                                              log.body.paymentRemark
                                            ]
                                              ? log.body.paymentRemark
                                              : "N/A"}
                                          </Typography>
                                          <Typography
                                            variant="body1"
                                            style={{
                                              color: "#0a474c",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Invoice Amount:{" "}
                                            {t.invoiceData[
                                              log.body.paymentRemark
                                            ] || "N/A"}
                                          </Typography>
                                        </div>
                                        <div>
                                          {this.getButtonIcon(
                                            log,
                                            t.invoiceData
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                </Box>
              </Drawer>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Box style={{ display: "flex" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={this.handleChange}
              aria-label="Vertical tabs"
              style={{ width: "20%", position: "sticky", top: "0" }}
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                label="Partner management"
                style={
                  value === 0
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Partner configs"
                style={
                  value === 1
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="TSP Configuration"
                style={
                  value === 2
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Pricing management"
                style={
                  value === 3
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Assistants"
                style={
                  value === 4
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              {!TENANT_ID ? (
                <Tab
                  label="Reports"
                  style={
                    value === 5
                      ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                      : {}
                  }
                />
              ) : (
                <></>
              )}

              <Tab
                label="Controls"
                style={
                  value === 6
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
            </Tabs>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingLeft: "5%",
                paddingRight: "3%",
                width: "100%",
              }}
            >
              {value === 0 && (
                <PartnerBalanceInput
                  partner={partner}
                  updateCentralBalance={this.updateCentralBalance}
                  updateMinAllowedBalance={this.updateMinAllowedBalance}
                  updateAttribute={this.updateAttribute}
                  activateAddon={this.activateAddon}
                  updateAddon={this.updateAddon}
                  disableAddon={this.disableAddon}
                  switchPlanType={this.switchPlanType}
                  changeTrialSettings={this.changeTrialSettings}
                  trialSetting={this.state.trialSetting}
                />
              )}
              {value === 1 && (
                <PartnerCredentialsInput
                  partner={partner}
                  resetPassword={this.resetPassword}
                  resetApiKey={this.resetApiKey}
                  updateSharedSecret={this.updateSharedSecret}
                  handleWebhookTopic={this.handleWebhookTopic}
                  showErrorMessage={this.showErrorMessage}
                  showSuccessMessage={this.showSuccessMessage}
                />
              )}
              {value === 2 && (
                <PartnerEmbeddedInput
                  partner={partner}
                  agent={agent}
                  updateAttribute={this.updateAttribute}
                />
              )}
              {value === 3 && (
                <PartnerNameInput
                  partner={partner}
                  updateAttribute={this.updateAttribute}
                  handleImageChange={this.handleImageChange}
                  showSuccessMessage={this.showSuccessMessage}
                  showErrorMessage={this.showErrorMessage}
                  skipBalanceCheck={this.skipBalanceCheck}
                />
              )}

              {value === 4 && (
                <AssistantsPage partner={partner} partnerPage={true} />
              )}

              {value === 5 ? (
                <Box
                  mb={4}
                  style={{
                    background: "#ebf5f3",
                    padding: "30px",
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    container
                    spacing={8}
                    style={{
                      padding: "20px 30px 25px 30px",
                    }}
                  >
                    <Grid xs={12}>
                      <Grid
                        xs={8}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{ marginBottom: "11px" }}
                        >
                          {"Download Report"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        style={{ marginBottom: "15px" }}
                      >
                        {"Download Central Balance recharge Report"}
                      </Typography>
                    </Grid>

                    <Grid
                      xs={12}
                      style={{
                        display:
                          window.innerWidth <= 850 ? "inline-block" : "flex",
                        alignItems: "center",
                      }}
                    >
                      <DateFilter
                        filterKey="createdAt"
                        filterName="Created At"
                        classes={classes}
                        applied={this.state.applied}
                        setDate={this.setDate}
                        setFocus={this.setFocus}
                        checkDateInput={this.checkDateInput}
                        clearDate={this.clearDate}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          marginLeft: 8,
                          height: 30,
                        }}
                        onClick={() => {
                          this.getReport();
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <></>
              )}

              {value === 6 && (
                <PartnerToggles
                  partner={partner}
                  updateAttribute={this.updateAttribute}
                  showSuccessMessage={this.showSuccessMessage}
                  showErrorMessage={this.showErrorMessage}
                  skipBalanceCheck={this.skipBalanceCheck}
                />
              )}

              <Grid container justifyContent="center">
                <Grid item>
                  {agent.power <= 11 ? (
                    <></>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          showCode: !t.showCode,
                        })
                      }
                    >
                      {t.showCode ? "Hide" : "Show"} Code
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Collapse in={t.showCode}>
                <pre contentEditable={true} className={classes.codeBox}>
                  {JSON.stringify(this.props.partner, null, 2)}
                </pre>
              </Collapse>
            </Box>
          </Box>
        </DialogContent>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <div className={classes.datePickerPosition}>
      <Grid item xs style={{ display: "flex", justifyContent: "flex-start" }}>
        <DateRangePicker
          startDatePlaceholderText="From"
          endDatePlaceholderText="To"
          onClose={() => checkDateInput(filterKey)}
          startDateId="startDate"
          endDateId="endDate"
          startDate={A.startDate}
          endDate={A.endDate}
          onDatesChange={({ startDate, endDate }) =>
            setDate(filterKey, startDate, endDate)
          }
          onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
          navPosition="navPositionTop"
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          customArrowIcon={true}
          screenReaderInputMessage={" "}
          small
          readOnly
          isOutsideRange={() => false}
          maxDate={moment(new Date())}
          minimumNights={0}
          focusedInput={A.focusedInput}
        />
      </Grid>
    </div>
  );
}

/** @param {Theme} theme */
const styles = (theme) =>
  createStyles({
    root: {},
    logo: {
      height: theme.spacing(4),
    },
    indicator: {
      backgroundColor: "rgb(70 20 134)",
    },
    drawerWidth: {
      "& .MuiDrawer-paper": {
        width: "34%",
      },
    },
    codeBox: {
      backgroundColor: "#eee",
      padding: "1rem",
      borderRadius: "12px",
      overflow: "auto",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    title: {},
    content: {
      backgroundColor: "#FDFFFC",
    },
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
    iconButton: {
      cursor: "pointer",
    },
  });

const connectedPartnerDetails = connect((state) => ({
  agent: state.login.user,
}))(PartnerDetails);
export default withStyles(styles)(connectedPartnerDetails);
